import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import React from 'react';
import MaterialGrid from '@material-ui/core/Grid';

export default function TemplatePresentialCourse(props) {
  return (
    <GeneralGrid container spacing={3}>
      <MaterialGrid item xs={12}>
        <p>
          Os detalhes específicos dessa atividade serão escolhidos na aba de
          premiação
        </p>
      </MaterialGrid>
    </GeneralGrid>
  );
}
