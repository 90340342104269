import React from 'react';
import { Route, IndexRoute } from 'react-router';
import TemplatesDrafts from './templatesDrafts';
import TemplatesForm from './templatesForm';
import ConfirmationCard from './confirmationCard/confirmationCard';

export default (
  <Route path="rascunhos">
    <IndexRoute component={props => <TemplatesDrafts {...props} />} />
    <Route path="novo" component={props => <ConfirmationCard {...props} />} />
    <Route
      path=":id/:action"
      component={props => <TemplatesForm {...props} />}
    />
  </Route>
);
