import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import { appColors } from 'styles/colors.jsx';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  primary: {
    color: appColors.dark.level1,
    borderColor: appColors.primary,
    backgroundColor: appColors.light.level1,
    '&:hover': {
      borderColor: appColors.dark.level2,
      backgroundColor: appColors.light.level2,
    },
  },
  /* secondary: {
        color: "#2B2D6B",
        borderColor: "#2B2D6B66",
        "&:hover": {
            borderColor: "#2B2D6B",
            backgroundColor: "#2B2D6B0a"
        }
    } */
}));

export default function SecondaryButton(props) {
  const classes = useStyles();

  return (
    <Button
      variant="outlined"
      className={clsx(props.noMargin ? '' : classes.button, classes.primary)}
      {...props}
    >
      {props.children}
    </Button>
  );
}
