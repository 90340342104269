import React from 'react';

import { Container } from './styles';
import Questions from '../../../quiz/QuestionsParticipant';
import Answers from '../../../quiz/AnswersParticipant';

export default function QuizRightAnswer({ questions }) {
  return (
    <>
      {questions
        ? questions.map(item => (
            <>
              {item ? (
                <>
                  <Questions question={item.question} />
                  <Container>
                    {item.options
                      ? item.options.map(item => (
                          <Answers
                            background={
                              item
                                ? item.label.color
                                  ? item.label.color
                                  : '#2D95EF'
                                : '#2D95EF'
                            }
                            textColor={
                              item
                                ? item.label.textColor
                                  ? item.label.textColor
                                  : '#FFF'
                                : '#FFF'
                            }
                            text={
                              item
                                ? item.label.text
                                  ? item.label.text
                                  : null
                                : null
                            }
                            image={
                              item
                                ? item.label.base64
                                  ? item.label.base64
                                  : null
                                : null
                            }
                          />
                        ))
                      : null}
                  </Container>
                </>
              ) : null}
            </>
          ))
        : null}
    </>
  );
}
