import React from 'react';
import { Box, Typography } from '@material-ui/core';

export default function TemplateRawContent(props) {
  return (
    <Box
      style={{ height: 300 }}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Typography color="primary" style={{ fontSize: 20 }}>
        Essa mecânica não possui interação configurável. O valor da doação é
        escollhido pelo participante.
      </Typography>
    </Box>
  );
}
