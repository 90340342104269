import React, { useRef, useState } from 'react';
import { TextField, Grid } from '@material-ui/core';
import PrimaryButton from '../../buttons/primaryButton';
import { FanApi } from '../api';
import { USER_TOKEN } from 'config/consts';

// import { Container } from './styles';

export default function UploadLink() {
  const ref = useRef();
  const [value, setValue] = useState('');

  async function handleLink(value) {
    await FanApi.post(
      `/embed-video`,
      { url: value },
      {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      }
    );
    setValue('');
  }

  return (
    <div
      style={{
        marginTop: 20,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <Grid container>
        <Grid item xs={6}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            <TextField
              ref={ref}
              value={value}
              onChange={e => setValue(e.target.value)}
              style={{ width: '100%' }}
              id="outlined-basic"
              label="Link-externo"
              variant="outlined"
            />
            <PrimaryButton
              onClick={() => handleLink(value)}
              type="button"
              style={{ margin: '8px 0 0 0' }}
            >
              Enviar
            </PrimaryButton>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
