import React from 'react';
import CheckListQuiz from '../activities/Quizes/CheckListQuiz';

import { Container } from './styles';
import QuizRightAnswer from '../activities/Quizes/QuizRightAnswer';

export default function Interaction(props) {
  return (
    <Container>
      {props.formValues.type_action_id === 9 ||
      props.formValues.type_action_id === 10 ? (
        <CheckListQuiz
          question={props.formValues.question}
          options={props.formValues.options}
        />
      ) : props.formValues.type_action_id === 11 ||
        props.formValues.type_action_id === 12 ? (
        <QuizRightAnswer questions={props.formValues.questions} />
      ) : null}
    </Container>
  );
}
