import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ChevronLeft, ChevronRight, Settings } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'white',
    marginLeft: -14,
    marginRight: -14,
  },
  AppBar: {
    background: 'white',
    boxShadow: 'none',
    marginLeft: -14,
    marginRight: -14,
  },
  tab: {
    color: 'black',
  },
  active: {
    backgroundColor: 'white',
  },
}));

const ActionsTabs = props => {
  const classes = useStyles();

  function submit(values) {
    if (props.activeTab === 1) {
      if (values.results) {
        for (let i = 0; i < values.results.length; i++) {
          if (values.results[i].feedback_lottie_id === 'none') {
            values.results[i].feedback_lottie_id = null;
          }
        }
      }
    } else if (props.activeTab === 2) {
      if (!values.isPaid) {
        values.points = 0.0;
      }
      if (values.feedback_lottie_id === 'none') {
        values.feedback_lottie_id = null;
      }

      if (values.type_action_id === 1) {
        if (!values.isInviterPaid) {
          values.points_inviter = 0.0;
        }
        if (!values.isInvitedPaid) {
          values.points_invited = 0.0;
        }
      }
    }

    props.onClickProgress(values);
  }
  return (
    <form style={{ width: '100%' }} onSubmit={props.handleSubmit(submit)}>
      <Box
        style={{ ...props.styles }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {props.activeTab === 0 ? (
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Settings style={{ fontSize: 23 }} />
              <Typography
                style={{
                  fontFamily: 'Lato',
                  fontStyle: 'normal',
                  fontWeight: 'bold',
                  fontSize: '24px',
                  lineHeight: '29px',
                  letterSpacing: '0.25px',
                  color: '#000000',
                  paddingLeft: 20,
                }}
              >
                Apresentação
              </Typography>
            </Box>
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Typography
                style={{
                  fontFamily: 'Lato',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '18px',
                  lineHeight: '22px',
                  letterSpacing: '0.25px',
                  color: '#000000',
                  paddingRight: 20,
                }}
              >
                1 de 4
              </Typography>

              <button type="button">
                <ChevronLeft style={{ fontSize: 23, color: 'gray' }} />
              </button>
              <button type="submit">
                <ChevronRight style={{ fontSize: 23, color: '#323232' }} />
              </button>
            </Box>
          </Box>
        ) : props.activeTab === 1 ? (
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Settings style={{ fontSize: 23 }} />
              <Typography
                style={{
                  fontFamily: 'Lato',
                  fontStyle: 'normal',
                  fontWeight: 'bold',
                  fontSize: '24px',
                  lineHeight: '29px',
                  letterSpacing: '0.25px',
                  color: '#000000',
                  paddingLeft: 20,
                }}
              >
                Interação
              </Typography>
            </Box>
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Typography
                style={{
                  fontFamily: 'Lato',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '18px',
                  lineHeight: '22px',
                  letterSpacing: '0.25px',
                  color: '#000000',
                  paddingRight: 20,
                }}
              >
                2 de 4
              </Typography>
              <button type="button" onClick={props.goBack}>
                <ChevronLeft style={{ fontSize: 23, color: '#323232' }} />
              </button>
              <button type="submit">
                <ChevronRight style={{ fontSize: 23, color: '#323232' }} />
              </button>
            </Box>
          </Box>
        ) : props.activeTab === 2 ? (
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Settings style={{ fontSize: 23 }} />
              <Typography
                style={{
                  fontFamily: 'Lato',
                  fontStyle: 'normal',
                  fontWeight: 'bold',
                  fontSize: '24px',
                  lineHeight: '29px',
                  letterSpacing: '0.25px',
                  color: '#000000',
                  paddingLeft: 20,
                }}
              >
                Recompensas
              </Typography>
            </Box>
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Typography
                style={{
                  fontFamily: 'Lato',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '18px',
                  lineHeight: '22px',
                  letterSpacing: '0.25px',
                  color: '#000000',
                  paddingRight: 20,
                }}
              >
                3 de 4
              </Typography>
              <button type="button" onClick={props.goBack}>
                <ChevronLeft style={{ fontSize: 23, color: '#323232' }} />
              </button>
              <button type="submit">
                <ChevronRight style={{ fontSize: 23, color: '#323232' }} />
              </button>
            </Box>
          </Box>
        ) : props.activeTab === 3 ? (
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Settings style={{ fontSize: 23 }} />
              <Typography
                style={{
                  fontFamily: 'Lato',
                  fontStyle: 'normal',
                  fontWeight: 'bold',
                  fontSize: '24px',
                  lineHeight: '29px',
                  letterSpacing: '0.25px',
                  color: '#000000',
                  paddingLeft: 20,
                }}
              >
                Publicação
              </Typography>
            </Box>
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Typography
                style={{
                  fontFamily: 'Lato',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '18px',
                  lineHeight: '22px',
                  letterSpacing: '0.25px',
                  color: '#000000',
                  paddingRight: 20,
                }}
              >
                4 de 4
              </Typography>
              <button type="button" onClick={props.goBack}>
                <ChevronLeft style={{ fontSize: 23, color: '#323232' }} />
              </button>

              <button type="button">
                <ChevronRight style={{ fontSize: 23, color: 'gray' }} />
              </button>
            </Box>
          </Box>
        ) : null}
      </Box>
      <div className={classes.root}>
        <div style={{ backgroundColor: 'white', minHeight: '100vh' }}>
          {props.activeTab === 0 && (
            <TabContainer>{props.content_1}</TabContainer>
          )}
          {props.activeTab === 1 && (
            <TabContainer>{props.content_2}</TabContainer>
          )}
          {props.activeTab === 2 && (
            <TabContainer>{props.content_3}</TabContainer>
          )}
          {props.activeTab === 3 && (
            <TabContainer>{props.content_4}</TabContainer>
          )}
          {props.activeTab === 4 && (
            <TabContainer>{props.content_5}</TabContainer>
          )}
          {props.activeTab === 5 && (
            <TabContainer>{props.content_6}</TabContainer>
          )}
          {props.activeTab === 6 && (
            <TabContainer>{props.content_7}</TabContainer>
          )}
        </div>
      </div>
    </form>
  );
};

const ActionsTabsForm = reduxForm({
  form: 'templatesForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(ActionsTabs);

export default ActionsTabsForm;
