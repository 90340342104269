import _ from 'lodash';
import axios from 'axios';
import { BASE_API } from 'config/consts';

export const hasKeys = (obj, attrs) => {
  let tmp = obj;

  for (let i = 0; i < attrs.length; ++i) {
    if (_.isUndefined(tmp[attrs[i]])) return false;
    // tmp = tmp[attrs[i]];
  }
  return true;
};

export const tableSearch = (e, list) => {
  const newList = list.filter((item, index) => {
    let isFiltered = Object.values(item).find((item2, index2) => {
      if (!_.isNull(item2)) {
        if (
          item2
            .toString()
            .toUpperCase()
            .search(e.target.value.toUpperCase()) > -1
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    });

    if (!_.isUndefined(isFiltered)) {
      return true;
    } else {
      return false;
    }
  });
  return newList;
};

export const imgToBase64 = image => {
  return image.base64;
};

export const imgToImgType = image => {
  let a = image.base64.indexOf('/');
  let b = image.base64.indexOf(';');
  image.base64.slice(a + 1, b);
  return image.base64.slice(a + 1, b);
};

export const api = axios.create({
  baseURL: BASE_API,
});
