import { Box } from '@material-ui/core';
import MaterialGrid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PrimaryButton from 'common/components/buttons/primaryButton';
import {
  EditorConvertToHTMLActivity,
  InputFile,
  InputFileWithUpload,
  InputLabelOutlined,
  InputSwitchGrey,
} from 'common/components/fields/fields';
import { FORM_RULES } from 'helpers/validations';
import { getList as getCategoriesList } from 'modules/admin/pages/categories/categoriesActions';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import UploadVideos from '../../../../../common/components/upload';
import { getListById as getLottiesListById } from '../../lotties/lottiesActions';
import { getList as getTemplatesCategoriesList } from '../../templates-categories/categoriesActions';
import { dispatchLoading } from '../templatesActions';

const maxL = max => value =>
  value && value.length > max
    ? `Aceitamos ${max} caracteres ou menos. Seu atual: ${value.length}`
    : undefined;
const maxLength = maxL(40);
const maxLengthOwner = size => maxL(size);

class Content extends Component {
  constructor(props) {
    super(props);
    props.getCategoriesList();
    props.getTemplatesCategoriesList();
  }

  onSubmit = values => {
    this.props.onClickProgress(values);
  };

  render() {
    return (
      <>
        <Form
          role="form"
          onSubmit={this.props.handleSubmit(this.onSubmit)}
          noValidate
        >
          <MaterialGrid container direction="row" spacing={4} justify="center">
            <MaterialGrid item xs={12}>
              <Field
                component={InputLabelOutlined}
                label="Nome dessa atividade para o participante"
                name="body_name"
                validate={[FORM_RULES.required, maxLength]}
                required
                margin="normal"
              />
            </MaterialGrid>
            <MaterialGrid item xs={12}>
              <Field
                component={InputLabelOutlined}
                label="Resumo da atividade para o participante"
                name="body_summary"
                validate={[FORM_RULES.required]}
                required
                margin="normal"
              />
            </MaterialGrid>
            <MaterialGrid item xs={12}>
              <Field
                component={InputLabelOutlined}
                label="Label do card (Ex: Participe) no ambiente do participante"
                name="click_me_label"
                validate={[FORM_RULES.required]}
                required
                margin="normal"
              />
            </MaterialGrid>
            <MaterialGrid item xs={12}>
              <Typography
                style={{ marginBottom: 5 }}
                variant="subtitle2"
                align="left"
              >
                Foto para o participante
              </Typography>
              <Field
                component={InputFile}
                label={'Imagem .png ou .jpg'}
                cols="12 12 12 12"
                name="image"
                validate={[FORM_RULES.required]}
              />
            </MaterialGrid>
            <MaterialGrid item xs={12}>
              <Field
                component={EditorConvertToHTMLActivity}
                label={'Descrição dessa atividade para o participante'}
                name="body_description"
                border
                validate={[FORM_RULES.required]}
              />
            </MaterialGrid>
            <MaterialGrid item xs={12}>
              <Field
                component={InputLabelOutlined}
                label="Nome dessa atividade para o gestor"
                name="name"
                validate={[FORM_RULES.required, maxLengthOwner(50)]}
                required
                margin="normal"
              />
            </MaterialGrid>
            <MaterialGrid item xs={12}>
              <Field
                component={InputLabelOutlined}
                label="Resumo da atividade para o gestor"
                name="summary"
                validate={[FORM_RULES.required]}
                required
                margin="normal"
              />
            </MaterialGrid>
            <MaterialGrid item xs={12}>
              <Typography
                style={{ marginBottom: 5 }}
                variant="subtitle2"
                align="left"
              >
                Foto para o Gestor
              </Typography>
              <Field
                component={InputFileWithUpload}
                label={'Imagem .png ou .jpg'}
                name="owner_image"
                noRatioLimitation
                validate={[FORM_RULES.required]}
              />
            </MaterialGrid>
            <MaterialGrid item xs={12}>
              <Field
                component={EditorConvertToHTMLActivity}
                label={'Descrição dessa atividade para o gestor'}
                name="description"
                border
                validate={[FORM_RULES.required]}
              />
            </MaterialGrid>
            <MaterialGrid
              item
              xs={12}
              style={{
                paddingLeft: 16,
                paddingRight: 16,
                paddingTop: 0,
                paddingBottom: 8,
              }}
            >
              <Field
                component={InputSwitchGrey}
                label={'Destaque da área logada'}
                boxStyle={{ backgroundColor: '#f7f7f7', padding: 16 }}
                firstState="Ativado"
                secondState="Selecione para ativar o destaque"
                disabled={this.props.disabled}
                name="featuredPrivate"
                // required
                margin="normal"
              />
            </MaterialGrid>
            {this.props.formValues ? (
              this.props.formValues.featuredPrivate ? (
                <MaterialGrid item xs={12}>
                  <Typography
                    style={{ marginBottom: 5 }}
                    variant="subtitle2"
                    align="left"
                  >
                    Foto de destaque para a Atividade
                  </Typography>
                  <Field
                    component={InputFile}
                    label={'Imagem .png ou .jpg para destaque'}
                    cols="12 12 12 12"
                    name="featuredImage"
                    validate={
                      this.props.formValues.featuredPrivate
                        ? [FORM_RULES.required]
                        : null
                    }
                  />
                </MaterialGrid>
              ) : null
            ) : null}

            <MaterialGrid item xs={12}>
              <Field
                component={InputSwitchGrey}
                boxStyle={{ backgroundColor: '#f7f7f7', padding: 16 }}
                firstState="Ativado"
                secondState="Selecione para ativar o destaque"
                label={'Destaque da área não logada'}
                disabled={this.props.disabled}
                name="featuredPublic"
                required
                margin="normal"
              />
            </MaterialGrid>
            <MaterialGrid item xs={12}>
              <Field component={UploadVideos} name="videos" />
            </MaterialGrid>
          </MaterialGrid>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            style={{ marginTop: 10 }}
          >
            <PrimaryButton
              type="submit"
              color="primary"
              disabled={this.props.templatesDrafts.buttonLoading}
              progress={parseInt(this.props.general.submitProgress)}
            >
              {this.props.templatesDrafts.buttonLoading
                ? 'Salvando'
                : 'Salvar e Continuar'}
            </PrimaryButton>
          </Box>
        </Form>
      </>
    );
  }
}

Content = reduxForm({
  form: 'templatesForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(Content);

const mapStateToProps = state => {
  return {
    auth: state.auth,
    contactsSurveys: state.contactsSurveys,
    lotties: state.lotties,
    templatesDrafts: state.templatesDrafts,
    templates: state.templates,
    general: state.general,
    formValues: getFormValues('templatesForm')(state),
    categories: state.categories,
    templatesCategories: state.templatesCategories,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getCategoriesList,
      getLottiesListById,
      dispatchLoading,
      getTemplatesCategoriesList,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Content);
