import styled from 'styled-components';

export const ErrorMessage = styled.strong`
  justify-content: center;
  position: absolute;
  color: red;
  font-size: 16px;
  width: fit-content;
  font-family: Roboto;
  ${props => (props.error ? null : 'display:none')}
`;
