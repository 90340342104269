import AppBar from '@material-ui/core/AppBar';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { appColors } from '../../../../styles/colors';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'white',
  },
  AppBar: {
    background: '#f7f7f7',
    boxShadow: 'none',
    borderBottom: '1px solid lightgray',
  },
  tab: {
    fontSize: 15,
    margin: '10px 0 10px 0',
    textTransform: 'none',
    color: '#707070',
  },
  active: {
    color: '#2D95EF',
    backgroundColor: '#f7f7f7',
  },
}));

const AntTabs = withStyles({
  indicator: {
    backgroundColor: appColors.primary,
    transform: 'translateY(-65px)',
  },
})(Tabs);

export default function SimpleTabs(props) {
  const route = window.location.hash.split('#')[1];
  function renderState() {
    const filtered = props.navBarItem.children.filter((item, index) => {
      return route === item.link;
    });
    return !_.isEmpty(filtered) ? filtered[0].id : 0;
  }
  const classes = useStyles();
  const [value, setValue] = useState(renderState());
  function handleChange(event, newValue) {
    setValue(newValue);
  }

  useEffect(() => {
    const filtered = props.navBarItem.children.filter((item, index) => {
      return route === item.link;
    });
    setValue(!_.isEmpty(filtered) ? filtered[0].id : 0);
  }, [route]);

  return (
    <div
      className={classes.root}
      style={{
        paddingLeft: props.isMobile ? 0 : 72,
      }}
    >
      <AppBar position="static" className={classes.AppBar}>
        <AntTabs value={value} onChange={handleChange}>
          {props.navBarItem.children.map((item, index) => (
            <Tab
              key={index}
              onClick={() => props.router.push(item.link)}
              className={clsx(classes.tab, item.id === value && classes.active)}
              label={item.name}
            />
          ))}
        </AntTabs>
      </AppBar>
    </div>
  );
}
