import React, { Component } from 'react';
import { hashHistory, IndexRoute, Route, Router } from 'react-router';
import categoriesRoutes from '../modules/admin/pages/categories/categoriesRoutes';
import DashboardAdmin from '../modules/admin/pages/dashboard/dashboardAdmin';
import lottiesRoutes from '../modules/admin/pages/lotties/lottiesRoutes';
import managersRoutes from '../modules/admin/pages/managers/managersRoutes';
import ProfileRoutes from '../modules/admin/pages/profile/profileRoutes';
import templatesDraftsRoutes from '../modules/admin/pages/templates/templatesDraftsRoutes';
import templatesRoutes from '../modules/admin/pages/templates/templatesRoutes';
import termsOfUseRoutes from '../modules/admin/pages/termsOfUse/termsOfUseRoutes';
import transferRequestsRoutes from '../modules/admin/pages/financialSystem/transferRequests/transferRequestsRoutes';
import ActivateUser from '../pages/auth/activateUser/activateUser';
import ForgotPassword from '../pages/auth/forgotPassword/forgotPassword';
import NewPassword from '../pages/auth/forgotPassword/newPassword';
import Login from '../pages/auth/login/login';
import Logout from '../pages/auth/logout/logout';
import Home from '../pages/home/home';
import Terms from '../pages/terms/terms';
import authOrApp from './authOrApp';
import partnersRoutes from '../modules/admin/pages/partners/partnersRoutes';
import trasactionsRoutes from '../modules/admin/pages/financialSystem/transactionsOverview/trasactionsRoutes';
import templatesCategoriesRoutes from '../modules/admin/pages/templates-categories/categoriesRoutes';
import participantsRoutes from '../modules/admin/pages/participants/participantsRoutes';
export default class Routes extends Component {
  render() {
    return (
      <Router history={hashHistory}>
        <Route path="/login" component={Login} />
        <Route path="/ativar/:hash/:email" component={ActivateUser} />
        <Route path="/esqueci-minha-senha" component={ForgotPassword} />
        <Route
          path="/redefinir-senha/:userHash/:userEmail"
          component={NewPassword}
        />
        <Route path="/logout" component={Logout} />
        <Route path="/termos" component={Terms} />

        <Route path="/" component={authOrApp}>
          <IndexRoute component={Home} />
          <Route path="/admin">
            <IndexRoute component={DashboardAdmin} />
            {categoriesRoutes}
            {ProfileRoutes}
            {templatesRoutes}
            {templatesDraftsRoutes}
            {trasactionsRoutes}
            {lottiesRoutes}
            {managersRoutes}
            {partnersRoutes}
            {templatesCategoriesRoutes}
            {transferRequestsRoutes}
            {termsOfUseRoutes}
            {participantsRoutes}
          </Route>
        </Route>
      </Router>
    );
  }
}
