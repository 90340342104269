import { Typography } from '@material-ui/core';
import { InputLabelOutlined } from 'common/components/fields/fields';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import { FORM_RULES } from 'helpers/validations';
import React from 'react';
import { Field } from 'redux-form';

export default function TemplateAgreement(props) {
  return (
    <GeneralGrid container spacing={3}>
      <GeneralGrid item xs={12} style={{ marginTop: 15 }}>
        <Typography>Texto de aceite dos termos do regulamento</Typography>
      </GeneralGrid>
      <GeneralGrid item xs={12} style={{ marginBottom: 15 }}>
        <Field
          component={InputLabelOutlined}
          label={`Texto`}
          name={`checkbox`}
          validate={[FORM_RULES.required]}
          required
        />
      </GeneralGrid>
      <GeneralGrid item xs={12}>
        <Typography>Texto do botão</Typography>
      </GeneralGrid>
      <GeneralGrid item xs={12} style={{ marginBottom: 15 }}>
        <Field
          component={InputLabelOutlined}
          label={`Texto`}
          name={`button`}
          validate={[FORM_RULES.required]}
          required
        />
      </GeneralGrid>
    </GeneralGrid>
  );
}
