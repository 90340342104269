import generalReducer from './generalReducer';
import categoriesReducer from './pages/categories/categoriesReducer';
import dashboardAdminReducer from './pages/dashboard/dashboardAdminReducer';
import lottiesReducers from './pages/lotties/lottiesReducers';
import managersReducer from './pages/managers/managersReducer';
import profileReducer from './pages/profile/profileReducer';
import questionsReducer from './pages/questions/questionsReducer';
import templatesDrafts from './pages/templates/templatesDraftReducer';
import templatesReducer from './pages/templates/templatesReducer';
import termsOfUseReducer from './pages/termsOfUse/termsOfUseReducer';
import transferRequestsReducers from './pages/financialSystem/transferRequests/transferRequestsReducers';
import partnersReducer from './pages/partners/partnersReducer';
import transactionsReducer from './pages/financialSystem/transactionsOverview/transactionsReducer';
import templatesCategoriesReducer from './pages/templates-categories/categoriesReducer';
import participantsReducer from './pages/participants/participantsReducer';

export default {
  dashboardAdmin: dashboardAdminReducer,
  questions: questionsReducer,
  general: generalReducer,
  categories: categoriesReducer,
  templatesCategories: templatesCategoriesReducer,
  profile: profileReducer,
  templates: templatesReducer,
  templatesDrafts: templatesDrafts,
  lotties: lottiesReducers,
  managers: managersReducer,
  partners: partnersReducer,
  transferRequests: transferRequestsReducers,
  termsOfUse: termsOfUseReducer,
  transactions: transactionsReducer,
  participants: participantsReducer
};
