import { Box, Typography } from '@material-ui/core';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import AlertDialogSlide from 'common/components/dialog/dialog';
import { InputLabelOutlined } from 'common/components/fields/fields';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import Table6 from 'common/components/table/table6';
import { tableSearch } from 'helpers/utils';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PrimaryButton from '../../../../../common/components/buttons/primaryButton';
import {
  buyPoints,
  getList,
  remove,
  userBuyPoints,
} from './transferRequestsActions';

export function TransferRequests(props) {
  const { router } = props;
  const { list, loading } = props.transferRequests;

  const [columns] = useState([
    { field: 'points', title: 'Quantidade (R$)' },
    { field: 'createdAt', title: 'Criado em:' },
    {
      title: 'Estação / Parceiro / Participante',
      render: rowData =>
        rowData.user.type_id === 2 ? (
          <p style={{ margin: 'auto 0px' }}>{rowData.user.name}</p>
        ) : (
          <p style={{ margin: 'auto 0px' }}>{rowData.stationName}</p>
        ),
    },
    { field: 'status.name', title: 'Status' },
    { field: 'type.name', title: 'Tipo' },
  ]);
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState('');
  const [request, setRequest] = useState(undefined);

  useEffect(() => {
    props.getList();
  }, []);

  useEffect(() => {
    setData(props.transferRequests.list.content);
  }, [props.transferRequests.list.content]);

  function handleChange(e) {
    setData(tableSearch(e, props.transferRequests.list.content));
    setValue(e.target.value);
  }

  function onRowClick(e, rowData) {
    setRequest(rowData);
    setVisible(true);
  }

  function postTransferRequest() {
    const values = {
      transfer_request_id: request.id,
      points: request.points,
      user_id: request.user.type_id === 2 && request.user.id,
    };
    request.user.type_id === 2
      ? props.userBuyPoints(values, props.router)
      : props.buyPoints(values, props.router);
  }

  function renderEmptyScreen() {
    return (
      <section
        id="statement-buy-points"
        style={{ marginTop: '4.3em', height: '100vh' }}
      >
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <h2 className="signup-text">
                  Você ainda não possui nenhum pedido de transferência
                </h2>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img
              src={placeholder_illustration}
              className="img-fluid"
              alt="Ilustração: não há dados"
            />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }

  if (loading) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }

  if (list.content.length < 1) {
    return renderEmptyScreen();
  }

  return (
    <section id="transferRequests" style={{ marginTop: '4.3em' }}>
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12}>
          <GeneralGrid
            container
            spacing={3}
            justify="space-between"
            alignContent="space-between"
          >
            <GeneralGrid item xs={3}>
              <span
                style={{
                  fontFamily: 'Roboto',
                  fontWeight: 500,
                  fontSize: 24,
                }}
              >
                Pedidos de transferência
              </span>
              <Typography variant="body1" style={{ marginTop: 20 }}>
                {props.transferRequests.list.content.length} pedidos de
                transferência
              </Typography>
            </GeneralGrid>
            <GeneralGrid item xs={3}>
              <InputLabelOutlined
                input={{ onChange: handleChange, value: value }}
                meta={{}}
                // onChange={handleChange}
                // value={value}
                label="Procurar"
                name="search"
                margin="normal"
              />
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid container spacing={3}>
            <GeneralGrid item xs={12}>
              <Table6
                title="TransferRequests"
                columns={columns}
                data={data}
                router={router}
                onRowClick={onRowClick}
              />
            </GeneralGrid>
          </GeneralGrid>
        </GeneralGrid>
      </GeneralGrid>
      <AlertDialogSlide
        visible={visible}
        title="Distribuir créditos"
        onClose={() => setVisible(false)}
      >
        {request ? (
          parseInt(request.status.id) === 1 ? (
            <>
              <Box container spacing={3}>
                <Typography variant="subtitle1">
                  Ao clicar em "Confirmar" você irá distribuir R$
                  {request ? request.points : null} para
                  {request.user.type_id === 2
                    ? ' o participante '
                    : ' a estação '}
                  {request.user.type_id === 2
                    ? request.user.name
                    : request.stationName}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="flex-end">
                <PrimaryButton
                  onClick={() => postTransferRequest()}
                  disabled={props.transferRequests.buttonLoading}
                  progress={parseInt(props.general.submitProgress)}
                >
                  {props.transferRequests.buttonLoading
                    ? 'Processando'
                    : 'Confirmar'}
                </PrimaryButton>
              </Box>
            </>
          ) : (
            <Box container spacing={3}>
              <Typography variant="subtitle1">
                Você não pode distruibuir o mesmo pedido mais de uma vez. O
                status atual desse pedido é: {request.status.name}
              </Typography>
            </Box>
          )
        ) : null}
      </AlertDialogSlide>
    </section>
  );
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    transferRequests: state.transferRequests,
    general: state.general,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getList,
      remove,
      buyPoints,
      userBuyPoints,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TransferRequests);
