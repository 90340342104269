import { Box } from '@material-ui/core';
import MaterialGrid from '@material-ui/core/Grid';
import PrimaryButton from 'common/components/buttons/primaryButton';
import { InputLabelOutlined } from 'common/components/fields/fields';
import LoadingProgress from 'common/components/progress/loading';
import { FORM_RULES } from 'helpers/validations';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import {
  create,
  createCategoryType,
  fileRejected,
  getDetail,
  initializeForm,
  setDetail,
  setLoading,
  update,
} from './categoriesActions';

let TemplatesTypesCategoriesForm = function(props) {
  function onSubmit(values) {
    props.createCategoryType(values);
  }

  return props.categories.loading ? (
    <Box display="flex" height="100vh" justifyContent="center" mt={10}>
      <LoadingProgress />
    </Box>
  ) : (
    <>
      <section id="categories-form" style={{ marginTop: '4.3em' }}>
        <div className="card">
          <div className="card-body">
            <p className=" h4 card-title text-muted">Novo tipo de categoria</p>
            <Form
              role="form"
              onSubmit={props.handleSubmit(onSubmit)}
              noValidate
            >
              <MaterialGrid
                container
                spacing={3}
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <MaterialGrid item xs={12} md={6} lg={4}>
                  <Field
                    component={InputLabelOutlined}
                    label="Nome"
                    name="name"
                    validate={[FORM_RULES.required]}
                    required
                    margin="normal"
                  />
                </MaterialGrid>
              </MaterialGrid>
              <PrimaryButton
                type="submit"
                disabled={props.categories.buttonLoading}
                progress={parseInt(props.general.submitProgress)}
              >
                {props.categories.buttonLoading ? 'Enviando' : 'Enviar'}
              </PrimaryButton>
            </Form>
          </div>
        </div>
      </section>
    </>
  );
};

TemplatesTypesCategoriesForm = reduxForm({
  form: 'templatesTypesCategoriesForm',
})(TemplatesTypesCategoriesForm);

const mapStateToProps = state => {
  return {
    auth: state.auth,
    categories: state.templatesCategories,
    formValues: getFormValues('templatesTypesCategoriesForm')(state),
    general: state.general,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      createCategoryType,
      getDetail,
      setDetail,
      initializeForm,
      create,
      update,
      setLoading,
      fileRejected,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TemplatesTypesCategoriesForm);
