import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Dialog, Slide } from '@material-ui/core';
import MaterialGrid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import {
  EditorConvertToHTMLActivity,
  SelectLabel,
} from 'common/components/fields/fields';
import LottieControl from 'common/components/lottie/lottie';
import { FORM_RULES } from 'helpers/validations';
import { getListById as getLottiesListById } from 'modules/admin/pages/lotties/lottiesActions';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, getFormValues } from 'redux-form';
import PrimaryButton from '../../buttons/primaryButton';
import LoadingProgress from '../../progress/loading';
import { Container } from './styles';

const marks = [
  {
    value: 0,
    label: ' 0%',
  },
  {
    value: 100,
    label: '100%',
  },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let Results = function(props) {
  const {
    input: { onChange },
  } = props;
  const [open, setOpen] = useState(false);
  const [results, setResults] = useState([[0, 100]]);

  let lottiesList = props.lotties.list.content.map(item => {
    return {
      label: item.name,
      value: item.id,
      url: item.url,
    };
  });

  lottiesList.unshift({
    label: 'Nenhuma Animação',
    value: 'none',
    url: 'none',
  });

  function handleResult(index) {
    setOpen(index);
  }

  function handleClose() {
    setOpen(false);
  }

  function valuetext(values) {
    return `${values}%`;
  }

  const handleChange = (event, v, index) => {
    let len = results.length;
    let newRange = [...results];
    for (let j = 0; j < len; j++) {
      if (j === index) {
        newRange[j] = v;
      } else if (j < index) {
        newRange[j] = [results[j][0], newRange[j + 1][0]];
      } else if (j > index) {
        newRange[j] = [newRange[j - 1][1], results[j][1]];
      }
    }
    newRange[0][0] = 0;
    newRange[len - 1][1] = 100;
    onChange({
      ranges: newRange,
    });
    setResults(newRange);
  };

  const handleClick = () => {
    let n = results.length + 1;
    const localResults = [];
    for (let i = 0; i < n; i++) {
      if (i === 0) {
        localResults.push([0, parseInt(100 / n)]);
      } else if (i === n - 1) {
        localResults.push([parseInt(localResults[i - 1][1]), 100]);
      } else {
        localResults.push([
          parseInt(localResults[i - 1][1]),
          parseInt(localResults[i - 1][1]) + parseInt(100 / n),
        ]);
      }
    }
    onChange({
      ranges: localResults,
    });
    setResults(localResults);
  };

  const handleClickRemove = () => {
    let n = results.length - 1;
    const localResults = [];
    for (let i = 0; i < n; i++) {
      if (i === 0) {
        localResults.push([0, parseInt(100 / n)]);
      } else if (i === n - 1) {
        localResults.push([parseInt(localResults[i - 1][1]), 100]);
      } else {
        localResults.push([
          parseInt(localResults[i - 1][1]),
          parseInt(localResults[i - 1][1]) + parseInt(100 / n),
        ]);
      }
    }
    setResults(localResults);
  };

  useEffect(() => {
    if (props.formValues.results) {
      setResults(props.formValues.results.ranges);
    } else {
      onChange({
        ranges: [[0, 100]],
      });
    }
  }, []);

  function LottieChange(e) {
    if (props.formValues) {
      if (e.target.value !== 'none') props.getLottiesListById(e.target.value);
    }
  }

  return (
    <>
      <Box style={{ margin: '12px 0' }}>
        <PrimaryButton style={{ margin: 0 }} onClick={handleClick}>
          Adicionar resultado
        </PrimaryButton>
      </Box>
      {results.map((_, index) => (
        <>
          <Container>
            <Box
              display="flex"
              justifyContent="space-between"
              style={{ width: '100%' }}
            >
              <Slider
                value={results[index]}
                style={{ margin: '0 0 0 10px' }}
                onChange={(event, v) => handleChange(event, v, index)}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                getAriaValueText={valuetext}
                marks={marks}
              />
              <button type="button" onClick={() => handleResult(index)}>
                Resultado
              </button>
              <FontAwesomeIcon
                style={{
                  cursor: 'pointer',
                  margin: 'auto 0 auto 8px',
                  fontSize: 16,
                }}
                icon={faTrash}
                onClick={handleClickRemove}
              />
            </Box>
          </Container>
          <Dialog
            open={index === open}
            TransitionComponent={Transition}
            keepMounted
            style={{ padding: 10 }}
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <Box style={{ margin: 20 }}>
              <Field
                component={EditorConvertToHTMLActivity}
                label={'Feedback da pesquisa'}
                name={`result-${index}`}
                border
                validate={[FORM_RULES.required]}
              />
              <MaterialGrid
                item
                xs={12}
                style={{ minHeight: 100, marginTop: 50 }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <MaterialGrid item xs={6}>
                    <Field
                      component={SelectLabel}
                      options={lottiesList}
                      onChange={e => LottieChange(e)}
                      name={`feedback_lottie_id_${index}`}
                      label="Escolha uma animação para feedback"
                    />
                  </MaterialGrid>
                  <MaterialGrid item xs={6}>
                    <div style={{ height: 100 }}>
                      {props.formValues ? (
                        props.formValues[`feedback_lottie_id_${index}`] !==
                        'none' ? (
                          props.lotties.loading ? (
                            <Box display="flex" justifyContent="center">
                              <LoadingProgress />
                            </Box>
                          ) : (
                            <Box display="flex" justifyContent="center">
                              <LottieControl
                                width={100}
                                height={100}
                                loop={false}
                                animation={props.lotties.detail.json}
                                hasReplayButton
                              />
                            </Box>
                          )
                        ) : null
                      ) : null}
                    </div>
                  </MaterialGrid>
                </Box>
              </MaterialGrid>
            </Box>
          </Dialog>
        </>
      ))}
    </>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    templates: state.templates,
    general: state.general,
    lotties: state.lotties,
    formValues: getFormValues('templatesForm')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getLottiesListById,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Results);
