import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import moment from 'moment';
import type from './types';
import _ from 'lodash';

const URL_CONTACTS_BY_STATION_ID = `${BASE_API}/users-stations`;
const URL_ACTIVATE_USERS = `${BASE_API}/auth/signup/activate-participant`

export const getParticipantsList = id => {
  return dispatch => {
    dispatch({ type: type.LIST_LOAD, payload: true });
    axios
      .get(`${URL_CONTACTS_BY_STATION_ID}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        let newResponse = response.data;
        if (response.data.length > 0) {
          if (
            !_.isUndefined(response.data[0].user) &&
            !_.isNull(response.data[0].user)
          ) {
            for (let i in response.data[0].user.activities) {
              response.data[0].user.activities[i]['createdAtDate'] = moment(
                response.data[0].user.activities[i].createdAt
              ).format('DD/MM/YYYY HH:mm:ss');
            }
          }

          newResponse = response.data.map((item, index) => {
            return {
              ...item,
              isFiliated: item.filiated ? 'Sim' : 'Não',
              isInternal: item.internal ? 'Sim' : 'Não',
            };
          });
        }
        dispatch([
          {
            type: type.LIST_FETCHED,
            payload: newResponse,
          },
        ]);
      });
  };
};


export const activateUser = data => {
  return dispatch => {
    dispatch({ type: type.LIST_LOAD, payload: true });
    axios
      .post(`${URL_ACTIVATE_USERS}`, data, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        dispatch([{ type: type.LIST_LOAD, payload: false }, getParticipantsList(data.station)]);
      });
  };
};
