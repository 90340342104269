import { Box } from '@material-ui/core';
import MaterialGrid from '@material-ui/core/Grid';
import PrimaryButton from 'common/components/buttons/primaryButton';
import { InputLabelOutlined } from 'common/components/fields/fields';
import LoadingProgress from 'common/components/progress/loading';
import { FORM_RULES } from 'helpers/validations';
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import { SelectLabelOutlined } from '../../../../common/components/fields/fields';
import {
  create,
  fileRejected,
  getDetail,
  getTypeList,
  initializeForm,
  setDetail,
  setLoading,
  update,
  createCategory,
} from './categoriesActions';
import TemplatesTypesCategoriesForm from './categoriesTypeForm';

let CategoriesForm = function(props) {
  useEffect(() => {
    props.getTypeList();
  }, []);

  function onSubmit(values) {
    props.createCategory(values, props.router);
  }

  const templatesTypes = useMemo(() => {
    if (props.categories && props.categories.listType) {
      return props.categories.listType.map(item => ({
        label: item.name,
        value: item.id,
      }));
    } else {
      return [];
    }
  }, [props.categories && props.categories.listType]);

  return props.categories.loading ? (
    <Box display="flex" height="100vh" justifyContent="center" mt={10}>
      <LoadingProgress />
    </Box>
  ) : (
    <>
      <TemplatesTypesCategoriesForm />
      <section id="categories-form" style={{ marginTop: '4.3em' }}>
        <div className="card">
          <div className="card-body">
            <p className=" h4 card-title text-muted">Nova categoria</p>
            <Form
              role="form"
              onSubmit={props.handleSubmit(onSubmit)}
              noValidate
            >
              <MaterialGrid
                container
                spacing={3}
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <MaterialGrid item xs={12} md={6} lg={4}>
                  <Field
                    component={InputLabelOutlined}
                    label="Nome"
                    name="name"
                    validate={[FORM_RULES.required]}
                    required
                    margin="normal"
                  />
                </MaterialGrid>
                <MaterialGrid item xs={12} md={6} lg={4}>
                  <Field
                    component={SelectLabelOutlined}
                    label="Tipo"
                    options={templatesTypes}
                    name="type"
                    validate={[FORM_RULES.required]}
                    required
                    margin="normal"
                  />
                </MaterialGrid>
              </MaterialGrid>
              <PrimaryButton
                type="submit"
                disabled={props.categories.buttonLoading}
                progress={parseInt(props.general.submitProgress)}
              >
                {props.categories.buttonLoading ? 'Enviando' : 'Enviar'}
              </PrimaryButton>
            </Form>
          </div>
        </div>
      </section>
    </>
  );
};

CategoriesForm = reduxForm({ form: 'categoriesForm' })(CategoriesForm);

const mapStateToProps = state => {
  return {
    auth: state.auth,
    categories: state.templatesCategories,
    formValues: getFormValues('categoriesForm')(state),
    general: state.general,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      createCategory,
      getTypeList,
      getDetail,
      setDetail,
      initializeForm,
      create,
      update,
      setLoading,
      fileRejected,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesForm);
