import { Box, Typography } from '@material-ui/core';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import PrimaryButton from 'common/components/buttons/primaryButton';
import { InputLabelOutlined } from 'common/components/fields/fields';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import Table6 from 'common/components/table/table6';
import { tableSearch } from 'helpers/utils';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getList, remove } from './templatesActions';

function Templates(props) {
  useEffect(() => {
    props.getList();
  }, []);

  const { router } = props;

  const [columns] = useState([
    { title: 'Nome:', field: 'name' },
    { title: 'Tipo:', field: 'type.name' },
    { title: 'Descrição:', field: 'description' },
  ]);
  const [data, setData] = useState([]);
  const [value, setValue] = useState('');

  useEffect(() => {
    setData(props.templates.list.content);
  }, [props.templates.list.content]);

  function handleChange(e) {
    setData(tableSearch(e, props.news.list.content));
    setValue(e.target.value);
  }

  function handleDelete(id, resolve, reject) {
    props.remove(id, resolve, reject);
  }

  function onRowClick(e, rowData) {
    router.push(`/admin/modelos/${rowData.id}/editar`);
  }

  function renderEmptyScreen() {
    return (
      <section id="templates" style={{ marginTop: '4.3em', height: '100vh' }}>
        <GeneralGrid container spacing={3} justify="center" alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <div className="pl-4">
                  <h2 className="signup-text">
                    Você ainda não possui modelos.
                  </h2>
                  <p className="">
                    Seus modelos são as pessoas que são ou não associados da sua
                    empresa. Ao importá-los, você poderá oferecer atividades
                    promocionais para eles.
                  </p>
                  <PrimaryButton
                    onClick={() => props.router.push('/admin/rascunhos/novo')}
                  >
                    Criar Modelos
                  </PrimaryButton>
                </div>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img
              src={placeholder_illustration}
              className="img-fluid"
              alt="Não há dados"
            />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }

  if (props.templates.loading) {
    return (
      <Box display="flex" justifyContent="center" height="100vh" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }
  if (props.templates.list.content.length < 1) {
    return renderEmptyScreen();
  }

  return (
    <section id="templates" style={{ marginTop: '4.3em' }}>
      <GeneralGrid container spacing={3} justify="space-between">
        <GeneralGrid item sm={5} xs={12} md={6} lg={6}>
          <Typography
            style={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: 24 }}
          >
            Lista de Modelos
          </Typography>
          <Typography variant="body1">
            {props.templates.list.content.length} modelos
          </Typography>
        </GeneralGrid>
        <GeneralGrid item xs={3}>
          <InputLabelOutlined
            input={{ onChange: handleChange, value: value }}
            meta={{}}
            onChange={handleChange}
            value={value}
            label="Procurar"
            name="search"
            margin="normal"
          />
        </GeneralGrid>
      </GeneralGrid>
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12}>
          <Table6
            title="Templates"
            columns={columns}
            data={data}
            router={router}
            onRowClick={onRowClick}
            remove
            handleDelete={handleDelete}
          />
        </GeneralGrid>
      </GeneralGrid>
    </section>
  );
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    templates: state.templates,
    general: state.general,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators({ getList, remove }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Templates);
