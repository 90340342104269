import { Box, Typography } from '@material-ui/core';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import AddButton from 'common/components/buttons/addButton';
import PrimaryButton from 'common/components/buttons/primaryButton';
import { InputLabelOutlined } from 'common/components/fields/fields';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import Table6 from 'common/components/table/table6';
import { tableSearch } from 'helpers/utils';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getList, remove } from './categoriesActions';

export function Categories(props) {
  const { router } = props;
  const { list, loading } = props.categories;

  const [columns] = useState([
    { field: 'name', title: 'Nome:' },
    { field: 'description', title: 'Descrição:' },
  ]);
  const [data, setData] = useState([]);
  const [value, setValue] = useState('');

  useEffect(() => {
    props.getList();
  }, []);

  useEffect(() => {
    setData(props.categories.list.content);
  }, [props.categories.list.content]);

  function handleChange(e) {
    setData(tableSearch(e, props.categories.list.content));
    setValue(e.target.value);
  }

  function onRowClick(e, rowData) {
    router.push(`/admin/categorias/${rowData.id}/editar`);
  }

  function renderEmptyScreen() {
    return (
      <section
        id="statement-buy-points"
        style={{ marginTop: '4.3em', height: '100vh' }}
      >
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <h2 className="signup-text">
                  Você ainda não criou nenhuma categoria
                </h2>
                <PrimaryButton
                  onClick={() => props.router.push('/admin/categorias/novo')}
                >
                  Criar Categoria
                </PrimaryButton>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img
              src={placeholder_illustration}
              className="img-fluid"
              alt="Ilustração: não há dados"
            />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }
  if (loading) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }
  if (list.content.length < 1) {
    return renderEmptyScreen();
  }
  return (
    <section id="categories">
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12}>
          <GeneralGrid
            container
            spacing={3}
            justify="space-between"
            alignContent="space-between"
          >
            <GeneralGrid item xs={3}>
              <span
                style={{
                  fontFamily: 'Roboto',
                  fontWeight: 500,
                  fontSize: 24,
                }}
              >
                Categorias
              </span>
              <Typography variant="body1" style={{ marginTop: 20 }}>
                {props.categories.list.content.length} categorias
              </Typography>
            </GeneralGrid>
            <GeneralGrid item xs={3}>
              <InputLabelOutlined
                input={{ onChange: handleChange, value: value }}
                meta={{}}
                onChange={handleChange}
                value={value}
                label="Procurar"
                name="search"
                margin="normal"
              />
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid container spacing={3}>
            <GeneralGrid item xs={12}>
              <Table6
                title="Categorias"
                columns={columns}
                data={data}
                router={router}
                onRowClick={onRowClick}
              />
            </GeneralGrid>
          </GeneralGrid>
        </GeneralGrid>
      </GeneralGrid>

      <AddButton onClick={() => props.router.push('/admin/categorias/novo')} />
    </section>
  );
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    categories: state.categories,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getList,
      remove,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
