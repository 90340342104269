import { Box, Typography } from '@material-ui/core';
import PrimaryButton from 'common/components/buttons/primaryButton';
import { SelectLabel } from 'common/components/fields/fields';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import { FORM_RULES } from 'helpers/validations';
import { getListById as getLottiesListById } from 'modules/admin/pages/lotties/lottiesActions';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Field,
  Form,
  formValueSelector,
  getFormValues,
  initialize,
  reduxForm,
} from 'redux-form';
import { showToastr } from '../templatesActions';
import TemplateRedirectLink from '../templatesFormTypes/temaplateRedirectLink';
import TemplateBuyEarn from '../templatesFormTypes/templateCoupons';
import TemplateContent from '../templatesFormTypes/templateContent';
import TemplateMemberGetMember from '../templatesFormTypes/templateMemberGetMember';
import TemplateMemberGetPermission from '../templatesFormTypes/templateMemberGetPermission';
import TemplatePresentialCourse from '../templatesFormTypes/templatePresentialCourse';
import TemplateSignupAndEarn from '../templatesFormTypes/templateSignupAndEarn';
import TemplateCheckListQuiz from '../templatesFormTypes/templatesQuizes/templateCheckListQuiz';
import TemplateCorrectAnswerQuiz from '../templatesFormTypes/templatesQuizes/templateCorrectAnswerQuiz';
import TemplatePersonalityQuiz from '../templatesFormTypes/templatesQuizes/templatePersonalityQuiz';
import TemplatePoll from '../templatesFormTypes/templatesQuizes/templatePoll';
import TemplateQuiz from '../templatesFormTypes/templatesQuizes/templateQuiz';
import TemplateResearch from '../templatesFormTypes/templatesQuizes/templateResearch';
import TemplateProductDelivery from '../templatesFormTypes/templateProductDelivery';
import TemplateAgreement from '../templatesFormTypes/templateAgreement';
import TemplateRawContent from '../templatesFormTypes/templateRawContent';
import TemplateDonation from '../templatesFormTypes/templateDonation';
import TemplateVideoPlaylist from '../templatesFormTypes/templateVideoPlaylist';

const changeFieldValue = (form, value) => {
  return dispatch => {
    dispatch(initialize(form, value));
  };
};

class ResearchActionInteraction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      visible: true,
      questions: [],
      activeIndex: 1,
    };
  }

  renderPresentialCourseTemplate() {
    return (
      <TemplatePresentialCourse
        listPartners={this.props.listPartners}
        formValues={this.props.formValues}
      />
    );
  }

  renderCouponsTemplate() {
    return <TemplateBuyEarn {...this.props} />;
  }

  renderProductDelivery() {
    return <TemplateProductDelivery {...this.props} />;
  }

  renderMemberGetMemberTemplate() {
    return <TemplateMemberGetMember {...this.props} />;
  }

  renderSignupAndEarn() {
    return <TemplateSignupAndEarn {...this.props} />;
  }

  renderAgreement() {
    return <TemplateAgreement {...this.props} />;
  }

  renderResearchTemplate() {
    return <TemplateResearch {...this.props} />;
  }

  renderQuizTemplate() {
    return <TemplateQuiz {...this.props} />;
  }

  renderPollTemplate() {
    return <TemplatePoll {...this.props} />;
  }

  renderCheckListQuizTemplate() {
    return <TemplateCheckListQuiz {...this.props} />;
  }

  renderCorrectAnswerQuizTemplate() {
    return <TemplateCorrectAnswerQuiz {...this.props} />;
  }

  renderContentTemplate() {
    return <TemplateContent router={this.props.router} {...this.props} />;
  }

  renderPersonalityQuizTemplate() {
    return <TemplatePersonalityQuiz {...this.props} />;
  }

  renderRedirectLink() {
    return <TemplateRedirectLink {...this.props} />;
  }

  renderRawContent() {
    return <TemplateRawContent {...this.props} />;
  }
  renderDonation() {
    return <TemplateDonation {...this.props} />;
  }
  renderVideoPlaylist() {
    return <TemplateVideoPlaylist {...this.props} />;
  }
  renderMemberGetPermission() {
    return <TemplateMemberGetPermission {...this.props} />;
  }

  componentDidMount() {}

  checkMoneyStatus = values => {
    //presential course
    if (this.props.selectedValue === 4) {
      if (values.isNoDiscountPriceFree) {
        values.price = 0.0;
      }
      if (values.isDiscountPriceFree) {
        values.discount_price = 0.0;
      }

      //nps for members
    } else if (this.props.selectedValue === 8) {
      //cupoms
    } else if (this.props.selectedValue === 2) {
    }
  };

  onSubmit = values => {
    if (values.results) {
      for (let i = 0; i < values.results.length; i++) {
        if (values.results[i].feedback_lottie_id === 'none') {
          values.results[i].feedback_lottie_id = null;
        }
      }
    }

    this.checkMoneyStatus(values);
    this.props.onClickProgress(values);
  };

  renderSteps = () => {
    return (
      <section id="templates-form" style={{ marginTop: '1em' }}>
        <GeneralGrid container spacing={3}>
          <GeneralGrid item xs={12}>
            <Form
              role="form"
              onSubmit={this.props.handleSubmit(this.onSubmit)}
              noValidate
            >
              <GeneralGrid container spacing={3}>
                <GeneralGrid item xs={12}>
                  <Field
                    component={SelectLabel}
                    options={[
                      { label: 'Convide e Ganhe', value: 1 },
                      { label: 'Cadastre e Ganhe', value: 2 },
                      { label: 'Compre e Ganhe', value: 3 },
                      { label: 'Compartilhe e Ganhe', value: 5 },
                      { label: 'Aula Presencial', value: 4 },
                      { label: 'Responda pesquisa e ganhe', value: 8 },
                      { label: 'Quiz resposta(s) certa(s)', value: 6 },
                      { label: 'Pesquisa de opinião', value: 10 },
                      { label: 'Check-list quiz', value: 9 },
                      { label: 'Check resposta certa', value: 11 },
                      { label: 'Check de personalidades', value: 12 },
                      { label: 'Redirecionar para Link', value: 13 },
                      { label: 'Entrega de Produtos', value: 14 },
                      { label: 'Termos de aceite', value: 15 },
                      { label: 'Conteúdo sem interação', value: 16 },
                      { label: 'Doação', value: 17 },
                      { label: 'Video Playlist', value: 18 },
                      { label: 'Convite para estação fechada', value: 19 },
                      {
                        label: 'Redirecionar link com área interna',
                        value: 20,
                      },
                    ]}
                    name="type_action_id"
                    label="Interação esperada"
                    validate={[FORM_RULES.required]}
                    required
                  />
                </GeneralGrid>
              </GeneralGrid>
              {this.props.selectedValue === 4
                ? this.renderPresentialCourseTemplate()
                : this.props.selectedValue === 3
                ? this.renderCouponsTemplate()
                : this.props.selectedValue === 1
                ? this.renderMemberGetMemberTemplate()
                : this.props.selectedValue === 2
                ? this.renderSignupAndEarn()
                : this.props.selectedValue === 5
                ? this.renderContentTemplate()
                : this.props.selectedValue === 8
                ? this.renderResearchTemplate()
                : this.props.selectedValue === 6
                ? this.renderQuizTemplate()
                : this.props.selectedValue === 10
                ? this.renderPollTemplate()
                : this.props.selectedValue === 9
                ? this.renderCheckListQuizTemplate()
                : this.props.selectedValue === 11
                ? this.renderCorrectAnswerQuizTemplate()
                : this.props.selectedValue === 12
                ? this.renderPersonalityQuizTemplate()
                : this.props.selectedValue === 13
                ? this.renderRedirectLink()
                : this.props.selectedValue === 14
                ? this.renderProductDelivery()
                : this.props.selectedValue === 15
                ? this.renderAgreement()
                : this.props.selectedValue === 16
                ? this.renderRawContent()
                : this.props.selectedValue === 17
                ? this.renderDonation()
                : this.props.selectedValue === 18
                ? this.renderVideoPlaylist()
                : this.props.selectedValue === 19
                ? this.renderMemberGetPermission()
                : this.props.selectedValue === 20
                ? this.renderRedirectLink()
                : null}

              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  style={{ cursor: 'pointer' }}
                  onClick={this.props.prevClick}
                  color="primary"
                >
                  Anterior
                </Typography>
                <PrimaryButton
                  type="submit"
                  color="primary"
                  disabled={this.props.templatesDrafts.buttonLoading}
                  progress={parseInt(this.props.general.submitProgress)}
                >
                  {this.props.templatesDrafts.buttonLoading
                    ? 'Salvando'
                    : 'Salvar e Continuar'}
                </PrimaryButton>
              </Box>
            </Form>
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  };

  render() {
    return this.renderSteps();
  }
}

ResearchActionInteraction = reduxForm({
  form: 'templatesForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(ResearchActionInteraction);

const mapStateToProps = state => {
  return {
    auth: state.auth,
    general: state.general,
    templates: state.templates,
    templatesDrafts: state.templatesDrafts,
    formValues: getFormValues('templatesForm')(state),
    lotties: state.lotties,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { changeFieldValue, showToastr, getLottiesListById },
    dispatch
  );
};

const selector = formValueSelector('templatesForm');

ResearchActionInteraction = connect(state => {
  const selectedValue = selector(state, 'type_action_id');
  return { selectedValue };
})(ResearchActionInteraction);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResearchActionInteraction);
