import { Box, Typography } from '@material-ui/core';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import { InputLabelOutlined } from 'common/components/fields/fields';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import Table6 from 'common/components/table/table6';
import { tableSearch } from 'helpers/utils';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getpartnersList } from './partnersActions';

export function partners(props) {
  const { router } = props;
  const { list, loading } = props.partners;

  const [columns] = useState([
    { field: 'name', title: 'Nome do Parceiro' },
    { field: 'establishments[0].name', title: 'Nome da Empresa' },
    { field: 'createdAt', title: 'Data de Cadastro' },
  ]);
  const [data, setData] = useState([]);
  const [value, setValue] = useState('');

  useEffect(() => {
    props.getpartnersList();
  }, []);

  useEffect(() => {
    setData(props.partners.list.content);
  }, [props.partners.list.content]);

  function handleChange(e) {
    setData(tableSearch(e, props.partners.list.content));
    setValue(e.target.value);
  }

  function renderEmptyScreen() {
    return (
      <section
        id="statement-buy-points"
        style={{ marginTop: '4.3em', height: '100vh' }}
      >
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <h2 className="signup-text">
                  Você ainda não criou nenhuma categoria
                </h2>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img
              src={placeholder_illustration}
              className="img-fluid"
              alt="Ilustração: não há dados"
            />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }

  if (loading) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }
  if (list.content.length < 1) {
    return renderEmptyScreen();
  }
  return (
    <section id="partners" style={{ marginTop: '4.3em' }}>
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12}>
          <GeneralGrid
            container
            spacing={3}
            justify="space-between"
            alignContent="space-between"
          >
            <GeneralGrid item xs={3}>
              <span
                style={{
                  fontFamily: 'Roboto',
                  fontWeight: 500,
                  fontSize: 24,
                }}
              >
                Lista de Parceiroes
              </span>
              <Typography variant="body1" style={{ marginTop: 20 }}>
                {props.partners.list.content.length} Parceiroes
              </Typography>
            </GeneralGrid>
            <GeneralGrid item xs={3}>
              <InputLabelOutlined
                input={{ onChange: handleChange, value: value }}
                meta={{}}
                onChange={handleChange}
                value={value}
                label="Procurar"
                name="search"
                margin="normal"
              />
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid container spacing={3}>
            <GeneralGrid item xs={12}>
              <Table6
                title="Parceiroes"
                columns={columns}
                data={data}
                router={router}
              />
            </GeneralGrid>
          </GeneralGrid>
        </GeneralGrid>
      </GeneralGrid>
    </section>
  );
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    partners: state.partners,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getpartnersList,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(partners);
