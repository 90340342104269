import { Box, Typography } from '@material-ui/core';
import MaterialGrid from '@material-ui/core/Grid';
import PrimaryButton from 'common/components/buttons/primaryButton';
import { InputLabelOutlined } from 'common/components/fields/fields';
import { currencyMask } from 'helpers/masks';
import states from 'helpers/states.json';
import { FORM_RULES } from 'helpers/validations';
import { getList as getCategoriesList } from 'modules/admin/pages/categories/categoriesActions';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import { getManagersList } from '../../managers/managersActions';
import {
  SelectLabel,
  SelectMultiple,
  InputSwitchLarge,
  SelectLabelOutlined,
} from 'common/components/fields/fields';
import { getList as getTemplatesCategoriesList } from '../../templates-categories/categoriesActions';

class Publish extends Component {
  constructor(props) {
    super(props);
    this.state = { states: states };
    props.getManagersList();
    props.getCategoriesList();
    props.getTemplatesCategoriesList();
  }

  componentDidMount() {
    let orderedStates = [...states];
    orderedStates.sort(function(a, b) {
      if (a.id === 10) {
        return -1;
      }
      if (b.id === 10) {
        return 1;
      }
      if (a.sigla < b.sigla) {
        return -1;
      }
      if (a.sigla > b.sigla) {
        return 1;
      }
      return 0;
    });

    this.setState({ states: orderedStates });
  }

  onSubmit = values => {
    this.props.onClickProgress(values);
  };

  render() {
    const stationsList = this.props.stations.list.content.map(item => {
      if (item.establishments[0]) {
        return {
          value: item.establishments[0].id,
          label: item.establishments[0].stations[0].name,
        };
      } else {
        return null;
      }
    });

    let finalStationsList = [
      { value: 'none', label: 'Nenhuma' },
      { value: 'all', label: 'Todas' },
    ];
    stationsList.forEach(item => {
      if (item) {
        finalStationsList.push(item);
      }
    });

    const partnersList = this.props.partners.list.content.map(item => ({
      value: item.id,
      label: item.name,
    }));

    let finalPartnersList = [
      { value: 'none', label: 'Nenhum' },
      { value: 'all', label: 'Todos' },
    ];
    partnersList.forEach(item => {
      if (item) {
        finalPartnersList.push(item);
      }
    });

    const statesList = this.state.states.map((item, index) => ({
      value: item.sigla,
      label: item.nome,
    }));

    const ageError = "A idade 'De' está menor que 'Para'";

    const categoriesList = this.props.categories.list.content.map(
      (item, index) => ({
        value: item.id,
        label: item.name,
      })
    );
    const templatesCategoriesList = this.props.templatesCategories.list.content.map(
      (item, index) => ({
        value: item.id,
        label: item.name,
      })
    );
    return (
      <>
        <Form
          role="form"
          onSubmit={this.props.handleSubmit(this.onSubmit)}
          noValidate
        >
          <Typography
            style={{ fontSize: 16, fontWeight: '400', marginBottom: 10 }}
            variant="subtitle2"
          >
            {'Categoria'}
          </Typography>
          {parseInt(this.props.formValues.type_action_id) !== 2 && (
            <>
              <MaterialGrid container spacing={3}>
                <MaterialGrid item xs={12}>
                  <Field
                    component={SelectMultiple}
                    label={'Categoria de atividade'}
                    options={categoriesList}
                    multiple
                    disabled={this.props.disabled}
                    name="categories"
                    required
                    validate={[FORM_RULES.required]}
                    margin="normal"
                  />
                </MaterialGrid>
              </MaterialGrid>
            </>
          )}
          <MaterialGrid container spacing={3}>
            <MaterialGrid item xs={12}>
              <Field
                component={SelectLabelOutlined}
                label={'Categoria de template'}
                options={templatesCategoriesList}
                multiple
                disabled={this.props.disabled}
                name="category"
                required
                validate={[FORM_RULES.required]}
                margin="normal"
              />
            </MaterialGrid>
          </MaterialGrid>
          <MaterialGrid container spacing={3}>
            <MaterialGrid item xs={12}>
              <Field
                component={InputSwitchLarge}
                label={'A atividade é paga?'}
                name="is_action_paid"
                disabled={this.props.disabled}
              />
            </MaterialGrid>
            {this.props.formValues ? (
              this.props.formValues.is_action_paid ? (
                <>
                  <MaterialGrid item xs={12}>
                    <Field
                      component={InputLabelOutlined}
                      label={'Preço da atividade sem desconto'}
                      name="action_price_before"
                      disabled={this.props.disabled}
                      type="money"
                      required
                      validate={[FORM_RULES.required]}
                      margin="normal"
                      {...currencyMask}
                    />
                  </MaterialGrid>
                  <MaterialGrid item xs={12}>
                    <Field
                      component={InputLabelOutlined}
                      label={'Preço final da atividade'}
                      name="action_price"
                      disabled={this.props.disabled}
                      type="money"
                      required
                      validate={[FORM_RULES.required]}
                      margin="normal"
                      {...currencyMask}
                    />
                  </MaterialGrid>
                </>
              ) : null
            ) : null}
          </MaterialGrid>
          {parseInt(this.props.formValues.type_action_id) !== 2 ? (
            <>
              <Field
                component={InputSwitchLarge}
                label={'Definir público alvo'}
                name="has_target_audience"
                disabled={this.props.disabled}
              />
              {this.props.formValues ? (
                this.props.formValues.has_target_audience ? (
                  <>
                    <MaterialGrid container spacing={3}>
                      <MaterialGrid item xs={12} sm={12}>
                        <Field
                          component={SelectLabel}
                          type="text"
                          label={`Associados`}
                          name={`associated`}
                          disabled={this.props.disabled}
                          options={[
                            { value: 'all', label: 'Todos' },
                            { value: true, label: 'Associados' },
                            { value: false, label: 'Não associados' },
                          ]}
                          validate={[FORM_RULES.required]}
                        />
                      </MaterialGrid>
                    </MaterialGrid>
                    <MaterialGrid container spacing={3}>
                      <MaterialGrid item xs={12} sm={12}>
                        <Field
                          component={SelectLabel}
                          type="text"
                          label={`Empregados`}
                          name={`internal`}
                          disabled={this.props.disabled}
                          options={[
                            { value: 'all', label: 'Todos' },
                            { value: true, label: 'Empregados' },
                            { value: false, label: 'Não empregados' },
                          ]}
                          validate={[FORM_RULES.required]}
                        />
                      </MaterialGrid>
                    </MaterialGrid>
                    <MaterialGrid container spacing={3}>
                      <MaterialGrid item xs={12} sm={12} md={6}>
                        <Field
                          component={InputLabelOutlined}
                          label="De (anos)"
                          name="from"
                          type="number"
                          disabled={this.props.disabled}
                          validate={[FORM_RULES.required]}
                          required
                          margin="normal"
                          helperText={
                            this.props.formValues
                              ? parseInt(this.props.formValues.from) >
                                parseInt(this.props.formValues.to)
                                ? ageError
                                : null
                              : null
                          }
                        />
                      </MaterialGrid>
                      <MaterialGrid item xs={12} sm={12} md={6}>
                        <Field
                          component={InputLabelOutlined}
                          label="Até (anos)"
                          name="to"
                          type="number"
                          disabled={this.props.disabled}
                          validate={[FORM_RULES.required]}
                          required
                          margin="normal"
                          helperText={
                            this.props.formValues
                              ? parseInt(this.props.formValues.from) >
                                parseInt(this.props.formValues.to)
                                ? ageError
                                : null
                              : null
                          }
                        />
                      </MaterialGrid>
                    </MaterialGrid>
                  </>
                ) : null
              ) : null}
              <MaterialGrid container spacing={3}>
                <MaterialGrid item xs={12} sm={12}>
                  <Field
                    component={InputSwitchLarge}
                    label={'Local de divulgação'}
                    name="has_divulgation_place"
                    disabled={this.props.disabled}
                  />
                  {this.props.formValues ? (
                    this.props.formValues.has_divulgation_place ? (
                      <Field
                        component={SelectMultiple}
                        label={'Estados que poderão participar'}
                        options={statesList}
                        multiple
                        name="states"
                        disabled={this.props.disabled}
                        required
                        validate={[FORM_RULES.required]}
                        margin="normal"
                      />
                    ) : null
                  ) : null}
                </MaterialGrid>
              </MaterialGrid>
            </>
          ) : null}
          <>
            <Field
              component={InputSwitchLarge}
              label={'Segmentação de plataforma'}
              name="has_stations"
              disabled={this.props.disabled}
            />
            {this.props.formValues ? (
              this.props.formValues.has_stations ? (
                <MaterialGrid container spacing={3}>
                  <MaterialGrid item xs={12} sm={12}>
                    <Field
                      component={SelectMultiple}
                      label={'Plataformas que poderão visualizar'}
                      options={finalStationsList}
                      multiple
                      name="stations"
                      disabled={this.props.disabled}
                      required
                      validate={[FORM_RULES.required]}
                      margin="normal"
                    />
                  </MaterialGrid>
                </MaterialGrid>
              ) : null
            ) : null}
          </>
          {this.props.formValues.type_action_id === 3 ||
          this.props.formValues.type_action_id === 4 ||
          this.props.formValues.type_action_id === 6 ||
          this.props.formValues.type_action_id === 8 ||
          this.props.formValues.type_action_id === 14 ||
          this.props.formValues.type_action_id === 16 ||
          this.props.formValues.type_action_id === 17 ||
          this.props.formValues.type_action_id === 18 ? (
            <>
              <Field
                component={InputSwitchLarge}
                label={'Segmentação de parceiro'}
                name="has_partners"
                disabled={this.props.disabled}
              />
              {this.props.formValues ? (
                this.props.formValues.has_partners ? (
                  <MaterialGrid container spacing={3}>
                    <MaterialGrid item xs={12} sm={12}>
                      <Field
                        component={SelectMultiple}
                        label={'Parceiros que poderão visualizar'}
                        options={finalPartnersList}
                        multiple
                        name="partners"
                        disabled={this.props.disabled}
                        required
                        validate={[FORM_RULES.required]}
                        margin="normal"
                      />
                    </MaterialGrid>
                  </MaterialGrid>
                ) : null
              ) : null}
            </>
          ) : null}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              style={{ cursor: 'pointer' }}
              onClick={this.props.prevClick}
              color="primary"
            >
              Anterior
            </Typography>
            <PrimaryButton
              type="submit"
              disabled={this.props.templates.buttonLoading}
              progress={parseInt(this.props.general.submitProgress)}
            >
              {this.props.templates.buttonLoading ? 'Publicando' : 'Publicar'}
            </PrimaryButton>
          </Box>
        </Form>
      </>
    );
  }
}

Publish = reduxForm({
  form: 'templatesForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(Publish);

const mapStateToProps = state => {
  return {
    auth: state.auth,
    contactsSurveys: state.contactsSurveys,
    formValues: getFormValues('templatesForm')(state),
    templates: state.templates,
    general: state.general,
    categories: state.categories,
    templatesCategories: state.templatesCategories,
    stations: state.managers,
    partners: state.partners,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { getCategoriesList, getManagersList, getTemplatesCategoriesList },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Publish);
