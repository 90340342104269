import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@material-ui/core';
import PrimaryButton from 'common/components/buttons/primaryButton';
import SecondaryButton from 'common/components/buttons/secondaryButton';
import { MaterialCheckbox } from 'common/components/fields/fields';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import Answers from 'common/components/quiz/Answers';
import { FORM_RULES } from 'helpers/validations';
import _ from 'lodash';
import React from 'react';
import { Field, FieldArray } from 'redux-form';
import Questions from '../../../../../../../common/components/quiz/Questions';
import { InputSwitchGrey } from '../../../../../../../common/components/fields/fields';

const renderOptions = props => (
  <ul style={{ listStyleType: 'none' }}>
    <li>
      <Box>
        <PrimaryButton onClick={() => props.fields.push()}>
          Adicionar opção
        </PrimaryButton>
      </Box>
    </li>
    {props.fields.map((option, index) => (
      <li key={index}>
        <GeneralGrid container style={{ marginBottom: 8 }}>
          <GeneralGrid item xs={6}>
            <Box display="flex" alignContent="center">
              <Field
                component={Answers}
                label={`Opção ${index + 1}`}
                name={`${option}.label`}
                validate={[FORM_RULES.required]}
                required
                margin="normal"
              />
              <FontAwesomeIcon
                style={{ cursor: 'pointer', margin: 'auto 8', fontSize: 16 }}
                icon={faTrash}
                onClick={() => props.fields.remove(index)}
              />
            </Box>
          </GeneralGrid>
          <GeneralGrid item xs={6}>
            <Box
              display="flex"
              alignContent="center"
              justifyContent="center"
              height="100%"
            >
              <Field
                component={MaterialCheckbox}
                label={`Esta é a resposta correta?`}
                name={`${option}.correct`}
              />
            </Box>
          </GeneralGrid>
        </GeneralGrid>
      </li>
    ))}
  </ul>
);

const renderQuestions = props => {
  return (
    <ul style={{ listStyleType: 'none' }}>
      <li>
        <Box>
          <PrimaryButton onClick={() => props.fields.push()}>
            Adicionar pergunta
          </PrimaryButton>
        </Box>
      </li>
      {props.fields.map((question, index) => (
        <li key={index}>
          <Box>
            <SecondaryButton
              onClick={() => props.fields.remove(index)}
              color="primary"
            >
              Excluir Pergunta
            </SecondaryButton>
          </Box>

          <GeneralGrid container spacing={3}>
            <GeneralGrid item xs={12} sm={12}>
              <Field
                component={Questions}
                label={`Pergunta ${index + 1}`}
                name={`${question}.question`}
                validate={[FORM_RULES.required]}
                required
                margin="normal"
              />
              {props.meta.submitFailed && props.meta.error && (
                <Typography color="error" variant="caption">
                  {props.meta.error}
                </Typography>
              )}
            </GeneralGrid>

            <GeneralGrid item xs={12} sm={12}>
              <FieldArray
                name={`${question}.options`}
                component={renderOptions}
              />
            </GeneralGrid>
          </GeneralGrid>
        </li>
      ))}
    </ul>
  );
};

export default function TemplateCorrectAnswerQuiz(props) {
  return (
    <>
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12} sm={12}>
          <h4 style={{ fontWeight: 'bold' }}>Configure suas perguntas:</h4>
        </GeneralGrid>
      </GeneralGrid>
      {_.isEmpty(props.formValues.questions) && (
        <p style={{ textAlign: 'center' }}>
          Ainda não há perguntas. Adicione algumas!
        </p>
      )}

      <FieldArray
        name="questions"
        component={renderQuestions}
        formValues={props.formValues}
        validate={[FORM_RULES.questions]}
        parentProps={props}
      />
      <GeneralGrid
        item
        xs={12}
        style={{
          marginTop: 10,
          paddingLeft: 10,
          paddingRight: 10,
          paddingTop: 0,
          paddingBottom: 8,
        }}
      >
        <Field
          component={InputSwitchGrey}
          label={'Tentativa errada conta como participação?'}
          boxStyle={{ backgroundColor: '#f7f7f7', padding: 16 }}
          firstState="Ativado"
          secondState="Selecione para ativar"
          name="error_count_as_participation"
          margin="normal"
        />
      </GeneralGrid>
    </>
  );
}
