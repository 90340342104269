import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import _ from 'lodash';
import { initialize, reset as resetForm } from 'redux-form';
import type from './types';

const URL = `${BASE_API}/cupoms/`;
const URL_COUPONS_BY_ESTABLISHMENT = `${BASE_API}/cupoms/establishment`;
const URL_COUPONS_TEMPLATES = `${BASE_API}/action-templates/type`;

export const getList = id => {
  return dispatch => {
    dispatch({ type: type.LIST_LOAD, payload: true });
    axios
      .get(`${URL_COUPONS_BY_ESTABLISHMENT}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        dispatch([
          {
            type: type.LIST_FETCHED,
            payload: response,
          },
        ]);
      });
  };
};

export const getListPage = page => {
  const request = axios.get(`${URL}?page=${page}`, {
    headers: {
      Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
    },
  });
  return dispatch => {
    dispatch([
      {
        type: type.LIST_LOAD,
        payload: true,
      },
      {
        type: type.LIST_FETCHED,
        payload: request,
      },
    ]);
  };
};

export const getListLimit = number => {
  const request = axios.get(`${URL}?limit=${number}`, {
    headers: {
      Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
    },
  });
  return dispatch => {
    dispatch([
      {
        type: type.LIST_LOAD,
        payload: true,
      },
      {
        type: type.LIST_FETCHED,
        payload: request,
      },
    ]);
  };
};

export const remove = (id, resolve, reject, other = undefined) => {
  //console.log(id, "entrou");
  return dispatch => {
    //console.log(id, "dispatch", reject, resolve);
    dispatch({ type: type.LIST_LOAD, payload: false });
    axios
      .delete(`${URL}${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        dispatch([
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
          {
            type: type.LIST_LOAD,
            payload: false,
          },

          resolve(),
          getList(other),
        ]);
      })
      .catch(e => {
        try {
          for (const i in e.response.data) {
            for (const j in e.response.data[i]) {
              dispatch({
                type: 'SNACKBAR',
                payload: {
                  message: `${i}: ${e.response.data.errors[i][j]}`,
                  variant: 'error',
                  open: true,
                },
              });
            }
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const getDetail = id => {
  return dispatch => {
    dispatch({
      type: type.LIST_LOAD,
      payload: true,
    });
    axios
      .get(`${URL}${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        const initialValues = {
          ...response.data[0],
        };
        dispatch([
          {
            type: type.ITEM_DETAIL,
            payload: response.data[0],
          },
          initializeForm(initialValues),
        ]);
      });
  };
};

export const setDetail = values => {
  return dispatch => {
    dispatch({
      type: type.LIST_LOAD,
      payload: true,
    });
    const initialValues = {
      ...values,
    };
    dispatch([
      {
        type: type.ITEM_DETAIL,
        payload: { data: values },
      },
      initializeForm(initialValues),
      reset(),
      getList(),
    ]);
  };
};

export const initializeForm = values => {
  return initialize('ProfileForm', values);
};

export const reset = () => {
  return resetForm('ProfileForm');
};

export const create = (values, router, firstTime) => {
  return submit(values, 'post', router, firstTime);
};

export const update = (values, router) => {
  return submit(values, 'put', router);
};

export const getCupomTemplates = () => {
  return dispatch => {
    dispatch({ type: type.CUPOM_TEMPLATES_LOAD, payload: true });
    axios
      .get(`${URL_COUPONS_TEMPLATES}/3`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        dispatch([
          {
            type: type.CUPOM_TEMPLATES_FETCHED,
            payload: response,
          },
        ]);
      });
  };
};

function submit(values, method, router = undefined, firstTime) {
  const id = values.id ? `${values.id}/` : '';
  return dispatch => {
    dispatch([
      { type: type.COUPON_BUTTON_LOAD, payload: true },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
    ]);
    axios[method](`${URL}${id}`, values, {
      headers: {
        Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
      },
      onUploadProgress: progressEvent =>
        dispatch([
          {
            type: 'SUBMIT_PROGRESS',
            payload: `${Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            )} %`,
          },
        ]),
    })
      .then(response => {
        /* dispatch([reset(), getList()]); */
        dispatch([
          { type: type.COUPON_BUTTON_LOAD, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
        ]);

        if (!_.isUndefined(router)) {
          if (firstTime) {
            router.push('/admin');
          } else {
            router.push('/admin/profile');
          }
        }
      })
      .catch(e => {
        dispatch([
          { type: type.COUPON_BUTTON_LOAD, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
        ]);

        try {
          for (const i in e.response.data) {
            for (const j in e.response.data[i]) {
              dispatch({
                type: 'SNACKBAR',
                payload: {
                  message: `${i}: ${e.response.data.errors[i][j]}`,
                  variant: 'error',
                  open: true,
                },
              });
            }
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
}
