import { Box } from '@material-ui/core';
import MaterialGrid from '@material-ui/core/Grid';
import PrimaryButton from 'common/components/buttons/primaryButton';
import { InputLabelOutlined } from 'common/components/fields/fields';
import LoadingProgress from 'common/components/progress/loading';
import { create_or_update, setFormValue } from 'helpers/formComponent';
import { FORM_RULES } from 'helpers/validations';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import {
  create,
  fileRejected,
  getDetail,
  initializeForm,
  setDetail,
  setLoading,
  update,
} from './categoriesActions';

let CategoriesForm = function(props) {
  const { categories, router, location, getDetail, setDetail } = props;

  useEffect(() => {
    if (!router.params.id) {
      props.setLoading(false);
    } else {
      setFormValue(categories, router, location, getDetail, setDetail);
    }
  }, []);

  function onSubmit(values) {
    const { create, update } = props;
    const newValues = {
      ...values,
    };
    let firstTime = false;

    create_or_update(router, newValues, create, update, firstTime);
  }

  return props.categories.loading ? (
    <Box display="flex" height="100vh" justifyContent="center" mt={10}>
      <LoadingProgress />
    </Box>
  ) : (
    <section id="categories-form" style={{ marginTop: '4.3em' }}>
      <div className="card">
        <div className="card-body">
          <p className=" h4 card-title text-muted">Formulário</p>
          <Form role="form" onSubmit={props.handleSubmit(onSubmit)} noValidate>
            <MaterialGrid
              container
              spacing={3}
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <MaterialGrid item xs={12} md={6} lg={4}>
                <Field
                  component={InputLabelOutlined}
                  label="Nome"
                  name="name"
                  validate={[FORM_RULES.required]}
                  required
                  margin="normal"
                />
              </MaterialGrid>
              <MaterialGrid item xs={12} md={6} lg={4}>
                <Field
                  component={InputLabelOutlined}
                  label="Ícone"
                  name="icon"
                  validate={[FORM_RULES.required]}
                  required
                  margin="normal"
                />
              </MaterialGrid>
            </MaterialGrid>
            <PrimaryButton
              type="submit"
              disabled={props.categories.buttonLoading}
              progress={parseInt(props.general.submitProgress)}
            >
              {props.categories.buttonLoading ? 'Enviando' : 'Enviar'}
            </PrimaryButton>
          </Form>
        </div>
      </div>
    </section>
  );
};

CategoriesForm = reduxForm({ form: 'categoriesForm' })(CategoriesForm);

const mapStateToProps = state => {
  return {
    auth: state.auth,
    categories: state.categories,
    formValues: getFormValues('categoriesForm')(state),
    general: state.general,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getDetail,
      setDetail,
      initializeForm,
      create,
      update,
      setLoading,
      fileRejected,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesForm);
