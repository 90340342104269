export const BASE_API =
  process.env.REACT_APP_API_KEY === 'production'
    ? 'elasticbeanstalk'
    : process.env.REACT_APP_API_KEY === 'test'
    ? 'https://apitest.fermento.app/api'
    : 'http://localhost:8888/api';

export const BASE_AUTH =
  process.env.REACT_APP_API_KEY === 'production'
    ? 'elasticbeanstalk/auth'
    : process.env.REACT_APP_API_KEY === 'test'
    ? 'https://apitest.fermento.app/api/auth'
    : 'http://localhost:8888/api/auth';

export const BASE_S3 = 'https://s3.amazonaws.com/rilato.app.files';

export const USER_KEY = '_fermentoAdminUser';
export const USER_TOKEN = '_fermentoAdminUserToken';
