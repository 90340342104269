import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import { appColors } from 'styles/colors.jsx';
import LinearDeterminate from '../progress/progress';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  primary: {
    color: appColors.light.level1,
    backgroundColor: appColors.primary,
    '&:hover': {
      backgroundColor: appColors.primaryHover,
    },
  },
  disabledLabel: {
    color: appColors.light.level1,
  },
  loadingLabel: {
    display: 'block',
    color: appColors.success,
  },
}));

export default function PrimaryButton(props) {
  const classes = useStyles();
  const { disabled } = props;

  if (props.progress) {
    return (
      <Button
        variant="contained"
        size="large"
        classes={{ label: classes.loadingLabel }}
        className={clsx(props.noMargin ? '' : classes.button, classes.primary)}
        {...props}
      >
        <LinearDeterminate
          progress={props.progress}
          style={{ display: 'block' }}
        />
        {props.children}
      </Button>
    );
  } else {
    return (
      <Button
        variant="contained"
        classes={{ label: disabled ? classes.disabledLabel : 'default' }}
        className={clsx(props.noMargin ? '' : classes.button, classes.primary)}
        {...props}
      >
        {props.children}
      </Button>
    );
  }
}
