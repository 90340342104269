import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DollarSign from '../../svg/dollarSign';
import { BASE_S3 } from 'config/consts';
import React from 'react';
import { connect } from 'react-redux';

function ActivitiesCard(props) {
  const { image, body_name, body_summary } = props.formValues;
  const { haveImage, icon, callToAction } = props;

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div
        className="card"
        style={{ width: 500, height: haveImage ? 400 : 150, cursor: 'pointer' }}
      >
        {haveImage ? (
          <div
            style={{
              position: 'relative',
              background: `url( ${
                image
                  ? image.base64
                    ? image.base64
                    : BASE_S3 + '/' + image
                  : null
              }) center center / cover`,
              height: 200,
            }}
          >
            {icon ? (
              <div
                className="station-font-bold d-flex justify-content-center align-items-center"
                style={{
                  position: 'absolute',
                  bottom: -25,
                  left: '1.25rem',
                  background: 'white',
                  fontSize: 'x-small',
                  borderRadius: '50%',
                  width: 50,
                  height: 50,
                  textAlign: 'center',
                  fill: '#525252',
                }}
              >
                <DollarSign width={24} height={24} />
              </div>
            ) : null}
          </div>
        ) : null}

        <div className="card-body" style={{ marginTop: 10 }}>
          <div className="d-flex flex-column justify-content-between h-100">
            <p
              className="station-font-semibold"
              style={{
                color: '#525252',
                fontSize: '1rem',
                lineHeight: '1.875rem',
              }}
            >
              {body_name}
            </p>
            <p
              className="station-font-semibold"
              style={{
                color: '#626262',
                fontSize: '0.9rem',
                lineHeight: '1.875rem',
              }}
            >
              {body_summary}
            </p>
            <div className="d-flex justify-content-between">
              <p
                className="pb-2 station-font-regular"
                style={{
                  color: '#333',
                  fontSize: '0.875rem',
                }}
              >
                {callToAction}
              </p>
              <FontAwesomeIcon
                style={{
                  fontSize: 16,
                  color: '#333',
                }}
                icon={faChevronRight}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps)(ActivitiesCard);
