import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import React, { useEffect, useState } from 'react';
import YouTube from 'react-youtube';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { ErrorMessage } from './styles';
import { FanApi } from '../api';
import { USER_TOKEN } from 'config/consts';
import ConfirmationCard from '../ConfirmationCard/confirmationCard';
import { toastr } from 'react-redux-toastr';

export default function VideoCard({
  name,
  url,
  id,
  onChange,
  custom,
  checkVideos,
  setTab,
  tab,
}) {
  const [selected, setSelected] = useState(false);
  const [error, setError] = useState('');
  const [visible, setVisible] = useState(false);
  // const accessToken = 'b581876458f6814924e8460acb042025';

  useEffect(() => {
    if (checkVideos.videos) {
      if (checkVideos.videos[0] === id) {
        setSelected(true);
      }
    }
  }, [checkVideos]);

  useEffect(() => {
    setTimeout(() => {
      setError(false);
    }, 2000);
  }, [error]);

  async function deleteVideo(id) {
    try {
      await FanApi.delete(`/videos/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      });
      if (tab === 0) {
        setTab(1);
      } else {
        setTab(0);
      }
      setVisible(false);
    } catch (e) {
      try {
        if (e.response.data.error) {
          toastr.error('Erro', e.response.data.error);
        }
      } catch (error) {
        toastr.error('Erro', 'Erro interno no servidor');
      }
      setVisible(false);
    }
  }

  function handleDelete(id) {
    deleteVideo(id);
  }
  function openDialog() {
    setVisible(true);
  }

  function onClose() {
    setVisible(false);
  }

  function checkLenght(checkVideos) {
    let numChecked = 0;
    if (checkVideos.videos) {
      if (checkVideos.videos[0]) {
        numChecked++;
      }
      if (numChecked === 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  function handleSelect() {
    if (checkLenght(checkVideos)) {
      setSelected(!selected);
      if (!selected) {
        onChange([id]);
      } else if (selected) {
        onChange([]);
      }
    } else {
      if (checkVideos.videos[0] === id) {
        setSelected(false);
        onChange([]);
      } else {
        setError(true);
      }
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 20,
        marginLeft: 25,
      }}
    >
      {custom ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <YouTube
            videoId={url.split('=')[1]}
            opts={{ height: 120, width: 240 }}
          />
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 5,
            height: 120,
            width: 240,
          }}
        >
          <iframe
            src={`https://player.vimeo.com/video/${
              url ? url.split('/')[2] : null
            }`}
            width={'100%'}
            frameborder="0"
            title="vimeo video"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
          />
        </div>
      )}
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          marginRight: 5,
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Checkbox
            checked={selected}
            onChange={() => handleSelect(url)}
            color="primary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />

          <span>{name}</span>
        </div>
        <IconButton
          color="#A3A6A9"
          aria-label="upload picture"
          component="span"
          onClick={openDialog}
        >
          <DeleteOutlinedIcon />
        </IconButton>
      </div>
      <ConfirmationCard
        visible={visible}
        onClose={onClose}
        confirmDelete={() => handleDelete(id)}
      />
      <ErrorMessage error={error}>Limite de 1 vídeo</ErrorMessage>
    </div>
  );
}
