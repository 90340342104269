import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logout } from '../authActions';

class Logout extends Component {
  componentDidMount() {
    this.props.logout();
    this.props.router.push('/');
  }
  render() {
    return null;
  }
}

const mapStateToProps = null;

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ logout }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(Logout);
