import GeneralTabs from 'common/components/navigation/actionsTab/tabs';
import { BASE_S3 } from 'config/consts';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './activity.css';
import ActivityCoupon from './activityCoupon';
import ActivityMemberGetMember from './activityMemberGetMember';
import ActivityPresentialCourse from './activityPresentialCourse';
import ActivityShare from './activityShare';
import ActivitySignUpAndEarn from './activitySignUpAndEarn';
import ActivitySurvey from './activitySurvey';
import ActivityAgreement from './activityAgreement';
import ActivityDonation from './activityDonation';

class Activity extends Component {
  renderSignupAndWin() {
    return null;
  }

  renderStart(props) {
    return (
      <div className="container-fluid pb-5" style={{ backgroundColor: '#fff' }}>
        <div className="row justify-content-center">
          <div className="col-sm-12 station-font-lh-2">
            <div className="row justify-content-center mt-5">
              <div className="col-10">
                <h2
                  className="station-font-medium text-left"
                  style={{ color: '#525252' }}
                >
                  {props.formValues.body_name}
                </h2>
              </div>
            </div>
            {props.formValues.type_action_id === 3 && (
              <ActivityCoupon formValues={props.formValues} />
            )}
            {props.formValues.type_action_id === 4 && (
              <ActivityPresentialCourse formValues={props.formValues} />
            )}
            {props.formValues.type_action_id === 2 && (
              <ActivitySignUpAndEarn formValues={props.formValues} />
            )}
            {props.formValues.type_action_id === 1 ||
              (props.formValues.type_action_id === 19 && (
                <ActivityMemberGetMember formValues={props.formValues} />
              ))}
            {props.formValues.type_action_id === 5 && (
              <ActivityShare formValues={props.formValues} />
            )}
            {props.formValues.type_action_id === 14 && (
              <ActivityCoupon formValues={props.formValues} />
            )}
            {(props.formValues.type_action_id === 7 ||
              props.formValues.type_action_id === 8 ||
              props.formValues.type_action_id === 9 ||
              props.formValues.type_action_id === 10 ||
              props.formValues.type_action_id === 11 ||
              props.formValues.type_action_id === 12 ||
              props.formValues.type_action_id === 6) && (
              <ActivitySurvey formValues={props.formValues} />
            )}
            {props.formValues.type_action_id === 15 && (
              <ActivityAgreement formValues={props.formValues} />
            )}
            {props.formValues.type_action_id === 17 && (
              <ActivityDonation formValues={props.formValues} />
            )}
          </div>
        </div>
      </div>
    );
  }

  renderRules(props) {
    return (
      <div className="container-fluid pb-5" style={{ backgroundColor: '#fff' }}>
        <div className="row justify-content-center">
          <div className="col-sm-12 station-font-lh-2">
            <div className="row justify-content-center">
              <div className="col-10">
                <h2
                  className="station-font-medium text-left mt-5"
                  style={{ color: '#525252' }}
                >
                  Regulamento
                </h2>
                <div
                  dangerouslySetInnerHTML={{ __html: props.formValues.rules }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div
        className="d-flex justify-content-center"
        style={{ backgroundColor: '#FAFAFA', width: '100%', height: '100vh' }}
      >
        <div className="col-12 mt-4">
          <div
            style={{
              backgroundImage: `url( ${
                this.props.formValues
                  ? this.props.formValues.image
                    ? this.props.formValues.image.base64
                      ? this.props.formValues.image.base64
                      : BASE_S3 + '/' + this.props.formValues.image
                    : null
                  : null
              })`,
              maxWidth: '100%',
              height: 300,
              width: 'auto',
              borderRadius: 0,
              overflow: 'hidden',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              position: 'relative',
            }}
            className="col-10"
          ></div>
          <GeneralTabs
            start={this.renderStart(this.props)}
            rules={this.renderRules(this.props)}
            hiddenTabs={
              this.props.formValues.type_action_id === 16 ||
              !this.props.formValues.has_rules
            }
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Activity);
