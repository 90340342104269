import { faSquare, faCircle } from '@fortawesome/free-regular-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@material-ui/core';
import SecondaryButton from 'common/components/buttons/secondaryButton';
import {
  InputLabelOutlined,
  SelectLabel,
  InputSwitch,
} from 'common/components/fields/fields';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import { FORM_RULES } from 'helpers/validations';
import _ from 'lodash';
import React from 'react';
import { ExpansionList, ExpansionPanel } from 'react-md';
import { Field, FieldArray } from 'redux-form';
import PrimaryButton from '../../../../../../../common/components/buttons/primaryButton';

const renderOptions = props => (
  <ul style={{ listStyleType: 'none' }}>
    <li>
      <Box>
        <PrimaryButton onClick={() => props.fields.push()}>
          Adicionar opção
        </PrimaryButton>
      </Box>
    </li>
    {props.fields.map((option, index) => (
      <li key={index}>
        <GeneralGrid container>
          <GeneralGrid item xs={12}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {props.questionType > 3 && (
                <FontAwesomeIcon
                  style={{ margin: 'auto 8', fontSize: 16 }}
                  icon={props.questionType === 4 ? faCircle : faSquare}
                />
              )}
              <Field
                component={InputLabelOutlined}
                label={`Opção ${index + 1}`}
                name={`${option}.label`}
                style={{ width: '100%', flex: 1 }}
                validate={[FORM_RULES.required]}
                required
                margin="normal"
              />

              <FontAwesomeIcon
                style={{ cursor: 'pointer', margin: 'auto 8', fontSize: 16 }}
                icon={faTrash}
                onClick={() => props.fields.remove(index)}
              />
            </div>
          </GeneralGrid>
        </GeneralGrid>
      </li>
    ))}
  </ul>
);

const renderQuestions = props => (
  <ul style={{ listStyleType: 'none' }}>
    <li>
      <Box>
        <PrimaryButton onClick={() => props.fields.push()}>
          Adicionar pergunta
        </PrimaryButton>
      </Box>
    </li>
    {props.fields.map((question, index) => (
      <li key={index}>
        <ExpansionPanel
          key={index}
          label={`Pergunta ${index + 1}`}
          footer={
            <Box ml={2}>
              <SecondaryButton
                onClick={() => props.fields.remove(index)}
                color="primary"
              >
                Excluir Pergunta
              </SecondaryButton>
            </Box>
          }
          style={{
            marginBottom: 10,
            width: '100%',
            borderRadius: 4,
            boxShadow: 'none',
            backgroundColor: '#ffffff',
          }}
        >
          <GeneralGrid container spacing={3}>
            <GeneralGrid item xs={12} sm={12}>
              <Field
                component={InputLabelOutlined}
                label={`Pergunta ${index + 1}`}
                name={`${question}.question`}
                validate={[FORM_RULES.required]}
                required
                margin="normal"
              />
            </GeneralGrid>
            <GeneralGrid item xs={12} sm={12}>
              <Field
                component={SelectLabel}
                type="text"
                label={`Tipo da Pergunta ${index + 1}`}
                name={`${question}.type`}
                margin="normal"
                options={[
                  {
                    value: 4,
                    label: 'Múltipla escolha',
                  },
                  {
                    value: 5,
                    label: 'Caixa de seleção',
                  },
                  {
                    value: 1,
                    label: 'Lista suspensa - uma opção',
                  },
                  {
                    value: 2,
                    label: 'Lista suspensa - várias opções',
                  },
                  {
                    value: 3,
                    label: 'Questão aberta',
                  },
                ]}
                validate={[FORM_RULES.required]}
              />
              {props.formValues['questions'][index] &&
                (parseInt(props.formValues['questions'][index].type) === 4 ||
                  parseInt(props.formValues['questions'][index].type) ===
                    5) && (
                  <Box style={{ padding: 10 }}>
                    <Field
                      component={InputSwitch}
                      label={'Adicionar opção "Outro"'}
                      name={`${question}.otherOption`}
                    />
                  </Box>
                )}
              {props.formValues['questions'][index] &&
              parseInt(props.formValues['questions'][index].type) !== 3 ? (
                <FieldArray
                  name={`${question}.options`}
                  component={renderOptions}
                  questionType={props.formValues['questions'][index].type}
                />
              ) : null}
              {props.formValues['questions'][index] &&
                (parseInt(props.formValues['questions'][index].type) === 4 ||
                  parseInt(props.formValues['questions'][index].type) === 5) &&
                props.formValues['questions'][index].otherOption && (
                  <Box style={{ marginTop: 16 }}>
                    <FontAwesomeIcon
                      style={{ margin: 'auto 8', fontSize: 16 }}
                      icon={
                        parseInt(props.formValues['questions'][index].type) ===
                        4
                          ? faCircle
                          : faSquare
                      }
                    />
                    <span>Outro: ________</span>
                  </Box>
                )}
            </GeneralGrid>
          </GeneralGrid>
        </ExpansionPanel>
      </li>
    ))}
  </ul>
);

export default function TemplateResearch(props) {
  return (
    <>
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12} sm={12}>
          <h4 style={{ fontWeight: 'bold' }}>Configure suas perguntas:</h4>
        </GeneralGrid>
      </GeneralGrid>
      {_.isEmpty(props.formValues.questions) && (
        <p style={{ textAlign: 'center' }}>
          Ainda não há perguntas. Adicione algumas!
        </p>
      )}

      <ExpansionList>
        {
          <FieldArray
            name="questions"
            component={renderQuestions}
            formValues={props.formValues}
          />
        }
      </ExpansionList>
    </>
  );
}
