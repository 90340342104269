import React from 'react';
import { Route, IndexRoute } from 'react-router';
import TermsOfUse from './termsOfUse';
import TermsOfUseForm from './termsOfUseForm';

export default (
  <Route path="termos-de-uso">
    <IndexRoute component={props => <TermsOfUse {...props} />} />
    <Route path="novo" component={props => <TermsOfUseForm {...props} />} />
    <Route
      path=":id/:action"
      component={props => <TermsOfUseForm {...props} />}
    />
  </Route>
);
