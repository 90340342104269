import { FanApi } from './api';
import { USER_TOKEN } from 'config/consts';

export function sendPostVideo(selectedFile, setSelectedFile) {
  return dispatch => {
    const data = new FormData();
    data.append('file', selectedFile);
    FanApi.post('/upload-video', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
      },
      onUploadProgress: progressEvent =>
        dispatch([
          {
            type: 'UPLOAD_VIDEO_PROGRESS',
            payload: `${parseInt(
              (progressEvent.loaded / progressEvent.total) * 100
            )} %`,
          },
        ]),
    }).then(response => {
      dispatch([
        {
          type: 'UPLOAD_VIDEO_PROGRESS',
          payload: '',
        },
        dispatchLoading(false),
        setSelectedFile(null),
      ]);
    });
  };
}

export const dispatchLoading = bool => {
  return dispatch => {
    dispatch({
      type: 'LIST_LOAD',
      payload: bool,
    });
  };
};
