import React from 'react';
import { Box, Typography } from '@material-ui/core';

export default function TemplateVideoPlaylist(props) {
  return (
    <Box
      style={{ height: 300 }}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Typography color="primary" style={{ fontSize: 20 }}>
        Essa mecânica não possui interação configurável. O funcionamento
        consiste em contar a visualização como participação. Ou seja, não é
        possível nessa atividade dar premiação por cada visualização, ou limitar
        participação por usuário. Se tiver premiação, ela será feita apenas na
        primeira visualização.
      </Typography>
    </Box>
  );
}
