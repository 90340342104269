/* eslint-disable react-hooks/exhaustive-deps */
import { InputLabelOutlined } from 'common/components/fields/fields';
import { currencyMask } from 'helpers/masks';
import { FORM_RULES } from 'helpers/validations';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field } from 'redux-form';
import ActivityButton from '../buttons/activityButton';

function ActivityDonation(props) {
  return (
    <>
      <div className="row justify-content-center mt-2 mb-2">
        <div className="col-10 station-font-lh-2">
          <div
            dangerouslySetInnerHTML={{
              __html: props.formValues.description,
            }}
          ></div>
        </div>
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div className="d-flex justify-content-center w-60">
          <Field
            component={InputLabelOutlined}
            type="tel"
            label={'Quantidade em BRL'}
            name="points"
            required
            onChange={() => console.log('ok')}
            validate={[FORM_RULES.required, FORM_RULES.maxValueDonation]}
            margin="normal"
            {...currencyMask}
          />
        </div>
        <div className="d-flex justify-content-center w-50">
          <ActivityButton color={'#2D95EF'} label={'Doar'} />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = state => {
  return {};
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityDonation);
