import React from 'react';
import { IndexRoute, Route } from 'react-router';
import Templates from './templates';
import TemplatesForm from './templatesForm';

export default (
  <Route path="modelos">
    <IndexRoute component={props => <Templates {...props} />} />
    <Route
      path=":id/:action"
      component={props => <TemplatesForm {...props} />}
    />
  </Route>
);
