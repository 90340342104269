import MaterialGrid from '@material-ui/core/Grid';
import { InputLabelOutlined } from 'common/components/fields/fields';
import { FORM_RULES } from 'helpers/validations';
import React from 'react';
import { Field } from 'redux-form';

export default function TemplatePresentialCourse(props) {
  return (
    <MaterialGrid container spacing={3}>
      <MaterialGrid item xs={12}>
        <Field
          component={InputLabelOutlined}
          type="text"
          label={'URL (web)'}
          name="url"
          cols="12 12 4 4"
          required
          validate={[FORM_RULES.required]}
          margin="normal"
        />
      </MaterialGrid>
      <MaterialGrid item xs={12}>
        <Field
          component={InputLabelOutlined}
          type="text"
          label={'Caso haja rota dentro do app, colocar aqui'}
          name="app_uri"
          cols="12 12 4 4"
          required
          margin="normal"
        />
      </MaterialGrid>
    </MaterialGrid>
  );
}
