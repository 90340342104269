import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import 'cropperjs/dist/cropper.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/nova-light/theme.css';
import React from 'react';
import ReactDOM from 'react-dom';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Provider } from 'react-redux';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'react-typist/dist/Typist.css';
import { applyMiddleware, createStore } from 'redux';
import multi from 'redux-multi';
import promise from 'redux-promise';
import thunk from 'redux-thunk';
import reducers from './main/reducers';
import Routes from './main/routes';
import ReduxToastr from 'react-redux-toastr';

const devTools =
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

const store = applyMiddleware(multi, thunk, promise)(createStore)(
  reducers,
  devTools
);

export const theme = createMuiTheme({
  palette: {
    primary: { main: '#2D95EF' },
    secondary: { main: '#ef2db3' },
    error: { main: '#FF5A52' },
    success: { main: '#52C22C' },
  },
  spacing: 8,
});

ReactDOM.render(
  <Provider store={store}>
    <>
      <ThemeProvider theme={theme}>
        {/* <CssBaseline /> */}
        <Routes />
      </ThemeProvider>
      <ReduxToastr
        timeOut={5000}
        newestOnTop={true}
        preventDuplicates
        position="top-right"
        getState={(state) => state.toastr} // This is the default
        transitionIn="bounceIn"
        transitionOut="bounceOut"
        progressBar={false}
        closeOnToastrClick
      />
    </>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.

// Learn more about service workers: http://bit.ly/CRA-PWA
//registerServiceWorker();
