import { Box, Icon, Tooltip, Typography } from '@material-ui/core';
import MaterialGrid from '@material-ui/core/Grid';
import { InputSwitchGrey } from 'common/components/fields/fields';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { Field } from 'redux-form';

export default function TemplateCoupons(props) {
  useEffect(() => {
    if (props.formValues) {
      if (!props.formValues.prices) {
        props.formValues['price'] = 0;
        props.formValues['newPrice'] = 0;
        props.formValues['discountPrice'] = 0;
      }
      if (
        !_.isUndefined(props.formValues.price) &&
        !_.isUndefined(props.formValues.discountPrice)
      ) {
        props.formValues['newPrice'] =
          props.formValues.price - props.formValues.discountPrice;
      } else if (
        !_.isUndefined(props.formValues.price) &&
        _.isUndefined(props.formValues.discountPrice)
      ) {
        props.formValues['newPrice'] = props.formValues.price;
      }
    }
  });
  return (
    <MaterialGrid container spacing={3} style={{ marginBottom: 20 }}>
      <MaterialGrid
        item
        xs={12}
        style={{
          backgroundColor: '#f7f7f7',
          padding: 16,
          marginTop: 16,
        }}
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography color="primary" style={{ fontSize: 20 }}>
            Os produtos serão cadastrados pelo gestor ou parceiro
          </Typography>
        </Box>
      </MaterialGrid>
      <MaterialGrid
        item
        xs={12}
        style={{
          backgroundColor: '#f7f7f7',
          padding: 16,
          marginTop: 16,
        }}
      >
        <Field
          component={InputSwitchGrey}
          label={'Essa atividade é um evento?'}
          boxStyle={{ backgroundColor: '#f7f7f7' }}
          firstState="Ativado"
          secondState="Ative"
          name="is_event"
          // required
          margin="normal"
        />
        <Tooltip
          style={{ marginLeft: 10 }}
          title="Se ativada, o gestor/parceiro da atividade terá que marcar uma data de ínício e fim do evento"
          placement="top-start"
        >
          <Icon color="default">help</Icon>
        </Tooltip>
      </MaterialGrid>
      <MaterialGrid
        item
        xs={12}
        style={{
          backgroundColor: '#f7f7f7',
          padding: 16,
          marginTop: 16,
        }}
      >
        <Field
          component={InputSwitchGrey}
          label={'Os produtos vendidos serão digitais?'}
          boxStyle={{ backgroundColor: '#f7f7f7' }}
          firstState="Ativado"
          secondState="Ative"
          name="digital_product"
          // required
          margin="normal"
        />
        <Tooltip
          style={{ marginLeft: 10 }}
          title="Se ativada, o gestor/parceiro não precisará fazer o cadastro da logística da venda."
          placement="top-start"
        >
          <Icon color="default">help</Icon>
        </Tooltip>
      </MaterialGrid>
    </MaterialGrid>
  );
}
