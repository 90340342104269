import React from 'react';
import { Box, Typography } from '@material-ui/core';

export default function TemplateProductDelivery(props) {
  return (
    <Box
      style={{ height: 300 }}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Typography color="primary" style={{ fontSize: 20 }}>
        Os produtos serão cadastrados pelo gestor ou parceiro
      </Typography>
    </Box>
  );
}
