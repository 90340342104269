import _ from 'lodash';

export const FORM_RULES = {
  required: value => {
    return !_.isEmpty(value) || _.isNumber(value) || _.isBoolean(value)
      ? undefined
      : 'Esse campo é obrigatório';
  },
  max: max => value =>
    value && value.length > max
      ? `Esse campo deve possuir no máximo ${max} caracteres`
      : undefined,
  min: min => value =>
    value && value.length < min
      ? `Esse campo deve possuir no minimo ${min} caracteres`
      : undefined,
  questions: values => {
    if (
      values &&
      values.length > 0 &&
      values[0] &&
      values[0].options &&
      values[0].options.length > 0 &&
      values[0].options[0]
    ) {
      let errorArray;
      for (let i = 0; i < values.length; i += 1) {
        errorArray = [];
        for (let j = 0; j < values[i].options.length; j += 1) {
          if (values[i].options[j]) {
            if (values[i].options[j].correct) {
              errorArray.push(values[i].options[j]);
            }
          }
        }
        if (errorArray.length > 1) {
          return 'Escolha apenas uma resposta correta.';
        } else {
          return undefined;
        }
      }
    }
    return undefined;
  },
  number: value =>
    value && isNaN(Number(value)) ? 'Este campo só aceita números' : undefined,
  minValue: min => value =>
    value && value < min ? `O valor deve ser maior que ${min}` : undefined,
  maxValue: max => value =>
    value && value < max ? `O valor deve ser menor que ${max}` : undefined,
  password: value =>
    value && !/((?=.*\d)(?=.*[a-z])(?=.*[A-Z]){8,20})/i.test(value)
      ? 'Senha precisar ter: uma letra maiúscula, uma letra minúscula, um número e tamanho entre 8 - 20.'
      : undefined,
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? 'Email inválido'
      : undefined,
  tooOld: value => value =>
    value && value > 65 ? 'You might be too old for this' : undefined,
  lowerCase: value =>
    value && /[^a-z]/i.test(value)
      ? 'Apenas letras minúsculas sem acento'
      : undefined,
  requiredCheckbox: value => {
    return value ? undefined : 'Esse campo é obrigatório';
  },
};

export const MESSAGES = {
  required: 'Este campo é obrigatório',
  whitespace: 'Este campo não pode ser vazio',
  mail: 'Digite um email válido',
  number: 'Este campo deve conter apenas números',
  url: 'Digite uma url válida',
};

export const FORMRULES = {
  required: { required: true, message: MESSAGES.required },
  whitespace: { whitespace: true, message: MESSAGES.whitespace },
  number: { pattern: /\d+/, message: MESSAGES.number },
  mail: {
    pattern: /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
    message: MESSAGES.mail,
  },
  url: {
    pattern: /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/,
    message: MESSAGES.url,
  },
};
