import { USER_KEY, USER_TOKEN } from 'config/consts';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';
import NavBarDashboard from '../../../common/components/navigation/navBarDashboard';
import CustomizedSnackbar from '../../../common/components/snackbar/Snackbar';
import { signupCompanyOnly } from '../../../pages/auth/authActions.js';
import { getListById as getSectionsListById } from '../pages/sections/sectionsActions';
import './admin.css';

class Admin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: !_.isEmpty(props.auth.user.establishments) ? false : true,
      step: 0,
    };
  }

  renderNav = () => {
    return (
      <span className="font-dosis">
        <span style={{ fontWeight: 'bold', color: '#2b2d6b' }}>
          {this.props.auth.user.name}
        </span>
      </span>
    );
  };

  onHide = () => {
    this.setState({ visible: false });
  };

  handleRemove = () => {
    localStorage.removeItem(USER_KEY);
    localStorage.removeItem(USER_TOKEN);
  };

  onSubmit = values => {
    const { router, auth } = this.props;
    values['type'] = 1;
    values[''] = this.props.auth.user.id;
    this.props.signupCompanyOnly(values, router, auth);
  };

  render() {
    return (
      <div>
        <NavBarDashboard
          navBarItems={this.props.navBarItems}
          router={this.props.router}
          auth={this.props.auth}
          establishments={this.props.establishments}
          website={this.props.website}
          principal={this.props.principal}
        >
          {this.props.children}
          <CustomizedSnackbar />
        </NavBarDashboard>
      </div>
    );
  }
}

Admin = reduxForm({ form: 'adminForm' })(Admin);

const mapStateToProps = state => {
  return {
    auth: state.auth,
    points: state.points,
    establishments: state.establishments,
    sections: state.sections,
    principal: state.principal,
    website: state.website,
    general: state.general,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { signupCompanyOnly, getSectionsListById },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
