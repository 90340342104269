import { Box, Dialog, Slide, Backdrop } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import ImageIcon from '@material-ui/icons/Image';
import { BASE_S3 } from 'config/consts';
import { uploadImageToS3 } from 'modules/admin/pages/templates/templatesActions';
import React, { useEffect, useState } from 'react';
import { SketchPicker } from 'react-color';
import Dropzone from 'react-dropzone';
import { ColorPicker } from '../Questions/styles';
import { IconBackground, Option, TextColorPicker, TextImage } from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));
export default function Questions({ input: { onBlur, onChange, value } }) {
  const classes = useStyles();
  const [image, setImage] = useState(undefined);
  const [background, setBackground] = useState('#2D95EF');
  const [textColor, setTextColor] = useState('#fff');
  const [open, setOpen] = useState(false);
  const [openText, setOpenText] = useState(false);
  const [counter, setCounter] = useState(0);
  const [selectedDate, setSelectedDate] = useState(value.text || null);
  const [loadingImage, setLoadingImage] = useState(false);

  function handleDateChange(e) {
    setSelectedDate(e.target.value);
  }

  useEffect(() => {
    setSelectedDate(value.text);
  }, [value.text]);

  function onClose() {
    setOpen(false);
    setOpenText(false);
  }

  function handleOpen() {
    setOpen(true);
    setCounter(counter + 1);
  }

  function handleOpenText() {
    setOpenText(true);
    setCounter(counter + 1);
  }

  function handleChangeComplete(color) {
    setBackground(color.hex);
  }

  function handleChangeCompleteText(color) {
    setTextColor(color.hex);
  }
  const dropzoneProps = {
    accept: 'image/jpeg, image/png',
    multiple: false,
    onDropAccepted: onDrop,
    maxSize: 1200000,
  };

  useEffect(() => {
    if (value.base64) {
      if (value.base64.indexOf('base64') > -1) {
        setImage(value.base64);
        setTextColor(value.textColor);
      } else {
        setImage(`${BASE_S3}/${value.base64}`);
        setTextColor(value.textColor);
      }
    } else if (value.color) {
      setBackground(value.color);
      setTextColor(value.textColor);
    }
  }, []);

  useEffect(() => {
    if (counter > 0) {
      setImage(undefined);
      onChange({
        ...value,
        base64: undefined,
        color: background,
      });
    }
  }, [background]);

  useEffect(() => {
    if (counter > 0) {
      onChange({
        ...value,
        textColor,
      });
    }
  }, [textColor]);

  function onDrop(imageFiles) {
    setLoadingImage(true);
    let reader = new FileReader();
    reader.readAsDataURL(imageFiles[0]);
    reader.onload = async function() {
      const url = await uploadImageToS3(reader.result);
      onBlur({ base64: url });
      onChange({
        ...value,
        color: undefined,
        base64: url,
      });
      setImage(url);

      setLoadingImage(false);
    };
    reader.onerror = function(error) {};
  }
  useEffect(() => {}, [image]);

  return (
    <>
      <Backdrop className={classes.backdrop} open={loadingImage}>
        <CircularProgress color="inherit" />{' '}
        <span style={{ marginLeft: 10 }}>Fazendo Upload da Imagem</span>
      </Backdrop>
      <Box display="flex" flexDirection="column" style={{ width: '100%' }}>
        <Box>
          <Option background={background} textColor={textColor} image={image}>
            {!image ? (
              <textarea
                spellcheck="false"
                rows="2"
                cols="50"
                maxlength="70"
                name="text"
                value={selectedDate}
                onChange={e => handleDateChange(e)}
                onBlur={e => onChange({ ...value, text: e.target.value })}
              />
            ) : null}

            <TextColorPicker onClick={handleOpenText} />
            <ColorPicker onClick={handleOpen} />

            <Dropzone {...dropzoneProps}>
              {({ getRootProps, getInputProps }) => (
                <IconBackground {...getRootProps()}>
                  <label htmlFor="image">
                    <ImageIcon style={{ color: 'white' }} />
                    <input {...getInputProps()} />
                  </label>
                </IconBackground>
              )}
            </Dropzone>
          </Option>
        </Box>
        <Box>
          {image ? (
            <TextImage
              textColor={textColor}
              value={selectedDate}
              onChange={e => handleDateChange(e)}
              onBlur={e => onChange({ ...value, text: e.target.value })}
            />
          ) : null}
        </Box>
      </Box>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <SketchPicker
          color={background}
          onChangeComplete={handleChangeComplete}
        />
      </Dialog>
      <Dialog
        open={openText}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <SketchPicker
          color={textColor}
          onChangeComplete={handleChangeCompleteText}
        />
      </Dialog>
    </>
  );
}
