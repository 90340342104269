import CallToActionCard from '../cards/moduleCard/callToActionCard';
import React from 'react';

function ActivitySurvey(props) {
  return (
    <>
      <div className="row justify-content-center mt-2 mb-2">
        <div className="col-10 station-font-lh-2">
          <div
            dangerouslySetInnerHTML={{
              __html: props.formValues.body_description,
            }}
          ></div>
        </div>
      </div>

      <div className="col-12">
        <div className="row justify-content-center">
          <CallToActionCard formValues={props.formValues} />
        </div>
      </div>
    </>
  );
}

export default ActivitySurvey;
