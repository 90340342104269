import MaterialTable from 'material-table';
import React from 'react';

export default function Table6(props) {
  const { title, data, columns, add, remove, edit } = props;
  const [newColumns2] = React.useState(setInitialNewColumns(props));
  const [columnsWithStyle] = React.useState(setInitialColumnsStyle(props));

  function setInitialNewColumns(props) {
    const index = props.columns.findIndex(item => item.field === 'base64');
    const itemFound = props.columns.find(item => item.field === 'base64');
    if (index >= 0) {
      let columnsWithImg = [...columns];

      columnsWithImg[index] = {
        ...itemFound,
        render: rowData => (
          <img
            alt=""
            src={rowData.base64}
            style={{ width: 50, borderRadius: '50%' }}
          />
        ),
      };
      return columnsWithImg;
    } else {
      return props.columns;
    }
  }

  function setInitialColumnsStyle(props) {
    return newColumns2.map(item => ({
      ...item,
      headerStyle: {
        fontWeight: 500,
        fontSize: 14,
        color: 'white',
        backgroundColor: '#ababab',
      },
    }));
  }

  return (
    <MaterialTable
      components={{
        Toolbar: props => (
          <div style={{ backgroundColor: '#ababab', color: 'white' }}></div>
        ),
      }}
      style={{ boxShadow: 'none', border: 'none', borderRadius: 0 }}
      //Necessário para realizar tradução do placeholder. Retirar deleteTooltip bugará.
      localization={{
        grouping: {
          groupedBy: 'Agrupar por:',
          placeholder:
            'Clique e arraste até aqui os títulos das colunas para agrupar',
        },
        toolbar: {
          searchPlaceholder: 'Procurar',
        },
        body: {
          editRow: {
            saveTooltip: 'Salvar',
            cancelTooltip: 'Cancelar',
            deleteText:
              props.deleteText || 'Tem certeza que deseja apagar esta linha?',
          },
          addTooltip: 'Adicionar',
          deleteTooltip: 'Deletar',
          editTooltip: 'Editar',
          emptyDataSourceMessage: 'Não há dados para visualização',
        },
        header: { actions: 'Atividades' },
        pagination: {
          labelRowsPerPage: 'Linhas por página',
          labelRowsSelect: 'linhas',
        },
      }}
      title={title}
      columns={[...columnsWithStyle]}
      onRowClick={props.onRowClick}
      data={data}
      options={{
        rowStyle: {
          height: '64px',
        },
        filtering: true,
        actionsColumnIndex: -1,
        searchFieldStyle: {
          color: 'white',
        },
      }}
      editable={{
        ...(add
          ? {
              onRowAdd: newData =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    {
                      const data = this.state.data;
                      data.push(newData);
                      this.setState({ data }, () => resolve());
                    }
                    resolve();
                  }, 1000);
                }),
            }
          : {}),
        ...(edit
          ? {
              onRowUpdate: (newData, oldData) =>
                //console.log(newData, oldData, this.state.data.indexOf(oldData)),
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    {
                      const data = this.state.data;
                      const index = data.indexOf(oldData);
                      data[index] = newData;
                      this.setState({ data }, () => resolve());
                    }
                    resolve();
                  }, 1000);
                }),
            }
          : {}),
        ...(remove
          ? {
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  props.handleDelete(oldData.id, resolve, reject);
                }),
            }
          : {}),
      }}
    />
  );
}
