import { Box, Container, Typography } from '@material-ui/core';
import logo from 'assets/img/fan_logo.svg';
import PrimaryButton from 'common/components/buttons/primaryButton';
import { InputLabelOutlined } from 'common/components/fields/fields';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import CustomizedSnackbar from 'common/components/snackbar/Snackbar';
import { FORM_RULES } from 'helpers/validations';
import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { bindActionCreators } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { appColors } from 'styles/colors';
import { login, validateToken } from '../authActions';
import { handleWhiteSpaces } from '../../../helpers/dataTransformers';

class Login extends Component {
  componentDidMount() {
    const { user } = this.props.auth;
    if (user) {
      this.props.validateToken(user.token);
    }
    this.isAuthenticated();
  }

  isAuthenticated() {
    const { user, validToken } = this.props.auth;

    if (user && validToken) {
      this.props.router.push('/');
    }
    ///////console.log(user, validToken);
  }

  onSubmit(values) {
    const { login, router } = this.props;
    login(values, router);
  }

  render() {
    return (
      <>
        <Container maxWidth="xl" style={{ paddingBottom: '4rem' }}>
          {/* <div className="row">
                    <div className="col-12">
                        <NavBarSite />
                    </div>
                </div> */}
          <Box mt={4}>
            <GeneralGrid
              container
              spacing={8}
              justify="center"
              style={{ background: 'white' }}
            >
              <GeneralGrid item xs={12}>
                <Box display="flex" justifyContent="center" mt={5}>
                  <Link to="/admin">
                    <div
                      style={{
                        width: 80,
                        height: 80,
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        padding: '0 8px',
                        justifyContent: 'center',
                      }}
                    >
                      <img
                        src={logo}
                        alt="Rilato Logo"
                        style={{ width: '100%', height: 'auto' }}
                      />
                    </div>
                  </Link>
                </Box>

                <Box display="flex" justifyContent="center" mt={5}>
                  <Typography
                    style={{
                      fontFamily: 'Roboto',
                      fontWeight: 400,
                      fontSize: 32,
                    }}
                  >
                    Digite seus dados para entrar.
                  </Typography>
                </Box>
                {/*  <Box display="flex" justifyContent="center" mt={2}>
                                    <Typography variant="subtitle1" style={{ color: appColors.dark.level3 }}>
                                        Ainda não possui uma conta?{" "}
                                        <a href="#/cadastrar" style={{ color: "#2D95EF", textDecoration: "underline" }}>
                                            Inscreva-se.
                                    </a>
                                    </Typography>
                                </Box> */}
              </GeneralGrid>
            </GeneralGrid>
            <GeneralGrid container spacing={3} justify="center">
              <GeneralGrid item xs={12}>
                <Box mt={5}>
                  <form
                    onSubmit={this.props.handleSubmit(v => this.onSubmit(v))}
                    noValidate
                  >
                    <GeneralGrid container spacing={3} justify="center">
                      <GeneralGrid item xs={8} md={4} lg={3}>
                        <Box mt={3}>
                          <Field
                            component={InputLabelOutlined}
                            label="E-mail"
                            name="email"
                            inputlabelprops={{
                              shrink: true,
                            }}
                            validate={[FORM_RULES.required, FORM_RULES.email]}
                            helperText="Digite seu e-mail preferencial"
                            normalize={handleWhiteSpaces}
                          />
                        </Box>
                      </GeneralGrid>
                    </GeneralGrid>
                    <GeneralGrid container spacing={3} justify="center">
                      <GeneralGrid item xs={8} md={4} lg={3}>
                        <Box mt={3}>
                          <Field
                            component={InputLabelOutlined}
                            type="password"
                            label="Senha"
                            name="password"
                            inputlabelprops={{
                              shrink: true,
                            }}
                            validate={[FORM_RULES.required]}
                            helperText="Digite sua senha"
                          />
                        </Box>
                      </GeneralGrid>
                    </GeneralGrid>
                    <GeneralGrid container spacing={3} justify="center">
                      <GeneralGrid item xs={8} md={4} lg={3}>
                        <Box display="flex" justifyContent="center" mt={3}>
                          <PrimaryButton
                            fullWidth={true}
                            style={{ padding: '12px 0' }}
                            router={this.props.router}
                            type="submit"
                          >
                            Entrar
                          </PrimaryButton>
                        </Box>
                      </GeneralGrid>
                    </GeneralGrid>
                    <Box display="flex" justifyContent="center" mt={6}>
                      <Typography
                        variant="body2"
                        style={{ color: appColors.dark.level3 }}
                      >
                        Esqueceu sua senha?{' '}
                      </Typography>
                    </Box>
                    <Box display="flex" justifyContent="center" mt={3}>
                      <Typography variant="body2">
                        <a
                          href="#/esqueci-minha-senha"
                          style={{
                            color: appColors.dark.level3,
                            textDecoration: 'underline',
                          }}
                        >
                          {' '}
                          Recupere-a aqui.
                        </a>
                      </Typography>
                    </Box>
                  </form>
                </Box>
              </GeneralGrid>
            </GeneralGrid>
          </Box>
          <CustomizedSnackbar />
        </Container>
        <div
          style={{
            position: isMobile ? 'relative' : 'absolute',
            bottom: 0,
            background: 'white',
            textAlign: 'center',
            width: '100%',
            padding: '1rem 0',
          }}
        >
          <Typography variant="body1" style={{ color: appColors.dark.level4 }}>
            2019 Todos os direitos reservados.{' '}
            <a
              href="#/termos"
              style={{ color: '#2D95EF', textDecoration: 'underline' }}
            >
              Preferências de cookies
            </a>
            ,{' '}
            <a
              href="#/termos"
              style={{ color: '#2D95EF', textDecoration: 'underline' }}
            >
              privacidade
            </a>{' '}
            e{' '}
            <a
              href="#/termos"
              style={{ color: '#2D95EF', textDecoration: 'underline' }}
            >
              termos de uso.
            </a>
          </Typography>
        </div>
      </>
    );
  }
}

Login = reduxForm({ form: 'loginForm' })(Login);

const mapStateToProps = state => {
  return {
    auth: state.auth,
    general: state.general,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ login, validateToken }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
