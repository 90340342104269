import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@material-ui/core';
import PrimaryButton from 'common/components/buttons/primaryButton';
import SecondaryButton from 'common/components/buttons/secondaryButton';
import { SelectLabel } from 'common/components/fields/fields';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import Answers from 'common/components/quiz/Answers';
import Questions from 'common/components/quiz/Questions';
import { FORM_RULES } from 'helpers/validations';
import _ from 'lodash';
import React from 'react';
import { ExpansionList, ExpansionPanel } from 'react-md';
import { Field, FieldArray } from 'redux-form';
import ResultsLotties from '../../../../../../../common/components/ResultsLotties';

const renderResults = props => {
  return (
    <ul style={{ listStyleType: 'none' }}>
      <li>
        <Box>
          <PrimaryButton noMargin onClick={() => props.fields.push()}>
            Adicionar resultado
          </PrimaryButton>
        </Box>
      </li>
      {props.fields.map((result, index) => (
        <li key={index}>
          <ExpansionPanel
            key={index}
            label={`Resultado ${index + 1}`}
            footer={
              <Box px={3}>
                <SecondaryButton
                  noMargin
                  onClick={() => props.fields.remove(index)}
                  color="primary"
                >
                  Excluir resultado
                </SecondaryButton>
              </Box>
            }
            style={{
              marginBottom: 10,
              width: '100%',
              borderRadius: 4,
              boxShadow: 'none',
              backgroundColor: '#ffffff',
            }}
          >
            <ResultsLotties
              result={result}
              index={index}
              lottiesList={props.lottiesList}
              formValues={props.formValues}
            />
          </ExpansionPanel>
        </li>
      ))}
    </ul>
  );
};

const renderOptions = props => (
  <ul style={{ listStyleType: 'none' }}>
    <li>
      <Box>
        <PrimaryButton onClick={() => props.fields.push()}>
          Adicionar opção
        </PrimaryButton>
      </Box>
    </li>
    {props.fields.map((option, index) => (
      <li key={index}>
        <GeneralGrid container style={{ marginBottom: 8 }}>
          <GeneralGrid item xs={6}>
            <Box display="flex" alignContent="center">
              <Field
                component={Answers}
                label={`Opção ${index + 1}`}
                name={`${option}.label`}
                validate={[FORM_RULES.required]}
                required
                margin="normal"
              />
              <FontAwesomeIcon
                style={{ cursor: 'pointer', margin: 'auto 8', fontSize: 16 }}
                icon={faTrash}
                onClick={() => props.fields.remove(index)}
              />
            </Box>
          </GeneralGrid>
          <GeneralGrid item xs={6}>
            <Box
              display="flex"
              alignContent="center"
              justifyContent="center"
              height="100%"
            >
              <Field
                component={SelectLabel}
                options={props.personalitiesList}
                label={`Esta é a resposta correta?`}
                name={`${option}.quiz_feedback_index`}
              />
            </Box>
          </GeneralGrid>
        </GeneralGrid>
      </li>
    ))}
  </ul>
);

const renderQuestions = props => {
  /* useEffect(() => {
    if (props.meta.submitFailed && props.meta.error) {
      props.parentProps.showToastr({
        message: props.meta.error,
        variant: 'error',
        open: true,
      });
    }
  }, [props.meta.submitFailed, props.meta.error]);
  console.log(props.parentProps); */

  return (
    <ul style={{ listStyleType: 'none' }}>
      <li>
        <Box>
          <PrimaryButton onClick={() => props.fields.push()}>
            Adicionar pergunta
          </PrimaryButton>
        </Box>
      </li>
      {props.fields.map((question, index) => (
        <li key={index}>
          <Box>
            <SecondaryButton
              onClick={() => props.fields.remove(index)}
              color="primary"
            >
              Excluir Pergunta
            </SecondaryButton>
          </Box>

          <GeneralGrid container spacing={3}>
            <GeneralGrid item xs={12} sm={12}>
              <Field
                component={Questions}
                label={`Pergunta ${index + 1}`}
                name={`${question}.question`}
                validate={[FORM_RULES.required]}
                required
                margin="normal"
              />
              {props.meta.submitFailed && props.meta.error && (
                <Typography color="error" variant="caption">
                  {props.meta.error}
                </Typography>
              )}
            </GeneralGrid>

            <GeneralGrid item xs={12} sm={12}>
              <FieldArray
                name={`${question}.options`}
                component={renderOptions}
                personalitiesList={props.personalitiesList}
              />
            </GeneralGrid>
          </GeneralGrid>
        </li>
      ))}
    </ul>
  );
};

export default function TemplatePersonalityQuiz(props) {
  let lottiesList = props.lotties.list.content.map(item => {
    return {
      label: item.name,
      value: item.id,
      url: item.url,
    };
  });
  lottiesList.unshift({
    label: 'Nenhuma Animação',
    value: 'none',
    url: 'none',
  });

  const personalitiesList = props.formValues.results
    ? props.formValues.results.map((result, index) => {
        return {
          value: index,
          label: `Resultado ${index + 1}`,
        };
      })
    : [];

  return (
    <>
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12} sm={12}>
          <h4 style={{ fontWeight: 'bold' }}>Configure seus resultados:</h4>
        </GeneralGrid>
      </GeneralGrid>
      {_.isEmpty(props.formValues.questions) && (
        <p style={{ textAlign: 'left' }}>
          Ainda não há resultados. Adicione alguns!
        </p>
      )}
      <ExpansionList>
        <FieldArray
          name="results"
          component={renderResults}
          formValues={props.formValues}
          validate={[FORM_RULES.questions]}
          lottiesList={lottiesList}
          lotties={props.lotties}
        />
      </ExpansionList>
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12} sm={12}>
          <h4 style={{ fontWeight: 'bold' }}>Configure suas perguntas:</h4>
        </GeneralGrid>
      </GeneralGrid>
      {_.isEmpty(props.formValues.questions) && (
        <p style={{ textAlign: 'center' }}>
          Ainda não há perguntas. Adicione algumas!
        </p>
      )}

      <FieldArray
        name="questions"
        component={renderQuestions}
        formValues={props.formValues}
        validate={[FORM_RULES.questions]}
        parentProps={props}
        personalitiesList={personalitiesList}
      />
    </>
  );
}
