import moment from 'moment';
import _ from 'lodash';

export const Bool2Number = data => {
  if (data === 1) {
    return true;
  }
  return false;
};

export const ShowBoolean = (data, params) => {
  if (data === 1) {
    return params.hasOwnProperty('true') ? params.true : 'Sim';
  }
  return params.hasOwnProperty('false') ? params.false : 'Não';
};

export const String2Float = data => {
  return parseFloat(data);
};

export const changeDateToFormatWithDot = date => {
  const itemDate = date.split('.')[0];
  return moment(itemDate).format('DD/MM/YYYY HH:mm');
};

export const changeDateToFormat = date => {
  return moment(date).format('DD/MM/YYYY HH:mm');
};

export const String2Currency = num => {
  if (_.isNaN(num)) {
    return 'R$ 0,00';
  }
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(num);
};

export const handleWhiteSpaces = value =>
  value &&
  value
    .split(' ')
    .join('')
    .toLowerCase();
