export const appColors = {
  primary: '#2D95EF',
  primaryHover: '#206caf',
  success: '#52C22C',
  warning: '#E6C029',
  error: '#FF5A52',
  errorHover: '#FFAAAA',
  //Níveis de escuro, começando do mais escuro
  dark: {
    level1: '#000000',
    level2: '#505050',
    level3: '#666666',
    level4: '#ABABAB',
  },
  //Níveis de claro, começando do mais claro
  light: {
    level1: '#FFFFFF',
    level2: '#FAFAFA',
    level3: '#FAFAFA',
    level4: '#E4E4E4',
  },
  //Cores de background
  background: {
    level1: '#EFEFEF',
    level2: '#FAFAFA',
    level3: '#F7F7F7',
  },
  disable: '#E4E4E4',
  //Cores de fields
  field: {
    label: '#707070',
    primary: '#2D95EF',
    normal: '#00000099',
    hover: '#000000',
    disabled: '#0000004D',
  },

  //Cores da navegação
  navigation: {
    normal: '#666666',
    active: '#2D95EF',
    hover: '#2D95EF',
  },
};
