import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';
import authReducer from '../pages/auth/authReducer';
import adminReducers from '../modules/admin/adminReducers';
import uploadReducer from '../../src/common/components/upload/UploadFiles/uploadReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  form: formReducer,
  toastr: toastrReducer,
  upload: uploadReducer,

  //Reducers from Admins module
  ...adminReducers,
});

export default rootReducer;
