import styled from 'styled-components';
import colorpicker from 'assets/img/ColorWheel.png';
import textcolorpicker from 'assets/img/Editing-Text-Color-icon.png';
import { BASE_S3 } from 'config/consts';

export const Question = styled.div`
  position: relative;
  width: 100%;
  height: 120px;
  border-radius: 6px;
  padding: 20px 25px;
  background: ${props =>
    !props.image
      ? props.background
        ? props.background
        : '#2D95EF'
      : props.image.indexOf('base64') > -1
      ? `url(${props.image})`
      : props.image.indexOf('rilato.app.files') > -1
      ? `url(${props.image})`
      : `url(${BASE_S3}/${props.image})`};
  background-position: center;
  background-size: cover;

  textarea {
    color: ${props => props.textColor};
    text-align: center;
    font-size: 24px;
    font-family: Roboto;
    width: 100%;
    height: 100%;
    border: 0;
    background: none;
    overflow: hidden;
    overflow-wrap: break-word;
  }
`;

export const TextImage = styled.input`
  color: ${props => props.textColor};
  width: 100%;
  height: 40px;
  font-size: 20px;
  text-align: center;
  background: #f7f7f7;
  border: 1px solid #eee;
  border-radius: 4px;
  margin-top: 1px;
`;

export const IconBackground = styled.div`
  position: absolute;
  bottom: 15px;
  right: 10px;

  label {
    cursor: pointer;
    margin: 0;

    &:hover {
      opacity: 0.7;
    }

    input {
      display: none;
    }
  }
`;

export const ColorPicker = styled.div`
  position: absolute;
  bottom: 52px;
  right: 12px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: ${`url(${colorpicker})`};
  background-position: center;
  background-size: cover;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

export const TextColorPicker = styled.div`
  position: absolute;
  bottom: 85px;
  right: 12px;
  width: 18px;
  height: 18px;
  background: ${`url(${textcolorpicker})`};
  background-position: center;
  background-size: cover;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;
