import Box from '@material-ui/core/Box';
import MaterialGrid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
// @material-ui/core
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Accessibility from '@material-ui/icons/Accessibility';
// @material-ui/icons
import Store from '@material-ui/icons/Store';
import welcome from 'assets/img/welcome.png';
import AlertDialogSlide from 'common/components/dialog/dialog';
import LoadingProgress from 'common/components/progress/loading';
import styles from 'material-dashboard-assets/jss/material-dashboard-react/views/dashboardStyle.js';
import Card from 'material-dashboard-components/Card/Card.js';
import CardBody from 'material-dashboard-components/Card/CardBody.js';
import CardHeader from 'material-dashboard-components/Card/CardHeader.js';
import CardIcon from 'material-dashboard-components/Card/CardIcon.js';
import GridContainer from 'material-dashboard-components/Grid/GridContainer.js';
// core components
import GridItem from 'material-dashboard-components/Grid/GridItem.js';
import Table from 'material-dashboard-components/Table/Table.js';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getStationData } from './dashboardAdminActions';

const useStyles = makeStyles(styles);

function Dashboard(props) {
  const classes = useStyles();
  const [visibleFirst, setVisibleFirst] = useState(false);

  useEffect(() => {
    props.getStationData();

    if (props.location.query.first_time) {
      setVisibleFirst({ visibleFirst: true });
    }
  }, []);

  if (props.dashboardAdmin.dataLoading) {
    return <LoadingProgress />;
  }
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card style={{ position: 'relative' }}>
            <CardHeader color="warning" stats icon>
              <CardIcon color="white">
                <Icon color="primary">cloud</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Atividades Ativas</p>
              <h3 className={classes.cardTitle}>
                {props.dashboardAdmin.data.actions.length}
              </h3>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="white">
                <Store color="primary" />
              </CardIcon>
              <p className={classes.cardCategory}>Caixa</p>
              <p style={{ fontSize: 18 }} className={classes.cardTitle}>
                R${' '}
                {parseFloat(props.dashboardAdmin.data.dec_points)
                  .toFixed(2)
                  .replace('.', ',')}
              </p>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="white">
                <Icon color="primary">local_offer</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Propostas de parceiros</p>
              <h3 className={classes.cardTitle}>
                {props.dashboardAdmin.data.actionOffers.length}
              </h3>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="white">
                <Accessibility color="primary" />
              </CardIcon>
              <p className={classes.cardCategory}>Participantes</p>
              <h3 className={classes.cardTitle}>
                {
                  props.dashboardAdmin.data.users.filter(
                    item => item.type_id === 2
                  ).length
                }
              </h3>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card onClick={() => props.router.push('/admin/parceiros')}>
            <CardHeader color="danger" stats icon>
              <CardIcon color="white">
                <Accessibility color="primary" />
              </CardIcon>
              <p className={classes.cardCategory}>Parceiros</p>
              <h3 className={classes.cardTitle}>
                {
                  props.dashboardAdmin.data.users.filter(
                    item => item.type_id === 4
                  ).length
                }
              </h3>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card onClick={() => props.router.push('/admin/gestores')}>
            <CardHeader color="warning" stats icon>
              <CardIcon color="white">
                <Accessibility color="primary" />
              </CardIcon>
              <p className={classes.cardCategory}>Estações</p>
              <h3 className={classes.cardTitle}>
                {
                  props.dashboardAdmin.data.users.filter(
                    item => item.type_id === 1
                  ).length
                }
              </h3>
            </CardHeader>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer></GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Atividades ativas</h4>
              <Box
                display="flex"
                style={{ width: '100%' }}
                justifyContent="space-between"
              >
                <span className={classes.cardCategoryWhite}>
                  As atividades ativas mais recentes na plataforma
                </span>
              </Box>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={['Nome', 'Data de criação']}
                tableData={props.dashboardAdmin.data.actions
                  .slice(0, 5)
                  .map(item => [
                    item.name,
                    moment(item.createdAt).format('DD/MM/YYYY HH:mm'),
                  ])}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Gestores</h4>
              <p className={classes.cardCategoryWhite}>
                Os últimos cadastros de gestores na plataforma
              </p>

              <span
                className={classes.cardCategoryWhite}
                style={{ color: 'white', cursor: 'pointer' }}
                onClick={() => props.router.push('/admin/gestores')}
              >
                Ver todos
              </span>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={['Nome', 'E-mail', 'Data de cadastro']}
                tableData={props.dashboardAdmin.data.users
                  .filter(item => item.type_id === 1)
                  .slice(0, 5)
                  .map(item => [
                    item.name,
                    item.email,
                    moment(item.createdAt).format('DD/MM/YYYY HH:mm'),
                  ])}
              />
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Parceiros</h4>
              <p className={classes.cardCategoryWhite}>
                Os últimos cadastros de parceiros na plataforma
              </p>
              <span
                className={classes.cardCategoryWhite}
                style={{ color: 'white', cursor: 'pointer' }}
                onClick={() => props.router.push('/admin/parceiros')}
              >
                Ver todos
              </span>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={['Nome', 'E-mail', 'Data de cadastro']}
                tableData={props.dashboardAdmin.data.users
                  .slice(0, 5)
                  .filter(item => item.type_id === 4)
                  .map(item => [
                    item.name,
                    item.email,
                    moment(item.createdAt).format('DD/MM/YYYY HH:mm'),
                  ])}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <AlertDialogSlide
        visible={visibleFirst}
        title="Seja Bem Vindo"
        onClose={() => {
          setVisibleFirst(false);
        }}
      >
        <MaterialGrid
          container
          spacing={2}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <MaterialGrid item md={6} lg={6}>
            <img alt="" className="img-fluid" src={welcome}></img>
          </MaterialGrid>
          <MaterialGrid item md={6} lg={6}>
            <Typography variant="h5">Seja bem vindo!</Typography>
            <Typography variant="subtitle1">
              Agora você faz parte do Time Rilato.
            </Typography>
            <Typography variant="body2">
              <br />
              <p>
                Você foi presenteado com R$ 150,00 e já montamos suas primeiras
                atividades.
              </p>
              <p>Aproveite!</p>
            </Typography>
          </MaterialGrid>
        </MaterialGrid>
      </AlertDialogSlide>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    activeActions: state.activeActions,
    auth: state.auth,
    dashboardAdmin: state.dashboardAdmin,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getStationData,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
