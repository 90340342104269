import { Box } from '@material-ui/core';
import React, { useState } from 'react';
import { Option, TextImage, CheckBox } from './styles';

export default function Options({ background, text, textColor, image }) {
  const [checked, setChecked] = useState(false);

  const handleChange = event => {
    setChecked(!checked);
  };

  return (
    <>
      <Box display="flex" flexDirection="column" style={{ width: '100%' }}>
        <Box>
          <Option
            onClick={handleChange}
            background={background}
            textColor={textColor}
            image={image}
            checked={checked}
          >
            {!image ? (
              <p
                disabled
                readonly
                spellcheck="false"
                rows="2"
                cols="50"
                maxlength="40"
                name="text"
              >
                {text}
              </p>
            ) : null}
            {checked ? (
              <CheckBox style={{ fontSize: '4.5em', color: '#333' }} />
            ) : null}
          </Option>
        </Box>
        <Box>
          {image ? (
            <TextImage disabled textColor={textColor} value={text} />
          ) : null}
        </Box>
      </Box>
    </>
  );
}
