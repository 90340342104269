import React from 'react';
import { IndexRoute, Route } from 'react-router';
import Lotties from './lotties';
import LottiesForm from './lottiesForm';

export default (
  <Route path="lotties">
    <IndexRoute component={props => <Lotties {...props} />} />
    <Route path="novo" component={props => <LottiesForm {...props} />} />
    <Route path=":id/:action" component={props => <LottiesForm {...props} />} />
  </Route>
);
