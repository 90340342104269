import { Typography } from '@material-ui/core';
import { InputLabelOutlined } from 'common/components/fields/fields';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import { FORM_RULES } from 'helpers/validations';
import React from 'react';
import { Field } from 'redux-form';

export default function TemplatePresentialCourse(props) {
  return (
    <GeneralGrid container spacing={3}>
      <GeneralGrid item xs={12}>
        <Typography>
          A estrutura a ser compartilhada é: Texto + (Quebra de linha) + link
        </Typography>
      </GeneralGrid>
      <GeneralGrid item xs={12}>
        <Field
          component={InputLabelOutlined}
          label={`Texto`}
          name={`share_content_text`}
          validate={[FORM_RULES.required]}
          required
          margin="normal"
        />
      </GeneralGrid>
      <GeneralGrid item xs={12}>
        <Field
          component={InputLabelOutlined}
          label={`Link`}
          name={`share_content_link`}
          validate={[FORM_RULES.required]}
          required
          margin="normal"
        />
      </GeneralGrid>
    </GeneralGrid>
  );
}
