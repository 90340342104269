import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import moment from 'moment';
import type from './types';

const URL = `${BASE_API}/list-all-clients`;

export const getpartnersList = id => {
  return dispatch => {
    dispatch({ type: type.LIST_LOAD, payload: true });
    axios
      .get(`${URL}/4`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        for (let i in response.data) {
          response.data[i]['createdAt'] = moment(
            response.data[i].createdAt
          ).format('DD/MM/YYYY HH:MM');
        }
        dispatch([
          {
            type: type.LIST_FETCHED,
            payload: response,
          },
        ]);
      });
  };
};
