import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import _ from 'lodash';
import type from './type';

const URL_GET_CAMPAIGNS = `${BASE_API}/actions/establishment`;
const URL_ESTABLISHMENTS = `${BASE_API}/establishments`;
const URL_CUPOM_OFFERS = `${BASE_API}/offer-by-establishment`;
const URL_CUPOM_ACTIVATE = `${BASE_API}/cupom-offer-active`;
const URL_CONTACTS = `${BASE_API}/contacts/establishment`;
const URL_COUPONS = `${BASE_API}/cupoms/establishment`;
const URL_WEBSITES = `${BASE_API}/landing-pages`;
const ADMIN_DATA = `${BASE_API}/admin-data`;

export const getStationData = () => {
  return dispatch => {
    dispatch([
      {
        type: 'ADMIN_DATA_LOAD',
        payload: true,
      },
    ]);
    axios
      .get(`${ADMIN_DATA}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        dispatch([
          {
            type: 'ADMIN_DATA_FETCHED',
            payload: response.data,
          },
        ]);
      })
      .catch(e => {});
  };
};

export const getCampaignsByStationId = id => {
  return dispatch => {
    dispatch([
      {
        type: type.CAMPAIGNS_LOAD,
        payload: true,
      },
    ]);
    axios
      .get(`${URL_GET_CAMPAIGNS}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        dispatch([
          {
            type: type.CAMPAIGNS_FETCHED,
            payload: response.data,
          },
        ]);
      })
      .catch(e => {});
  };
};

export const getCupomOffersByStationId = id => {
  return dispatch => {
    dispatch([
      {
        type: type.CUPOM_OFFERS_ESTABLISHMENT_LOAD,
        payload: true,
      },
    ]);
    axios
      .get(`${URL_CUPOM_OFFERS}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        dispatch([
          {
            type: type.CUPOM_OFFERS_ESTABLISHMENT_FETCHED,
            payload: response.data,
          },
        ]);
      })
      .catch(e => {});
  };
};

export const activateCupom = (id, active) => {
  return dispatch => {
    axios
      .post(
        `${URL_CUPOM_ACTIVATE}/${id}`,
        { active },
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        }
      )
      .then(response => {
        window.location.reload();
      })
      .catch(e => {
        //console.log(e);
      });
  };
};

export const getEstablishmentsList = () => {
  return dispatch => {
    dispatch([
      {
        type: 'ESTABLISHMENTS_LOAD',
        payload: true,
      },
    ]);
    axios
      .get(`${URL_ESTABLISHMENTS}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        dispatch([
          {
            type: 'ESTABLISHMENTS_FETCHED',
            payload: response,
          },
        ]);
      });
  };
};

export const getBasicGraphs = id => {
  return dispatch => {
    dispatch({
      type: type.BASIC_GRAPH_LOAD,
      payload: true,
    });
    axios
      .get(`${URL_CONTACTS}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(contactsResponse => {
        axios
          .get(`${URL_WEBSITES}/${id}`, {
            headers: {
              Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
            },
          })
          .then(websitesResponse => {
            axios
              .get(`${URL_COUPONS}/${id}`, {
                headers: {
                  Authorization: `${JSON.parse(
                    localStorage.getItem(USER_TOKEN)
                  )}`,
                },
              })
              .then(couponsResponse => {
                //console.log(contactsResponse, websitesResponse, couponsResponse);
                const contactsList = contactsResponse.data.map(contact => {
                  if (!_.isNull(contact.deletedAt)) {
                    return contact;
                  }
                  return null;
                });

                const websitesList = websitesResponse.data.map(website => {
                  if (!_.isNull(website.deletedAt)) {
                    return website;
                  }
                  return null;
                });
                const couponsList = couponsResponse.data.map(coupon => {
                  if (!_.isNull(coupon.deletedAt)) {
                    return coupon;
                  }
                  return null;
                });

                const data = {
                  contacts: contactsList.length,
                  websites: websitesList.length,
                  coupons: couponsList.length,
                };

                dispatch({
                  type: type.BASIC_GRAPHS_FETCHED,
                  payload: data,
                });
              })
              .catch(e => {
                //console.log("erros", e);

                try {
                  for (const i in e.response.data) {
                    for (const j in e.response.data[i]) {
                      dispatch({
                        type: 'SNACKBAR',
                        payload: {
                          message: `${i}: ${e.response.data.errors[i][j]}`,
                          variant: 'error',
                          open: true,
                        },
                      });
                    }
                  }
                } catch (error) {
                  dispatch({
                    type: 'SNACKBAR',
                    payload: { message: 'Erro', variant: 'error', open: true },
                  });
                }
              });
          })
          .catch(e => {
            try {
              for (const i in e.response.data) {
                for (const j in e.response.data[i]) {
                  dispatch({
                    type: 'SNACKBAR',
                    payload: {
                      message: `${i}: ${e.response.data.errors[i][j]}`,
                      variant: 'error',
                      open: true,
                    },
                  });
                }
              }
            } catch (error) {
              dispatch({
                type: 'SNACKBAR',
                payload: { message: 'Erro', variant: 'error', open: true },
              });
            }
          });
      })
      .catch(e => {
        try {
          for (const i in e.response.data) {
            for (const j in e.response.data[i]) {
              dispatch({
                type: 'SNACKBAR',
                payload: {
                  message: `${i}: ${e.response.data.errors[i][j]}`,
                  variant: 'error',
                  open: true,
                },
              });
            }
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};
