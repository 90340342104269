import { Box } from '@material-ui/core';
import PrimaryButton from 'common/components/buttons/primaryButton';
import { InputLabelOutlined } from 'common/components/fields/fields';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import Grid from 'common/components/layout/grid/grid';
import LoadingProgress from 'common/components/progress/loading';
import { create_or_update } from 'helpers/formComponent';
import { FORM_RULES } from 'helpers/validations';
import moment from 'moment';
import { requestPasswordResetLogged } from 'pages/auth/authActions';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import {
  create,
  getDetail,
  initializeForm,
  setDetail,
  update,
} from './profileActions';
import { handleWhiteSpaces } from '../../../../helpers/dataTransformers';

let ProfileForm = function(props) {
  const [visible, setVisible] = React.useState(false);
  props.setDetail(props.auth.user);
  function onSubmit(values) {
    const { router, create, update } = props;
    let firstTime = false;
    if (props.location.query.first_action) {
      firstTime = true;
    }

    values.checkbox = values.checkbox ? true : false;
    values.slider = values.slider ? true : false;
    console.log(values);
    values.expiration = moment(values.expiration, 'MMM Do HH:mm').format(
      'YYYY-MM-DD HH:mm'
    );
    console.log(values, moment(values.expiration).isValid());
    values.base64 = values.image.base64;
    values['station'] = props.auth.user.establishments[0].stations[0].id;
    let a = values.base64.indexOf('/');
    let b = values.base64.indexOf(';');
    values.imageType = values.base64.slice(a + 1, b);
    delete values['image'];
    create_or_update(router, values, create, update, firstTime);
  }
  const buttonClick = () => {
    const values = {
      email: props.auth.user.email,
    };
    const { router } = props;
    props.requestPasswordResetLogged(values, router);
    setVisible(true);
  };
  return props.general.loading ? (
    <Box display="flex" justifyContent="center" mt={10}>
      <LoadingProgress />
    </Box>
  ) : (
    <section id="general-form" style={{ marginTop: '4.3em' }}>
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12}>
          <div className="card">
            <div className="card-body">
              <p className=" h4 card-title text-muted">Perfil</p>
              <Form
                role="form"
                onSubmit={props.handleSubmit(onSubmit)}
                noValidate
              >
                <GeneralGrid container spacing={3}>
                  <GeneralGrid item xs={12} md={6} lg={6}>
                    <Field
                      component={InputLabelOutlined}
                      label="Nome"
                      name="name"
                      validate={[FORM_RULES.required]}
                      required
                      disabled
                      margin="normal"
                    />
                  </GeneralGrid>
                  <GeneralGrid item xs={12} md={6} lg={6}>
                    <Field
                      component={InputLabelOutlined}
                      label="E-mail"
                      name="email"
                      validate={[FORM_RULES.required]}
                      required
                      disabled
                      margin="normal"
                      normalize={handleWhiteSpaces}
                    />
                  </GeneralGrid>
                </GeneralGrid>
                <Grid cols="12 12 12 12" style={{ textAlign: 'center' }}>
                  <PrimaryButton
                    type="button"
                    onClick={() => buttonClick()}
                    disabled={props.general.buttonLoading}
                    progress={parseInt(props.general.submitProgress)}
                  >
                    {props.general.buttonLoading
                      ? 'Enviando'
                      : 'Mudar minha senha'}
                  </PrimaryButton>
                  {visible ? (
                    <p>Você receberá um e-mail com as instruções. Obrigado!</p>
                  ) : null}
                </Grid>
              </Form>
            </div>
          </div>
        </GeneralGrid>
      </GeneralGrid>
    </section>
  );
};

ProfileForm = reduxForm({ form: 'ProfileForm', enableReinitialize: true })(
  ProfileForm
);

const mapStateToProps = state => {
  return {
    auth: state.auth,
    general: state.general,
    formValues: getFormValues('ProfileForm')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getDetail,
      setDetail,
      initializeForm,
      create,
      update,
      requestPasswordResetLogged,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileForm);
