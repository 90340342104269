import { Box } from '@material-ui/core';
import MaterialGrid from '@material-ui/core/Grid';
import PrimaryButton from 'common/components/buttons/primaryButton';
import LoadingProgress from 'common/components/progress/loading';
import { create_or_update, setFormValue } from 'helpers/formComponent';
import { FORM_RULES } from 'helpers/validations';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import { EditorConvertToHTMLActivity } from '../../../../common/components/fields/fields';
import {
  create,
  fileRejected,
  getDetail,
  initializeForm,
  setDetail,
  setLoading,
  update,
} from './termsOfUseActions';

let TermsOfUseForm = function(props) {
  const { termsOfUse, router, location, getDetail, setDetail } = props;

  useEffect(() => {
    if (!router.params.id) {
      props.setLoading(false);
    } else {
      setFormValue(termsOfUse, router, location, getDetail, setDetail);
    }
  }, []);

  function onSubmit(values) {
    const { create, update } = props;
    const newValues = {
      ...values,
    };
    let firstTime = false;

    create_or_update(router, newValues, create, update, firstTime);
  }

  return props.termsOfUse.loading ? (
    <Box display="flex" height="100vh" justifyContent="center" mt={10}>
      <LoadingProgress />
    </Box>
  ) : (
    <section id="termsOfUse-form" style={{ marginTop: '4.3em' }}>
      <div className="card">
        <div className="card-body">
          <p className=" h4 card-title text-muted">Formulário</p>
          <Form role="form" onSubmit={props.handleSubmit(onSubmit)} noValidate>
            <MaterialGrid
              container
              spacing={3}
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <MaterialGrid item xs={12}>
                <Field
                  component={EditorConvertToHTMLActivity}
                  label={'Termos do gestor'}
                  name="owner"
                  border
                  validate={[FORM_RULES.required]}
                />
              </MaterialGrid>
              <MaterialGrid item xs={12}>
                <Field
                  component={EditorConvertToHTMLActivity}
                  label={'Termos do parceiro'}
                  name="partner"
                  border
                  validate={[FORM_RULES.required]}
                />
              </MaterialGrid>
              <MaterialGrid item xs={12}>
                <Field
                  component={EditorConvertToHTMLActivity}
                  label={'Termos do participante'}
                  name="participant"
                  border
                  validate={[FORM_RULES.required]}
                />
              </MaterialGrid>
            </MaterialGrid>
            <PrimaryButton
              type="submit"
              disabled={props.termsOfUse.buttonLoading}
              progress={parseInt(props.general.submitProgress)}
            >
              {props.termsOfUse.buttonLoading ? 'Enviando' : 'Enviar'}
            </PrimaryButton>
          </Form>
        </div>
      </div>
    </section>
  );
};

TermsOfUseForm = reduxForm({ form: 'termsOfUseForm' })(TermsOfUseForm);

const mapStateToProps = state => {
  return {
    auth: state.auth,
    termsOfUse: state.termsOfUse,
    formValues: getFormValues('termsOfUseForm')(state),
    general: state.general,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getDetail,
      setDetail,
      initializeForm,
      create,
      update,
      setLoading,
      fileRejected,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsOfUseForm);
