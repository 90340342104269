import { Box, Icon, Tooltip, Typography } from '@material-ui/core';
import MaterialGrid from '@material-ui/core/Grid';
import PrimaryButton from 'common/components/buttons/primaryButton';
import {
  EditorConvertToHTMLActivity,
  InputFileWithUpload,
  InputLabelOutlined,
  SelectLabel,
  InputSwitchLarge,
} from 'common/components/fields/fields';
import LottieControl from 'common/components/lottie/lottie';
import LoadingProgress from 'common/components/progress/loading';
import { currencyMask } from 'helpers/masks';
import { FORM_RULES } from 'helpers/validations';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import Results from '../../../../../common/components/quiz/Results';
import { thousandSeparatorMask } from '../../../../../helpers/masks';
import { getListById as getLottiesListById } from '../../lotties/lottiesActions';
import { getAllGiveaways } from '../templatesActions';

class Prize extends Component {
  onSubmit = values => {
    if (!values.isPaid) {
      values.points = 0.0;
    }
    if (values.feedback_lottie_id === 'none') {
      values.feedback_lottie_id = null;
    }

    if (
      this.props.formValues.type_action_id === 1 ||
      this.props.formValues.type_action_id === 19 ||
      this.props.formValues.type_action_id === 19
    ) {
      if (!values.isInviterPaid) {
        values.points_inviter = 0.0;
      }
      if (!values.isInvitedPaid) {
        values.points_invited = 0.0;
      }
    }
    this.props.onClickProgress(values);
  };

  componentDidUpdate(props) {
    if (props.formValues) {
      if (
        props.formValues.feedback_lottie_id !==
        this.props.formValues.feedback_lottie_id
      ) {
        if (this.props.formValues.feedback_lottie_id !== 'none') {
          this.props.getLottiesListById(
            this.props.formValues.feedback_lottie_id
          );
        }
      }
    } else {
      if (this.props.formValues) {
        if (this.props.formValues.feedback_lottie_id !== 'none') {
          this.props.getLottiesListById(
            this.props.formValues.feedback_lottie_id
          );
        }
      }
    }
  }
  render() {
    let lottiesList = this.props.lotties.list.content.map((item, index) => {
      return {
        label: item.name,
        value: item.id,
        url: item.url,
      };
    });

    lottiesList.unshift({
      label: 'Nenhuma Animação',
      value: 'none',
      url: 'none',
    });

    const listGiveaways = this.props.templates.listGiveaways.content.map(
      (item, index) => ({
        value: item.id,
        label: item.name,
      })
    );

    return (
      <Form
        role="form"
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        noValidate
      >
        {(this.props.formValues.type_action_id <= 8 ||
          this.props.formValues.type_action_id === 10 ||
          this.props.formValues.type_action_id > 12) &&
        this.props.formValues.type_action_id !== 4 &&
        this.props.formValues.type_action_id !== 15 &&
        this.props.formValues.type_action_id !== 16 &&
        this.props.formValues.type_action_id !== 1 ? (
          <>
            <MaterialGrid item xs={12}>
              <Typography
                style={{ fontSize: 16, fontWeight: '400' }}
                variant="subtitle2"
              >
                {'Mensagem ao concluir atividade'}
              </Typography>
              <Field
                component={EditorConvertToHTMLActivity}
                label={''}
                name="feedback_message"
                disabled={this.props.disabled}
                required
                border
                validate={[FORM_RULES.required]}
              />
            </MaterialGrid>
            <MaterialGrid
              item
              xs={12}
              style={{
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <Field
                component={InputSwitchLarge}
                label="Animação de feedback"
                name="hasFeedbackAnimation"
              />
              {this.props.formValues.hasFeedbackAnimation ? (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginBottom: 10 }}
                >
                  <MaterialGrid item xs={6}>
                    <Field
                      component={SelectLabel}
                      options={lottiesList}
                      name="feedback_lottie_id"
                      label="Escolha uma animação para feedback"
                    />
                  </MaterialGrid>
                  <MaterialGrid item xs={6}>
                    <div style={{ height: 100 }}>
                      {this.props.formValues ? (
                        this.props.formValues.feedback_lottie_id !== 'none' ? (
                          this.props.lotties.loading ? (
                            <Box display="flex" justifyContent="center">
                              <LoadingProgress />
                            </Box>
                          ) : (
                            <Box display="flex" justifyContent="center">
                              <LottieControl
                                width={100}
                                height={100}
                                loop={false}
                                animation={this.props.lotties.detail.json}
                                hasReplayButton
                              />
                            </Box>
                          )
                        ) : null
                      ) : null}
                    </div>
                  </MaterialGrid>
                </Box>
              ) : null}
            </MaterialGrid>
          </>
        ) : this.props.formValues.type_action_id === 12 ||
          this.props.formValues.type_action_id === 4 ||
          this.props.formValues.type_action_id === 15 ||
          this.props.formValues.type_action_id === 16 ||
          this.props.formValues.type_action_id === 1 ||
          this.props.formValues.type_action_id === 19 ? null : (
          <Field component={Results} name="results" />
        )}
        {this.props.formValues.type_action_id === 1 ||
        this.props.formValues.type_action_id === 19 ? (
          <MaterialGrid container spacing={3} justify="center">
            <MaterialGrid item xs={12}>
              <Field
                component={InputLabelOutlined}
                label="Total de convidados que ganharão recompensa"
                name="limit"
                validate={[FORM_RULES.required]}
                required
                margin="normal"
                {...thousandSeparatorMask}
              />
            </MaterialGrid>
            <MaterialGrid item xs={12}>
              <Field
                component={InputLabelOutlined}
                label="Total de convidados por usuário que podem ganhar recompensa"
                name="limitPerUser"
                validate={[FORM_RULES.required]}
                required
                margin="normal"
                {...thousandSeparatorMask}
              />
            </MaterialGrid>
          </MaterialGrid>
        ) : this.props.formValues.type_action_id === 2 ||
          this.props.formValues.type_action_id === 16 ? (
          <MaterialGrid container spacing={3} justify="center">
            <MaterialGrid item xs={12}>
              <Typography
                style={{ fontSize: 16, fontWeight: '400' }}
                variant="subtitle2"
              >
                {'Limite de participação'}
              </Typography>
              <Field
                component={InputLabelOutlined}
                label="Limite de participações na atividade"
                name="limit"
                validate={[FORM_RULES.required]}
                required
                margin="normal"
                {...thousandSeparatorMask}
              />
            </MaterialGrid>
          </MaterialGrid>
        ) : (
          <MaterialGrid container spacing={3} justify="center" style={{}}>
            <MaterialGrid item xs={12}>
              <Typography
                style={{ fontSize: 16, fontWeight: '400' }}
                variant="subtitle2"
              >
                {'Limite de participação'}
              </Typography>
              <Field
                component={InputLabelOutlined}
                label="Limite de participações na atividade"
                name="limit"
                validate={[FORM_RULES.required]}
                required
                margin="normal"
                {...thousandSeparatorMask}
              />
            </MaterialGrid>
            <MaterialGrid item xs={12}>
              <Field
                component={InputLabelOutlined}
                label="Limite de participações por usuário"
                name="limitPerUser"
                validate={[FORM_RULES.required]}
                required
                margin="normal"
                {...thousandSeparatorMask}
              />
            </MaterialGrid>
          </MaterialGrid>
        )}

        {this.props.formValues.type_action_id === 1 ||
        this.props.formValues.type_action_id === 19 ? (
          <>
            <MaterialGrid container spacing={3}>
              <MaterialGrid item xs={12}>
                <Field
                  component={InputSwitchLarge}
                  label={'Quem convida ganha recompensa?'}
                  name="isInviterPaid"
                />
              </MaterialGrid>
              {this.props.formValues.isInviterPaid ? (
                <MaterialGrid item xs={12}>
                  <Field
                    component={InputLabelOutlined}
                    label={'De quanto será a recompensa de quem convida?'}
                    name="points_inviter"
                    type="tel"
                    required
                    validate={[FORM_RULES.required]}
                    margin="normal"
                    {...currencyMask}
                  />
                </MaterialGrid>
              ) : null}
            </MaterialGrid>
            <MaterialGrid container spacing={3}>
              <MaterialGrid item xs={12}>
                <Field
                  component={InputSwitchLarge}
                  label={'O convidado ganhará recompensa?'}
                  name="isInvitedPaid"
                />
              </MaterialGrid>
              {this.props.formValues.isInvitedPaid ? (
                <MaterialGrid item xs={12}>
                  <Field
                    component={InputLabelOutlined}
                    label={'De quanto será a recompensa do convidado?'}
                    name="points_invited"
                    type="tel"
                    required
                    validate={[FORM_RULES.required]}
                    margin="normal"
                    {...currencyMask}
                  />
                </MaterialGrid>
              ) : null}
            </MaterialGrid>
          </>
        ) : (
          <>
            <>
              <MaterialGrid item xs={12} sm={12}>
                <Field
                  component={InputSwitchLarge}
                  label="Recompensar participação"
                  name="isPaid"
                />
              </MaterialGrid>
              {this.props.formValues.isPaid ? (
                <MaterialGrid container spacing={3}>
                  <MaterialGrid item xs={12}>
                    <Field
                      component={InputLabelOutlined}
                      label="Quanto vai ganhar quem participar? (R$)"
                      name="points"
                      type="tel"
                      validate={[FORM_RULES.required]}
                      required
                      margin="normal"
                      {...currencyMask}
                    />
                  </MaterialGrid>
                </MaterialGrid>
              ) : null}
            </>
          </>
        )}
        {parseInt(this.props.formValues.type_action_id) !== 16 ? (
          <MaterialGrid container spacing={3}>
            <MaterialGrid item xs={12}>
              <Field
                component={InputSwitchLarge}
                label="Regulamento"
                name="has_rules"
              />
            </MaterialGrid>
            {this.props.formValues.has_rules && (
              <MaterialGrid item xs={12}>
                <Field
                  component={EditorConvertToHTMLActivity}
                  label={''}
                  name="rules"
                  border
                  rows={4}
                  validate={[FORM_RULES.required]}
                />
              </MaterialGrid>
            )}
          </MaterialGrid>
        ) : null}
        {this.props.formValues ? (
          this.props.formValues.has_giveaway ? (
            <MaterialGrid container direction="row" justify="space-evenly">
              <MaterialGrid item xs={12} sm={12} md={6} lg={4}>
                <Field
                  component={InputLabelOutlined}
                  label="Quantos números da sorte vai ganhar quem participar"
                  name="giveaway_numbers"
                  type="tel"
                  validate={[FORM_RULES.required]}
                  required
                  margin="normal"
                />
              </MaterialGrid>
              <MaterialGrid item xs={12} sm={12}>
                <Field
                  component={SelectLabel}
                  label={'Promoção'}
                  name="giveaway"
                  required
                  options={listGiveaways}
                  margin="normal"
                  validate={[FORM_RULES.required]}
                />
              </MaterialGrid>
            </MaterialGrid>
          ) : null
        ) : null}

        <MaterialGrid item xs={12} sm={12}>
          <Field
            component={InputSwitchLarge}
            label="Receber troféu ao concluir"
            name="isThereTrophy"
            disabled={this.props.disabled}
          />
        </MaterialGrid>
        {this.props.formValues ? (
          this.props.formValues.isThereTrophy ? (
            <>
              <MaterialGrid item xs={12}>
                <Typography
                  style={{ marginBottom: 5, marginTop: 16 }}
                  variant="subtitle2"
                  align="left"
                >
                  Ícone do Troféu
                </Typography>
                <Field
                  component={InputFileWithUpload}
                  label={'Imagem .png ou .jpg de proporção 1x1 (quadrada)'}
                  name="trophy_image"
                  validate={[FORM_RULES.required]}
                />
              </MaterialGrid>
              <MaterialGrid item xs={12} style={{ marginTop: 8 }}>
                <Typography
                  style={{ marginBottom: 5, display: 'inline' }}
                  variant="subtitle2"
                  align="left"
                >
                  Mensagem de exibição do troféu
                </Typography>
                <div style={{ cursor: 'pointer', display: 'inline' }}>
                  <Tooltip
                    style={{ marginLeft: 10 }}
                    title="É a mensagem que aparecerá para o usuário ao passar o mouse no ícone do troféu."
                    placement="top-start"
                  >
                    <Icon color="default">help</Icon>
                  </Tooltip>
                </div>
                <Field
                  component={InputLabelOutlined}
                  label="Texto do troféu"
                  name="trophy_description"
                  margin="normal"
                  validate={[FORM_RULES.required]}
                />
              </MaterialGrid>
            </>
          ) : null
        ) : null}

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{ marginTop: 50 }}
        >
          <Typography
            style={{ cursor: 'pointer' }}
            onClick={this.props.prevClick}
            color="primary"
          >
            Anterior
          </Typography>
          <PrimaryButton
            type="submit"
            color="primary"
            disabled={this.props.templatesDrafts.buttonLoading}
            progress={parseInt(this.props.general.submitProgress)}
          >
            {this.props.templatesDrafts.buttonLoading
              ? 'Salvando'
              : 'Salvar e Continuar'}
          </PrimaryButton>
        </Box>
      </Form>
    );
  }
}

Prize = reduxForm({
  form: 'templatesForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(Prize);
const mapStateToProps = state => {
  return {
    auth: state.auth,
    templates: state.templates,
    templatesDrafts: state.templatesDrafts,
    general: state.general,
    formValues: getFormValues('templatesForm')(state),
    lotties: state.lotties,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getAllGiveaways,
      getLottiesListById,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Prize);
