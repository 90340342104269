import { Box, Container, Link, Typography } from '@material-ui/core';
import logo from 'assets/img/fan_logo.svg';
import { FORM_RULES } from 'helpers/validations';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm } from 'redux-form';
import PrimaryButton from '../../../common/components/buttons/primaryButton';
import { InputLabelOutlined } from '../../../common/components/fields/fields';
import GeneralGrid from '../../../common/components/layout/grid/GeneralGrid';
import CustomizedSnackbar from '../../../common/components/snackbar/Snackbar';
import { appColors } from '../../../styles/colors';
import { updatePassword } from '../authActions';

class NewPassword extends Component {
  onSubmit = values => {
    const newValues = {
      ...values,
      hash: this.props.router.params.userHash,
      email: this.props.router.params.userEmail,
    };
    console.log(newValues);
    this.props.updatePassword(newValues, this.props.router);
  };

  render() {
    return (
      <>
        <Container maxWidth="xl" style={{ paddingBottom: '4rem' }}>
          <GeneralGrid
            container
            spacing={8}
            justify="center"
            style={{ background: 'white' }}
          >
            <GeneralGrid item xs={8} md={5}>
              <Box display="flex" justifyContent="center" mt={5}>
                <Link to="/admin">
                  <div
                    style={{
                      width: 80,
                      height: 80,
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      padding: '0 8px',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src={logo}
                      alt="Rilato Logo"
                      style={{ width: '100%', height: 'auto' }}
                    />
                  </div>
                </Link>
              </Box>

              <Box display="flex" justifyContent="center" mt={6}>
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 500,
                    fontSize: 32,
                  }}
                >
                  Redefinir sua senha
                </Typography>
              </Box>
              <Box
                display="flex"
                textAlign="center"
                justifyContent="center"
                mt={2}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: appColors.dark.level3 }}
                >
                  Digite e confirme sua nova senha.
                </Typography>
              </Box>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid container spacing={8} justify="center">
            <GeneralGrid item xs={12}>
              <Box mt={5}>
                <form
                  onSubmit={this.props.handleSubmit(v => this.onSubmit(v))}
                  noValidate
                >
                  <GeneralGrid container spacing={3} justify="center">
                    <GeneralGrid item xs={8} md={4} lg={3}>
                      <Box mt={3}>
                        <Field
                          component={InputLabelOutlined}
                          label="Nova senha"
                          name="password"
                          type="password"
                          helperText="Digite sua nova senha"
                          validate={[FORM_RULES.required]}
                        />
                      </Box>
                      <Box mt={3}>
                        <Field
                          component={InputLabelOutlined}
                          label="Confirme sua senha"
                          type="password"
                          name="password_confirmation"
                          helperText="Confirme sua nova senha"
                          validate={[FORM_RULES.required]}
                        />
                      </Box>
                    </GeneralGrid>
                  </GeneralGrid>

                  <GeneralGrid container spacing={3} justify="center">
                    <GeneralGrid item xs={8} md={4} lg={3}>
                      <Box display="flex" justifyContent="center" mt={3}>
                        <PrimaryButton
                          style={{ padding: '12px 0' }}
                          fullWidth={true}
                          router={this.props.router}
                          type="submit"
                        >
                          Enviar
                        </PrimaryButton>
                      </Box>
                    </GeneralGrid>
                  </GeneralGrid>
                  <Box display="flex" justifyContent="center" mt={3}>
                    <Typography variant="caption">
                      Voltar para{' '}
                      <a
                        href="#/login"
                        style={{
                          color: appColors.dark.level3,
                          textDecoration: 'underline',
                        }}
                      >
                        {' '}
                        rilato.app/login
                      </a>
                    </Typography>
                  </Box>
                </form>
              </Box>
            </GeneralGrid>
          </GeneralGrid>

          <CustomizedSnackbar />
        </Container>
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            background: 'white',
            textAlign: 'center',
            width: '100%',
            padding: '1rem 0',
          }}
        >
          <Typography variant="body1" style={{ color: appColors.dark.level4 }}>
            2019 Todos os direitos reservados.{' '}
            <a
              href="#/termos"
              style={{ color: '#2D95EF', textDecoration: 'underline' }}
            >
              Preferências de cookies
            </a>
            ,{' '}
            <a
              href="#/termos"
              style={{ color: '#2D95EF', textDecoration: 'underline' }}
            >
              privacidade
            </a>{' '}
            e{' '}
            <a
              href="#/termos"
              style={{ color: '#2D95EF', textDecoration: 'underline' }}
            >
              termos de uso.
            </a>
          </Typography>
        </div>
      </>
    );
  }
}

NewPassword = reduxForm({ form: 'authForm' })(NewPassword);
const mapDispatchToProps = dispatch => {
  return bindActionCreators({ updatePassword }, dispatch);
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewPassword);
