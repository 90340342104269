import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import type from './types';

const URL_SECTIONS = `${BASE_API}/sections`;

export const getListById = id => {
  return dispatch => {
    dispatch({ type: type.SECTION_LOAD, payload: true });

    axios
      .get(`${URL_SECTIONS}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        dispatch([
          {
            type: type.SECTIONS_FETCHED,
            payload: response,
          },
        ]);
      })
      .catch(e => {
        try {
          for (const i in e.response.data) {
            for (const j in e.response.data[i]) {
              dispatch({
                type: 'SNACKBAR',
                payload: {
                  message: `${i}: ${e.response.data.errors[i][j]}`,
                  variant: 'error',
                  open: true,
                },
              });
            }
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const getList = () => {
  return dispatch => {
    dispatch({ type: type.SECTION_LOAD, payload: true });

    axios
      .get(`${URL_SECTIONS}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        dispatch([
          {
            type: type.SECTIONS_FETCHED,
            payload: response,
          },
        ]);
      })
      .catch(e => {
        try {
          for (const i in e.response.data) {
            for (const j in e.response.data[i]) {
              dispatch({
                type: 'SNACKBAR',
                payload: {
                  message: `${i}: ${e.response.data.errors[i][j]}`,
                  variant: 'error',
                  open: true,
                },
              });
            }
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};
