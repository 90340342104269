import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';

const URL_BUY_ACTION = `${BASE_API}/establishments/buy-action-transactions`;
const URL_EARN_POINTS = `${BASE_API}/earn-points-transactions`;
const URL_USER_BUY_POINTS = `${BASE_API}/user-buy-points-transactions`;
const URL_USE_POINTS = `${BASE_API}/use-points-transactions`;
const URL_GET_ESTABLISHMENT = `${BASE_API}/establishments`;

export const getEstablishment = () => {
  return dispatch => {
    dispatch([
      {
        type: 'ESTABLISHMENT_LOAD',
        payload: true,
      },
    ]);
    axios
      .get(`${URL_GET_ESTABLISHMENT}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        dispatch([
          {
            type: 'ESTABLISHMENT_FETCHED',
            payload: response.data,
          },
        ]);
      })
      .catch(e => {});
  };
};

export const getUserBuyActions = () => {
  return dispatch => {
    dispatch([
      {
        type: 'BUY_ACTION_TRANSACTION_LOAD',
        payload: true,
      },
    ]);
    axios
      .get(`${URL_BUY_ACTION}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        dispatch([
          {
            type: 'BUY_ACTION_TRANSACTION_FETCHED',
            payload: response.data,
          },
        ]);
      })
      .catch(e => {});
  };
};

export const getEarnPoints = () => {
  return dispatch => {
    dispatch([
      {
        type: 'EARN_POINTS_TRANSACTIONS_LOAD',
        payload: true,
      },
    ]);
    axios
      .get(`${URL_EARN_POINTS}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        dispatch([
          {
            type: 'EARN_POINTS_TRANSACTIONS_FETCHED',
            payload: response.data,
          },
        ]);
      })
      .catch(e => {});
  };
};

export const getUsePoints = () => {
  return dispatch => {
    dispatch([
      {
        type: 'USE_POINTS_LOAD',
        payload: true,
      },
    ]);
    axios
      .get(`${URL_USE_POINTS}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        dispatch([
          {
            type: 'USE_POINTS_FETCHED',
            payload: response.data,
          },
        ]);
      })
      .catch(e => {});
  };
};

export const getUserBuyPoints = () => {
  return dispatch => {
    dispatch([
      {
        type: 'USER_BUY_POINTS_LOAD',
        payload: true,
      },
    ]);
    axios
      .get(`${URL_USER_BUY_POINTS}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        dispatch([
          {
            type: 'USER_BUY_POINTS_FETCHED',
            payload: response.data,
          },
        ]);
      })
      .catch(e => {});
  };
};
