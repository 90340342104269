import { Typography } from '@material-ui/core';
import MaterialGrid from '@material-ui/core/Grid';
import {
  InputLabelOutlined,
  InputSwitchGrey,
} from 'common/components/fields/fields';
import { currencyMask } from 'helpers/masks';
import { FORM_RULES } from 'helpers/validations';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { Field } from 'redux-form';

export default function TemplatePresentialCourse(props) {
  useEffect(() => {
    if (props.formValues) {
      if (!props.formValues.prices) {
        props.formValues['price'] = 0;
        props.formValues['discount_price'] = 0;
        props.formValues['discountPrice'] = 0;
      }
      if (
        !_.isUndefined(props.formValues.price) &&
        !_.isUndefined(props.formValues.discountPrice)
      ) {
        props.formValues['discount_price'] =
          props.formValues.price - props.formValues.discountPrice;
      } else if (
        !_.isUndefined(props.formValues.price) &&
        _.isUndefined(props.formValues.discountPrice)
      ) {
        props.formValues['discount_price'] = props.formValues.price;
      }
    }
  });
  return (
    <MaterialGrid container spacing={3}>
      <MaterialGrid item xs={12}>
        <Field
          component={InputLabelOutlined}
          label={'Data de Expiração do Voucher'}
          name="date"
          type="datetime-local"
          required
          validate={[FORM_RULES.required]}
          margin="normal"
        />
      </MaterialGrid>
      <MaterialGrid
        item
        xs={12}
        style={{
          backgroundColor: '#f7f7f7',
          padding: 16,
        }}
      >
        <Field
          component={InputSwitchGrey}
          label={'Preços'}
          boxStyle={{ backgroundColor: '#f7f7f7' }}
          firstState="Defina o preço original e o desconto"
          secondState="Ative para mostrar preços"
          name="prices"
          // required
          margin="normal"
        />
        {props.formValues.prices ? (
          <MaterialGrid container spacing={3}>
            <MaterialGrid item xs={4}>
              <Field
                component={InputLabelOutlined}
                type="tel"
                label={'Valor em reais'}
                name="price"
                required
                validate={[FORM_RULES.required]}
                margin="normal"
                {...currencyMask}
              />
            </MaterialGrid>
            <MaterialGrid item xs={4}>
              <Field
                component={InputLabelOutlined}
                type="tel"
                label={'Desconto em reais'}
                name="discountPrice"
                required
                validate={[FORM_RULES.required]}
                margin="normal"
                {...currencyMask}
              />
            </MaterialGrid>
            <MaterialGrid
              item
              xs={4}
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: 10,
              }}
            >
              <Typography color="primary" style={{ fontSize: 12 }}>
                Valor com desconto
              </Typography>
              <Typography style={{ color: 'black', marginTop: 5 }}>
                {props.formValues
                  ? 'R$ ' +
                    parseFloat(props.formValues.discount_price || 0)
                      .toFixed(2)
                      .replace('.', ',')
                  : null}
              </Typography>
            </MaterialGrid>
          </MaterialGrid>
        ) : null}
      </MaterialGrid>

      <MaterialGrid item xs={12}>
        <Field
          component={InputLabelOutlined}
          type="text"
          label={'Regulamento do Voucher'}
          name="local"
          cols="12 12 4 4"
          required
          validate={[FORM_RULES.required]}
          margin="normal"
        />
      </MaterialGrid>
    </MaterialGrid>
  );
}
