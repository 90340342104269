import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import logo from 'assets/img/fan_logo.svg';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router';
import { appColors } from 'styles/colors.jsx';
import ActionsTab from './actionsTab/actionsTab';
import './navBarDashboard.css';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: appColors.background.level1,
    boxShadow: 'none',
    '& .MuiPaper-root': {
      border: 0,
    },
  },
  appBarShift: {},
  menuButton: {
    color: '#66788A',
  },
  hide: {
    display: 'none',
  },
  icon: {
    minWidth: 0,
    paddingRight: theme.spacing(3),
  },
  listItem: {
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: '1.5rem',
    paddingBottom: '1.5rem',
  },
  list: {
    paddingTop: 0,
  },
  font: {
    fontWeight: 500,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },

  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(9),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  drawerHeader: {
    display: 'inline-flex',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
  },
  drawerBorder: {
    border: 0,
  },
  content: {
    flexGrow: 1,
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    backgroundColor: '#f7f7f7',
    height: '100vh',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  title: {
    flexGrow: 1,
    color: appColors.primary,
    fontSize: '1.7rem',
    fontWeight: 400,
  },
  active: {
    color: appColors.navigation.active,
  },
  toolbar: {
    height: '72px',
    paddingLeft: 12,
  },
  overflow: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '-webkit-fill-available',
  },
  contentOver: {
    marginTop: '5.5em',
  },
  contentOver2: {
    marginTop: '1.3em',
  },
}));

export default function NavBarDashboard(props) {
  const route = window.location.hash.split('#')[1];
  function handleFirstStateActive() {
    for (let i in props.navBarItems) {
      if (props.navBarItems[i].link === route) {
        return props.navBarItems[i].id;
      } else {
        if (props.navBarItems[i].children) {
          for (let item in props.navBarItems[i].children) {
            if (props.navBarItems[i].children[item].link === route) {
              return props.navBarItems[i].id;
            }
          }
        }
      }
    }
  }

  function handleFirstStateHeaderName() {
    for (let i in props.navBarItems) {
      if (props.navBarItems[i].link === route) {
        return props.navBarItems[i].name;
      } else {
        if (props.navBarItems[i].children) {
          for (let item in props.navBarItems[i].children) {
            if (props.navBarItems[i].children[item].link === route) {
              return props.navBarItems[i].name;
            }
          }
        }
      }
    }
  }

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [active, setActive] = useState(handleFirstStateActive());
  const [headerName, setHeaderName] = useState(handleFirstStateHeaderName());
  const openAnchor = Boolean(anchorEl);

  useEffect(() => {
    for (let i in props.navBarItems) {
      if (props.navBarItems[i].link === route) {
        setActive(props.navBarItems[i].id);
      } else {
        if (props.navBarItems[i].children) {
          for (let item in props.navBarItems[i].children) {
            if (props.navBarItems[i].children[item].link === route) {
              setActive(props.navBarItems[i].id);
            }
          }
        }
      }
    }
  }, [route]);

  useEffect(() => {
    for (let i in props.navBarItems) {
      if (props.navBarItems[i].link === route) {
        setHeaderName(props.navBarItems[i].name);
      } else {
        if (props.navBarItems[i].children) {
          for (let item in props.navBarItems[i].children) {
            if (props.navBarItems[i].children[item].link === route) {
              setHeaderName(props.navBarItems[i].name);
            }
          }
        }
      }
    }
  }, [route]);

  function handleDrawerChange() {
    setOpen(!open);
  }
  function handleDrawerOpen() {
    setOpen(true);
  }
  function handleMenu(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleDrawerClose() {
    setOpen(false);
  }
  function handleClose() {
    setAnchorEl(null);
  }
  const handleActive = (index, item) => {
    setOpen(false);
    setActive(item.id);
    setHeaderName(item.name);
  };
  const handleHome = () => {
    setOpen(false);
    setActive(0);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            style={{
              width: 72,
              height: 72,
              backgroundColor: isMobile ? 'inherit' : 'white',
              borderRadius: 0,
            }}
            className={clsx(classes.menuButton, { [classes.hide]: open })}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            style={{ marginLeft: isMobile ? 0 : 24, color: '#505050' }}
            variant="h6"
            noWrap
            className={classes.title}
          >
            {headerName}
          </Typography>
          <div>
            <IconButton
              aria-label="Account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="#66788A"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={openAnchor}
              onClose={handleClose}
            >
              <MenuItem onClick={() => props.router.push('/admin/profile')}>
                Perfil
              </MenuItem>
              <MenuItem onClick={() => props.router.push('/logout')}>
                Sair
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
        {props.navBarItems.map((item, index) =>
          item.id === active && item.children ? (
            <ActionsTab
              key={item.id}
              isMobile={isMobile}
              router={props.router}
              active={active}
              navBarItem={item}
            />
          ) : null
        )}
      </AppBar>
      <Drawer
        onBlur={null}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
          paperAnchorDockedLeft: classes.drawerBorder,
        }}
        variant={open || isMobile ? 'temporary' : 'permanent'}
        open={open}
      >
        <div className={classes.drawerHeader}>
          <IconButton style={{ visibility: 'hidden' }}>
            <CloseIcon />
          </IconButton>
          <div
            className="d-inline-flex flex-column align-items-center justify-content-center"
            style={{ marginTop: 70, minWidth: 0 }}
          >
            {/*  <div
              className="d-flex align-items-center justify-content-center"
              style={{
                borderRadius: '50%',
                backgroundColor: `${appColors.light.level2}`,
                height: 50,
                width: 50,
                visibility: open ? 'visible' : 'hidden',
              }}
            >
              {!_.isEmpty(props.auth.user.establishments) ? (
                !_.isEmpty(props.auth.user.establishments[0].stations) ? (
                  <img
                    src={`${BASE_S3}/${props.auth.user.establishments[0].stations[0].banner_image}`}
                    style={{
                      height: 50,
                      width: 50,
                      borderRadius: '50%',
                      objectFit: 'cover',
                    }}
                    alt="Banner"
                  />
                ) : (
                  <CloudUploadIcon
                    style={{
                      fontSize: 24,
                      color: '#666666',
                    }}
                  />
                )
              ) : (
                <CloudUploadIcon
                  style={{
                    fontSize: 24,
                    color: '#666666',
                  }}
                />
              )}
            </div>
            <Typography
              style={{
                fontFamily: "'Roboto', serif",
                fontWeight: 400,
                fontSize: 16,
                paddingTop: 16,
                visibility: open ? 'visible' : 'hidden',
              }}
              className={classes.overflow}
            >
              {props.website
                ? !_.isEmpty(props.website.list)
                  ? props.website.list[0].name
                  : 'Não há estação'
                : 'Não há estação'}
            </Typography> */}
            {/* <Typography
              style={{
                fontFamily: "'Roboto', sans-serif",
                fontWeight: 400,
                fontSize: 12,
                color: '#707070',
                visibility: open ? 'visible' : 'hidden',
              }}
              className={classes.overflow}
            >
              {!_.isEmpty(props.auth.user.establishments)
                ? props.auth.user.establishments[0].name
                : 'Não há empresa'}
            </Typography> */}
          </div>

          {isMobile ? (
            <IconButton onClick={handleDrawerChange}>
              {theme.direction === 'ltr' ? <CloseIcon /> : <ChevronRightIcon />}
            </IconButton>
          ) : (
            <IconButton
              className="align-self-start"
              onClick={handleDrawerClose}
            >
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <CloseIcon />}
            </IconButton>
          )}
        </div>

        <List
          onMouseOver={() => handleDrawerOpen()}
          onMouseLeave={() => handleDrawerClose()}
          classes={{ root: classes.list }}
          style={{ marginTop: 70 }}
        >
          {props.navBarItems.map((item, index) => (
            <div>
              <Link
                to={item.link}
                onClick={() => setHeaderName(item.name)}
                style={{
                  color: 'inherit',
                  textDecoration: 'none',
                }}
              >
                <ListItem
                  button
                  disableGutters={true}
                  className={clsx(
                    'button-item',
                    classes.listItem,
                    parseInt(active) === item.id ? classes.active : undefined
                  )}
                  onClick={() => handleActive(`${item.id}`, item)}
                >
                  <ListItemIcon
                    classes={{ root: classes.icon }}
                    className={
                      parseInt(active) === item.id ? classes.active : undefined
                    }
                    style={{ fontSize: 24 }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    className={
                      parseInt(active) === item.id ? classes.active : undefined
                    }
                    disableTypography
                    style={{
                      fontWeight: 400,
                      fontSize: 16,
                    }}
                    primary={item.name}
                  />
                </ListItem>
              </Link>
            </div>
          ))}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '0 8px',
              justifyContent: 'center',
              marginTop: 20,
            }}
          >
            <Link to="/" onClick={() => handleHome()}>
              <div
                style={{
                  width: 60,
                  height: 60,
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  padding: '0 8px',
                  justifyContent: 'center',
                }}
              >
                <img
                  src={logo}
                  alt="Rilato Logo"
                  style={{ width: '100%', height: 'auto' }}
                />
              </div>
            </Link>
          </div>
        </List>
      </Drawer>
      <main
        style={{
          marginLeft: open ? 72 : 0,
        }}
        className={clsx(
          classes.content /* , {
                    [classes.contentShift]: open
                } */
        )}
      >
        <section className={classes.drawerHeader} />
        <section
          className={
            props.router.location.pathname === '/admin/categorias' ||
            props.router.location.pathname === '/admin/lotties' ||
            props.router.location.pathname === '/admin' ||
            props.router.location.pathname === '/admin/termos-de-uso'
              ? classes.contentOver2
              : classes.contentOver
          }
        >
          {props.children}
        </section>
      </main>
    </div>
  );
}
