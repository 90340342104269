import Switch from '@material-ui/core/Switch';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import { USER_TOKEN } from 'config/consts';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import PrimaryButton from '../buttons/primaryButton';
import { FanApi } from './api';
import AlertDialogSlide from './Dialog';
import { VideoButton, VideosContainer } from './styles';
import GeneralTabs from './tabs';
import UploadFile from './UploadFiles';
import UploadLink from './UploadLink';
import VideoCard from './videoCard';

let Upload = function({ input: { onChange, value }, checkVideos }) {
  const [visible, setVisible2] = useState(false);
  const [videosID, setVideosID] = useState([]);
  const [state, setState] = useState(false);
  const [tab, setTab] = useState(0);

  let selectedVideoOK = checkVideos
    ? checkVideos.videos
      ? checkVideos.videos.length > 0
        ? true
        : false
      : false
    : false;

  function onClose() {
    setVisible2(false);
  }
  function handleTab(tab) {
    setTab(tab);
  }

  function handleSwitch(event) {
    setState(event.target.checked);
  }

  async function getVideosID() {
    const response = await FanApi.get(`/videos`, {
      headers: {
        Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
      },
    });
    setVideosID(response.data);
  }

  useEffect(() => {
    getVideosID();
  }, [tab]);

  function renderEmptyScreen() {
    return (
      <section
        id="activeActions"
        style={{ marginTop: '4.3em', overflow: 'hidden' }}
      >
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <div className="pl-4">
                  <h2 className="signup-text">
                    Você ainda não possui vídeos online
                  </h2>
                  <p className="">
                    Para anexar videos a sua atividade volte a aba anterior e
                    faça o upload de um vídeo. Esse vídeo pode demorar até 15
                    minutos para estar disponivel na plataforma.
                  </p>
                </div>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img
              src={placeholder_illustration}
              className="img-fluid"
              alt="Ilustração Cupons"
            />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }

  function renderUpload() {
    return (
      <div style={{ marginTop: 20 }}>
        <Switch
          checked={state}
          onChange={handleSwitch}
          name="checked"
          color="primary"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
        <span style={{ fontFamily: 'Roboto', fontSize: 16, fontWeight: 530 }}>
          Deseja subir link do YouTube?
        </span>
        <br />
        {state ? (
          <UploadLink />
        ) : (
          <div style={{ marginTop: 20 }}>
            <span
              style={{ fontFamily: 'Roboto', fontSize: 16, fontWeight: 530 }}
            >
              O seu vídeo pode demorar até 15 minutos para estar disponível
              depois de feito o upload.
            </span>
            <UploadFile />
          </div>
        )}
      </div>
    );
  }

  function renderVideos() {
    return (
      <>
        <div style={{ marginTop: 20 }}>
          <span style={{ fontFamily: 'Roboto', fontSize: 16, fontWeight: 530 }}>
            Caso alguns vídeos não tenham sido carregados ainda, recarregue a
            página.
          </span>
        </div>
        {videosID.length > 0 ? (
          <VideosContainer>
            {videosID.map((item, index) => (
              <VideoCard
                key={index}
                onChange={onChange}
                value={value}
                custom={item.custom ? item.custom : null}
                name={`video-${index}`}
                url={item.url ? item.url : null}
                id={item.id ? item.id : null}
                checkVideos={checkVideos ? checkVideos : null}
                setTab={setTab}
                tab={tab}
              />
            ))}
          </VideosContainer>
        ) : (
          renderEmptyScreen()
        )}
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
        >
          <PrimaryButton onClick={onClose} type="button">
            Ok
          </PrimaryButton>
        </div>
      </>
    );
  }

  return (
    <>
      <VideoButton
        selected={selectedVideoOK}
        type="button"
        onClick={() => setVisible2(true)}
      >
        {checkVideos
          ? checkVideos.videos
            ? checkVideos.videos.length > 0
              ? 'VÍDEO JÁ SELECIONADO!'
              : 'SELECIONAR/UPLOAD VÍDEO(S)'
            : 'SELECIONAR/UPLOAD VÍDEO(S)'
          : 'SELECIONAR/UPLOAD VÍDEO(S)'}
      </VideoButton>
      <AlertDialogSlide visible={visible} onClose={onClose}>
        <GeneralTabs
          handleTab={handleTab}
          upload={renderUpload()}
          videos={renderVideos()}
        />
      </AlertDialogSlide>
    </>
  );
};

const mapStateToProps = state => {
  return {
    checkVideos: getFormValues('templatesForm')(state),
  };
};

export default connect(mapStateToProps)(Upload);
