import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { bindActionCreators } from 'redux';
import { create_or_update } from 'helpers/formComponent';
import {
  getDetail,
  setDetail,
  initializeForm,
  create,
  update,
  dispatchLoading,
  getPartnersList,
  dispatchFormReset,
  savePartialy,
} from './templatesActions';
import { Box } from '@material-ui/core';
import LoadingProgress from 'common/components/progress/loading';
import _ from 'lodash';
import AlertDialogSlide from 'common/components/dialog/dialogActivities';
import ActionsTabs from 'common/components/actions/actionsTabs';
import Interaction from './sections/interaction';
import Prize from './sections/prize';
import Content from './sections/content';
import Publish from './sections/publish';
import MaterialGrid from '@material-ui/core/Grid';
import { imgToImgType } from 'helpers/utils';
import { getList as getLottiesList } from 'modules/admin/pages/lotties/lottiesActions.js';
import { isMobile } from 'react-device-detect';
function TemplatesForm(props) {
  const [value, setValue] = React.useState(0);
  const [form, setForm] = React.useState(props.formValues);

  useEffect(() => {
    if (props.formValues) {
      setForm(props.formValues);
    }
  }, [props.formValues]);

  async function handleChange(values, router) {
    values.base64 = values.image.base64;
    if (!_.isUndefined(values.image.base64)) {
      values.imageType = imgToImgType(values.image);
    }

    if (!_.isUndefined(values.featuredImage)) {
      if (!_.isUndefined(values.featuredImage.base64)) {
        values.featuredImage.imageType = imgToImgType(values.featuredImage);
      }
    }

    const quizResults = [];
    let counter = 0;
    if (values.type_action_id !== 12) {
      if (values.results) {
        for (let i of values.results.ranges) {
          quizResults.push({
            range: i[1],
            feedback: values[`result-${counter}`],
            feedback_lottie_id: values[`feedback_lottie_id_${counter}`],
          });
          counter += 1;
        }
      }
    }

    values.body = {
      ...values,
      name: values.body_name,
      owner_image: values.owner_image,
      description: values.body_description,
      quizResults: quizResults,
    };

    delete values.body['image'];
    delete values.body['base64'];
    delete values.body['imageType'];
    values.body = JSON.stringify(values.body);

    props.savePartialy(values, router, setValue, value);
  }

  function goBack() {
    setValue(value - 1);
  }

  useEffect(() => {
    props.getPartnersList();
  }, []);

  useEffect(() => {
    props.getLottiesList();
  }, []);

  function handleNativeChange(e, newValue) {
    if (props.router.params.action === 'editar') {
      setValue(newValue);
    } else {
      if (newValue < value) {
        setValue(newValue);
      }
    }
  }

  useEffect(() => {
    if (props.router.params.action === 'editar') {
      props.getDetail(props.router.params.id);
    } else {
      props.dispatchLoading(false);
    }
  }, []);

  function onDialogClose() {
    if (props.router.params.action === 'editar') {
      props.dispatchFormReset();
    }

    if (
      props.router.location.pathname ===
      `/admin/rascunhos/${props.router.params.id}/editar`
    ) {
      props.router.push('/admin/rascunhos');
    } else {
      props.router.push('/admin/modelos');
    }
  }

  function onSubmit(values) {
    const { router, create, update } = props;

    values.base64 = values.image.base64;
    if (!_.isUndefined(values.image.base64)) {
      values.imageType = imgToImgType(values.image);
    }

    if (!_.isUndefined(values.featuredImage)) {
      if (!_.isUndefined(values.featuredImage.base64)) {
        values.featuredImage.imageType = imgToImgType(values.featuredImage);
      }
    }

    const target = {
      age: {
        from: values.from,
        to: values.to,
      },
      states: values.states,
      associated: values.associated,
      internal: values.internal,
    };

    const quizResults = [];
    let counter = 0;
    if (values.type_action_id !== 12) {
      if (values.results) {
        for (let i of values.results.ranges) {
          quizResults.push({
            range: i[1],
            feedback: values[`result-${counter}`],
            feedback_lottie_id: values[`feedback_lottie_id_${counter}`],
          });
          counter += 1;
        }
      }
    }

    values.body = {
      ...values,
      name: values.body_name,
      description: values.body_description,
      target,
      quizResults: quizResults,
    };
    values.target = { ...target };

    values.action_price = values.is_action_paid ? values.action_price : 0;
    values.body.action_price = values.is_action_paid ? values.action_price : 0;
    values.body.action_price_before = values.is_action_paid
      ? values.action_price_before
      : 0;
    values.action_price_before = values.is_action_paid
      ? values.action_price_before
      : 0;
    values.body.is_action_paid = values.is_action_paid;

    delete values.body['image'];
    delete values.body['base64'];
    delete values.body['imageType'];

    values.body = JSON.stringify(values.body);

    create_or_update(router, values, create, update);
  }

  const listPartners = props.templates.listPartners.content.map(
    (item, index) => ({
      value: item.id,
      label: item.name,
    })
  );

  return (
    <div>
      <AlertDialogSlide
        test={true}
        visible={true}
        value={value}
        height="95vh"
        form={form}
        onClose={onDialogClose}
        maxWidth={isMobile ? 'xl' : 'sm'}
      >
        {props.templates.loading && props.lotties.loading ? (
          <Box display="flex" height="100vh" justifyContent="center" mt={10}>
            <LoadingProgress />
          </Box>
        ) : (
          <MaterialGrid
            container
            spacing={2}
            direction="row"
            justify="center"
            alignItems="stretch"
            style={{
              backgroundColor: '#f7f7f7',
              padding: '35px 0 30px 0',
              boxShadow: 'inset 0 0 0 25px #f7f7f7',
            }}
          >
            <MaterialGrid
              style={{ backgroundColor: '#f7f7f7' }}
              sm={10}
              md={10}
              lg={10}
            >
              <ActionsTabs
                styles={{ marginBottom: 35 }}
                handleChange={handleNativeChange}
                activeTab={value}
                goBack={goBack}
                onClickProgress={values => handleChange(values, props.router)}
                content_1={
                  <Content
                    id={'content'}
                    router={props.router}
                    onClickProgress={values =>
                      handleChange(values, props.router)
                    }
                  />
                }
                content_2={
                  <Interaction
                    router={props.router}
                    listPartners={listPartners}
                    onClickProgress={values =>
                      handleChange(values, props.router)
                    }
                    prevClick={goBack}
                  />
                }
                content_3={
                  <Prize
                    router={props.router}
                    onClickProgress={values =>
                      handleChange(values, props.router)
                    }
                    prevClick={goBack}
                  />
                }
                content_4={
                  <Publish
                    router={props.router}
                    onClickProgress={values => onSubmit(values)}
                    prevClick={goBack}
                  />
                }
              />
            </MaterialGrid>
          </MaterialGrid>
        )}
      </AlertDialogSlide>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    templates: state.templates,
    general: state.general,
    lotties: state.lotties,
    formValues: getFormValues('templatesForm')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getDetail,
      setDetail,
      initializeForm,
      create,
      update,
      dispatchLoading,
      getPartnersList,
      dispatchFormReset,
      getLottiesList,
      savePartialy,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplatesForm);
