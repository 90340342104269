/* eslint-disable no-useless-constructor */
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MaterialGrid from '@material-ui/core/Grid';
import React from 'react';
import Activities from '../activities/activity';
import ActivitiesCard from '../cards/moduleCard/activitiesCard';
import Interaction from '../interaction';
import GeneralTabs from '../navigation/actionsTab/tabsActivity';
import Loading from '../progress/loading';

class DialogAction extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate(props) {
    if (props.value !== this.props.value) {
      this.dialogContent.scrollIntoView({
        block: 'start',
      });
    }
  }

  renderActivities() {
    return <Activities formValues={this.props.form} />;
  }

  renderInteraction() {
    return <Interaction formValues={this.props.form} />;
  }

  renderCard() {
    return (
      <ActivitiesCard
        formValues={this.props.form}
        haveImage={true}
        callToAction={this.props.form.click_me_label || 'Participe'}
      />
    );
  }

  render() {
    return (
      <div>
        <Dialog
          open={this.props.visible}
          keepMounted={true}
          PaperProps={{
            style: { minHeight: this.props.height || null },
          }}
          onClose={this.props.onClose}
          fullWidth={true}
          maxWidth={'xl'}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <div
            style={{
              background: '#323232',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                paddingLeft: 30,
                paddingTop: 20,
                paddingBottom: 20,
                fontFamily: 'Lato',
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '32px',
                lineHeight: '38px',
                letterSpacing: '0.25px',
                color: '#FFFFFF',
              }}
            >
              Gerenciador de atividades
            </span>
          </div>
          <div
            ref={node => {
              this.dialogContent = node;
            }}
          >
            <DialogContent
              style={{
                background: '#f7f7f7',
                padding: 0,
                overflow: 'hidden',
              }}
            >
              <MaterialGrid container justify="space-between">
                <MaterialGrid
                  style={{
                    borderRight: '7px solid white',
                  }}
                  item
                  xs={5}
                >
                  {this.props.children}
                </MaterialGrid>
                <MaterialGrid
                  item
                  xs={7}
                  style={{
                    borderLeft: '7px solid white',
                    backgroundColor: '#f7f7f7',
                    boxShadow: 'inset 0 0 0 25px #f7f7f7',
                    padding: 25,
                  }}
                >
                  {this.props.form ? (
                    <GeneralTabs
                      typeAction={this.props.form.type_action_id}
                      activity={this.renderActivities()}
                      card={this.renderCard()}
                      interaction={this.renderInteraction()}
                    />
                  ) : (
                    <Loading />
                  )}
                </MaterialGrid>
              </MaterialGrid>
            </DialogContent>
          </div>
        </Dialog>
      </div>
    );
  }
}

export default DialogAction;
