const INITIAL_STATE = {
  loading: false,
  uploadProgress: '',
  videosList: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'LIST_LOAD':
      return { ...state, loading: action.payload };
    case 'UPLOAD_VIDEO_PROGRESS':
      return { ...state, uploadProgress: action.payload };
    default:
      return state;
  }
};
