import MaterialGrid from '@material-ui/core/Grid';
import Table6 from 'common/components/table/table6';
import React, { useState, useEffect } from 'react';
import { getUserBuyPoints } from '../transactionsActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import LoadingProgress from 'common/components/progress/loading';
import { Box } from '@material-ui/core';

function UserBuyPointsTransactions(props) {
  const [data, setData] = useState([]);

  //   useEffect(()=> {
  //    setData(tableSearch(props.procurar, data));
  //   },[props.procurar])

  // const useStyles = makeStyles(styles);
  // const classes = useStyles();

  useEffect(() => {
    props.getUserBuyPoints();
  }, []);

  useEffect(() => {
    setData(props.transactions.userBuyPointsList);
  }, [props.transactions.userBuyPointsList]);

  const columns = [
    { title: 'Participante:', field: 'user.name' },
    { title: 'Valor:', field: 'points', type: 'currency' },
    { title: 'Data:', field: 'createdAt', type: 'date' },
  ];

  function renderEmptyScreen() {
    return (
      <section
        id="statement-buy-points"
        style={{ marginTop: '4.3em', height: '100vh' }}
      >
        <MaterialGrid container spacing={3} alignItems="center">
          <MaterialGrid item xs={12} md={6}>
            <MaterialGrid container spacing={3}>
              <MaterialGrid item xs={12}>
                <h2 className="signup-text">
                  Você ainda não possui transações efetuadas
                </h2>
              </MaterialGrid>
            </MaterialGrid>
          </MaterialGrid>
          <MaterialGrid item xs={12} md={6}>
            <img
              src={placeholder_illustration}
              className="img-fluid"
              alt="Ilustração: não há dados"
            />
          </MaterialGrid>
        </MaterialGrid>
      </section>
    );
  }

  if (props.transactions.userBuyPointsLoading) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }

  if (props.transactions.userBuyPointsList.length < 1) {
    return renderEmptyScreen();
  }

  return (
    <>
      {/* <MaterialGrid container style={{ marginTop: 15 }}>
        <MaterialGrid item xs={12}>
          <Carousel itemsDisplay={4}>
            {array.map((item, index) => {
              return (
                <Card
                  key={index}
                  style={{ position: "relative", width: 350, marginRight: 24, cursor: "pointer" }}
                >
                  <CardHeader color="warning" stats icon>
                  <img
                      src={image}
                      alt=""
                      style={{
                        height: 90,
                        width: 100,
                        float: "left",
                        marginTop: -20,
                        borderRadius: 6,
                        backgroundColor: "#f7f7f7",
                        boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)"

                      }}
                    />
                    <p className={classes.cardCategory}>{item}</p>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-end"
                      style={{ marginTop: 10 }}
                    >
                      <Typography style={{ display: "flex", fontSize: 14 }}>
                        Ações compradas:{"  "}
                        <Typography style={{ fontSize: 14 }}>{"  "}100</Typography>
                      </Typography>

                      <Typography style={{ display: "flex", fontSize: 14 }}>
                        Valor transacionado:{"  "}
                        <Typography style={{ fontSize: 14 }}>
                        {"  "}R$15.450,00
                        </Typography>
                      </Typography>
                    </Box>
                  </CardHeader>
                </Card>
              );
            })}
          </Carousel>
        </MaterialGrid>
      </MaterialGrid> */}
      <MaterialGrid container>
        <MaterialGrid item xs={12}>
          <Table6 title="TransferRequests" columns={columns} data={data} />
        </MaterialGrid>
      </MaterialGrid>
    </>
  );
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    transactions: state.transactions,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getUserBuyPoints,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserBuyPointsTransactions);
