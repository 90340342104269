const INITIAL_STATE = {
  buyActionLoading: false,
  buyActionList: [],
  earnPointsLoading: false,
  earnPointsList: [],
  usePointsLoading: false,
  usePointsList: [],
  userBuyPointsLoading: false,
  userBuyPointsList: [],
  establishmentLoading: false,
  establishmentList: [],
};

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'BUY_ACTION_TRANSACTION_LOAD':
      return { ...state, buyActionLoading: action.payload };
    case 'BUY_ACTION_TRANSACTION_FETCHED':
      return {
        ...state,
        buyActionList: action.payload,
        buyActionLoading: false,
      };
    case 'EARN_POINTS_TRANSACTIONS_LOAD':
      return { ...state, earnPointsLoading: action.payload };
    case 'EARN_POINTS_TRANSACTIONS_FETCHED':
      return {
        ...state,
        earnPointsList: action.payload,
        earnPointsLoading: false,
      };
    case 'USE_POINTS_LOAD':
      return { ...state, usePointsLoading: action.payload };
    case 'USE_POINTS_FETCHED':
      return {
        ...state,
        usePointsList: action.payload,
        usePointsLoading: false,
      };
    case 'USER_BUY_POINTS_LOAD':
      return { ...state, userBuyPointsLoading: action.payload };
    case 'USER_BUY_POINTS_FETCHED':
      return {
        ...state,
        userBuyPointsList: action.payload,
        userBuyPointsLoading: false,
      };
    case 'ESTABLISHMENT_LOAD':
      return { ...state, establishmentLoading: action.payload };
    case 'ESTABLISHMENT_FETCHED':
      return {
        ...state,
        establishmentList: action.payload,
        establishmentLoading: false,
      };
    default:
      return state;
  }
};
