import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import _ from 'lodash';
import moment from 'moment';
import { initialize, reset as resetForm } from 'redux-form';
import type from './types';

const URL = `${BASE_API}/transfer-request`;
const URL_BUY_POINTS = `${BASE_API}/buy-points-transaction`;
const URL_USER_BUY_POINTS = `${BASE_API}/user-buy-points-transaction`;

export const getList = id => {
  return dispatch => {
    dispatch({ type: type.LIST_LOAD, payload: true });
    axios
      .get(`${URL}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        for (let i in response.data) {
          response.data[i]['createdAt'] = moment(
            response.data[i].createdAt
          ).format('DD/MM/YYYY HH:MM');
          if (response.data[i].establishment) {
            response.data[i]['stationName'] =
              response.data[i].establishment.name;
          } else {
            response.data[i]['stationName'] = 'N/A';
          }

          response.data[i]['points'] = parseFloat(
            response.data[i].points
          ).toFixed(2);
        }
        dispatch([
          {
            type: type.LIST_FETCHED,
            payload: response,
          },
        ]);
      });
  };
};

export const getListById = id => {
  return dispatch => {
    dispatch({ type: type.LIST_LOAD, payload: true });
    axios
      .get(`${URL}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        const initialValues = {
          ...response.data,
          json: JSON.parse(response.data.json),
        };
        dispatch([
          {
            type: type.ITEM_DETAIL,
            payload: { data: initialValues },
          },
        ]);
      });
  };
};

export const remove = (id, resolve, reject, other = undefined) => {
  return dispatch => {
    dispatch({ type: type.LIST_LOAD, payload: false });
    axios
      .delete(`${URL}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        dispatch([
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
          {
            type: type.LIST_LOAD,
            payload: false,
          },

          resolve(),
          getList(other),
        ]);
      })
      .catch(e => {
        try {
          dispatch({
            type: 'SNACKBAR',
            payload: {
              message: `Erro: ${e.response.data.message}`,
              variant: 'error',
              open: true,
            },
          });
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
        reject();
      });
  };
};

export const getDetail = id => {
  return dispatch => {
    axios
      .get(`${URL}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        const initialValues = {
          ...response.data[0],
        };
        dispatch([
          {
            type: type.ITEM_DETAIL,
            payload: response,
          },
          initializeForm(initialValues),
        ]);
      });
  };
};

export const setDetail = values => {
  return dispatch => {
    const initialValues = {
      ...values,
    };
    dispatch([
      {
        type: type.ITEM_DETAIL,
        payload: { data: values },
      },
      initializeForm(initialValues),
    ]);
  };
};

export const initializeForm = values => {
  return initialize('LottiesForm', values);
};

export const reset = () => {
  return resetForm('LottiesForm');
};

export const create = (values, router, firstTime) => {
  return submit(values, 'post', router, firstTime);
};

export const update = (values, router) => {
  return submit(values, 'put', router);
};

function submit(values, method, router = undefined, firstTime) {
  const id = values.id ? `${values.id}/` : '';
  return dispatch => {
    dispatch([
      { type: type.BUTTON_LOAD, payload: true },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
    ]);
    axios[method](`${URL}/${id}`, values, {
      headers: {
        Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
      },
      onUploadProgress: progressEvent =>
        dispatch([
          {
            type: 'SUBMIT_PROGRESS',
            payload: `${Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            )} %`,
          },
        ]),
    })
      .then(response => {
        /* dispatch([reset(), getList()]); */
        dispatch([
          { type: type.BUTTON_LOAD, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
        ]);

        if (!_.isUndefined(router)) {
          if (firstTime) {
            router.push('/admin');
          } else {
            router.push('/admin/lotties');
          }
        }
      })
      .catch(e => {
        dispatch([
          { type: type.BUTTON_LOAD, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
        ]);

        try {
          for (const i in e.response.data) {
            for (const j in e.response.data[i]) {
              dispatch({
                type: 'SNACKBAR',
                payload: {
                  message: `${i}: ${e.response.data.errors[i][j]}`,
                  variant: 'error',
                  open: true,
                },
              });
            }
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
}

export const setLoading = bool => {
  return dispatch => {
    dispatch({
      type: type.LIST_LOAD,
      payload: false,
    });
  };
};

export const fileRejected = () => {
  return dispatch => {
    dispatch({
      type: 'SNACKBAR',
      payload: {
        message: 'Erro: Imagem maior que 2MB',
        variant: 'error',
        open: true,
      },
    });
  };
};

export const buyPoints = (values, router = undefined) => {
  return dispatch => {
    dispatch([
      { type: 'OWNER_POINTS_BUTTON_LOAD', payload: true },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
    ]);
    axios
      .post(URL_BUY_POINTS, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
        onUploadProgress: progressEvent =>
          dispatch([
            {
              type: 'SUBMIT_PROGRESS',
              payload: `${Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              )} %`,
            },
          ]),
      })
      .then(response => {
        window.location.reload();
        setTimeout(() => {
          dispatch([
            { type: 'OWNER_POINTS_BUTTON_LOAD', payload: false },
            {
              type: 'SUBMIT_PROGRESS',
              payload: 0,
            },
          ]);
        }, 2000);
      })
      .catch(e => {
        dispatch([
          { type: 'OWNER_POINTS_BUTTON_LOAD', payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
        ]);
        try {
          if (e.response.data[0]) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `Erro: ${e.response.data[0]}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const userBuyPoints = (values, router = undefined) => {
  return dispatch => {
    dispatch([
      { type: 'OWNER_POINTS_BUTTON_LOAD', payload: true },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
    ]);
    axios
      .post(URL_USER_BUY_POINTS, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
        onUploadProgress: progressEvent =>
          dispatch([
            {
              type: 'SUBMIT_PROGRESS',
              payload: `${Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              )} %`,
            },
          ]),
      })
      .then(response => {
        window.location.reload();
        setTimeout(() => {
          dispatch([
            { type: 'OWNER_POINTS_BUTTON_LOAD', payload: false },
            {
              type: 'SUBMIT_PROGRESS',
              payload: 0,
            },
          ]);
        }, 2000);
      })
      .catch(e => {
        dispatch([
          { type: 'OWNER_POINTS_BUTTON_LOAD', payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
        ]);
        try {
          if (e.response.data[0]) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `Erro: ${e.response.data[0]}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};
