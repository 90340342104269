import { Box } from '@material-ui/core';
import MaterialGrid from '@material-ui/core/Grid';
import PrimaryButton from 'common/components/buttons/primaryButton';
import {
  InputFile,
  InputLabelOutlined,
  InputSwitch,
} from 'common/components/fields/fields';
import LoadingProgress from 'common/components/progress/loading';
import { create_or_update } from 'helpers/formComponent';
import { imgToBase64, imgToImgType } from 'helpers/utils';
import { FORM_RULES } from 'helpers/validations';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import {
  create,
  fileRejected,
  getDetail,
  initializeForm,
  setDetail,
  setLoading,
  update,
} from './lottiesActions';

let LottiesForm = function(props) {
  const { router } = props;

  useEffect(() => {
    if (!router.params.id) {
      props.setLoading(false);
    } else {
      props.getDetail(router.params.id);
    }
  }, []);

  function onSubmit(values) {
    const { create, update } = props;

    const newValues = {
      ...values,
    };

    let firstTime = false;

    if (props.location.query.first_action) {
      firstTime = true;
    }

    if (!_.isUndefined(newValues.url.base64)) {
      newValues.base64 = imgToBase64(newValues.url);
      newValues.type = imgToImgType(newValues.url);
      delete newValues['url'];
    }

    create_or_update(router, newValues, create, update, firstTime);
  }

  return props.lotties.loading ? (
    <Box display="flex" height="100vh" justifyContent="center" mt={10}>
      <LoadingProgress />
    </Box>
  ) : (
    <section id="lotties-form" style={{ marginTop: '4.3em' }}>
      <div className="card">
        <div className="card-body">
          <p className=" h4 card-title text-muted">Formulário</p>
          <Form role="form" onSubmit={props.handleSubmit(onSubmit)} noValidate>
            <MaterialGrid
              container
              spacing={3}
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <MaterialGrid item xs={12} md={6} lg={4}>
                <Field
                  component={InputLabelOutlined}
                  label="Nome"
                  name="name"
                  validate={[FORM_RULES.required]}
                  required
                  margin="normal"
                />
              </MaterialGrid>
            </MaterialGrid>
            <MaterialGrid
              container
              spacing={3}
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <MaterialGrid item xs={12} md={6} lg={4}>
                <Field
                  component={InputFile}
                  label="Arquivo"
                  lottie
                  accept="application/json"
                  placeholder="Clique ou arraste seu arquivo aqui (.json)"
                  name="url"
                  validate={[FORM_RULES.required]}
                />
              </MaterialGrid>
            </MaterialGrid>
            <MaterialGrid item xs={12} md={6} lg={4}>
              <Field
                component={InputSwitch}
                label={'O Lottie é para situacão de sucesso?'}
                name="success"
              />
            </MaterialGrid>
            <PrimaryButton
              type="submit"
              disabled={props.lotties.buttonLoading}
              progress={parseInt(props.general.submitProgress)}
            >
              {props.lotties.buttonLoading ? 'Enviando' : 'Enviar'}
            </PrimaryButton>
          </Form>
        </div>
      </div>
    </section>
  );
};

LottiesForm = reduxForm({ form: 'LottiesForm' })(LottiesForm);

const mapStateToProps = state => {
  return {
    auth: state.auth,
    lotties: state.lotties,
    formValues: getFormValues('LottiesForm')(state),
    general: state.general,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getDetail,
      setDetail,
      initializeForm,
      create,
      update,
      setLoading,
      fileRejected,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LottiesForm);
