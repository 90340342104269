import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';

import { Container, ContainerButton, AgreementContainer } from './styles';

export default function ActivityButton(props) {
  const [state, setState] = React.useState({
    checkbox1: false,
    checkbox2: true,
    checkbox3: false,
  });

  const handleChange = name => event => {
    setState({ ...state, [name]: event.target.checked });
  };

  return (
    <>
      {props.presential_course ? (
        <Container
          color={props.color}
          state={!state.checkbox1}
          presential_course={props.presential_course}
        >
          <div>
            {props.presential_course ? (
              <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    style={{ color: 'black' }}
                    value="checkbox1"
                    control={
                      <Checkbox checked={state.checkbox1} color="default" />
                    }
                    label="Aceito compartilhar os meus dados com o parceiro/gestor."
                    labelPlacement="end"
                    onChange={handleChange('checkbox1')}
                  />
                  <FormControlLabel
                    style={{ color: 'black' }}
                    value="checkbox2"
                    control={
                      <Checkbox checked={state.checkbox2} color="default" />
                    }
                    label="Quero que o parceiro/gestor entre em contato comigo."
                    labelPlacement="end"
                    onChange={handleChange('checkbox2')}
                  />
                </FormGroup>
              </FormControl>
            ) : null}
          </div>

          <button
            style={{ marginTop: 10 }}
            disabled={!state.checkbox1 && props.presential_course}
          >
            {props.label}
          </button>
        </Container>
      ) : props.agreement ? (
        <AgreementContainer
          color={props.color}
          state={!state.checkbox3}
          presential_course={props.agreement}
        >
          <FormControlLabel
            style={{ color: 'black', margin: '0px 0px 0px -11px' }}
            value="checkbox1"
            control={<Checkbox color="default" />}
            label={props.checkbox_text}
            labelPlacement="end"
            onChange={handleChange('checkbox3')}
          />
          <button
            style={{ marginTop: 10, fontFamily: 'Roboto' }}
            disabled={!state.checkbox3 && props.agreement}
          >
            {props.label}
          </button>
        </AgreementContainer>
      ) : (
        <>
          <ContainerButton color={props.color}>
            <button>{props.label}</button>
          </ContainerButton>
        </>
      )}
    </>
  );
}
