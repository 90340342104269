import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@material-ui/core';
import Answers from 'common/components/quiz/Answers';
import React from 'react';
import { ExpansionList } from 'react-md';
import { Field, FieldArray } from 'redux-form';
import PrimaryButton from 'common/components/buttons/primaryButton';
import Question from 'common/components/quiz/Questions';
import { AnswersContainer, Container } from './styles';

export default function TemplateCheckListQuiz(props) {
  const renderOptions = props => (
    <ul style={{ listStyleType: 'none' }}>
      <li>
        <Box style={{ margin: '12px 0' }}>
          <PrimaryButton
            style={{ margin: 0 }}
            onClick={() => props.fields.push()}
          >
            Adicionar opção
          </PrimaryButton>
        </Box>
      </li>
      <AnswersContainer>
        {props.fields.map((option, index) => (
          <li key={index}>
            <Box display="flex" alignContent="center">
              <Field
                component={Answers}
                label={`Opção ${index + 1}`}
                name={`${option}.label`}
              />

              <FontAwesomeIcon
                style={{
                  cursor: 'pointer',
                  margin: 'auto 0 auto 8px',
                  fontSize: 16,
                }}
                icon={faTrash}
                onClick={() => props.fields.remove(index)}
              />
            </Box>
          </li>
        ))}
      </AnswersContainer>
    </ul>
  );

  return (
    <>
      <Container>
        <Field name="question" component={Question} />
        <ExpansionList>
          {
            <FieldArray
              name="options"
              component={renderOptions}
              formValues={props.formValues}
            />
          }
        </ExpansionList>
      </Container>
    </>
  );
}
