import Home from '@material-ui/icons/Home';
import Layers from '@material-ui/icons/Layers';
// Imports de Ícones do Material
import LooksIcon from '@material-ui/icons/Looks';
import Money from '@material-ui/icons/Money';
import Pages from '@material-ui/icons/Pages';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import axios from 'axios';
import { USER_TOKEN } from 'config/consts';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Admin from '../modules/admin/main/admin';
import { NotAuthorized } from '../pages/401/401';
import { validateToken } from '../pages/auth/authActions';
// import App from './app';
import Login from '../pages/auth/login/login';

const navBarItems = [
  {
    id: 0,
    name: 'Dashboard',
    action: '',
    icon: <Home style={{ fontSize: 24 }} />,
    link: '/admin',
  },
  {
    id: 1,
    name: 'Atividades',
    action: '',
    icon: <Layers style={{ fontSize: 24 }} />,
    link: '/admin/modelos',
    children: [
      {
        name: 'Modelos',
        link: '/admin/modelos',
        id: 0,
      },
      {
        name: 'Rascunhos',
        link: '/admin/rascunhos',
        id: 1,
      },
      {
        name: 'Criar modelo',
        link: '/admin/rascunhos/novo',
        id: 2,
      },
    ],
  },
  /*  {
    id: 2,
    name: 'Categorias',
    link: '/admin/categorias',
    icon: <List style={{ fontSize: 24 }} />,
    activeName: 'pnl',
  }, */
  {
    id: 2,
    name: 'Usuários',
    link: '/admin/gestores',
    icon: <SupervisorAccountIcon style={{ fontSize: 26 }} />,
    activeName: 'pnl',
    children: [
      {
        name: 'Gestores',
        link: '/admin/gestores',
        id: 0,
      },
      {
        name: 'Parceiros',
        link: '/admin/parceiros',
        id: 1,
      },
      {
        name: 'Participantes',
        link: '/admin/participantes',
        id: 2,
      },
    ],
  },
  {
    id: 3,
    name: 'Animações',
    link: '/admin/lotties',
    icon: <LooksIcon style={{ fontSize: 24 }} />,
    activeName: 'pnl',
  },
  {
    id: 4,
    name: 'Financeiro',
    link: '/admin/transacoes',
    icon: <Money style={{ fontSize: 24 }} />,
    activeName: 'pnl',
    children: [
      {
        name: 'Transações',
        link: '/admin/transacoes',
        id: 0,
      },
      {
        name: 'Chamados',
        link: '/admin/chamados',
        id: 1,
      },
    ],
  },
  {
    id: 5,
    name: 'Termos de Uso',
    link: '/admin/termos-de-uso',
    icon: <Pages style={{ fontSize: 24 }} />,
    activeName: 'pnl',
  },
];

class AuthOrApp extends Component {
  constructor(props) {
    super(props);
    console.disableYellowBox = true;
    axios.defaults.headers.common['Accept'] = 'application/json';
  }

  componentDidMount() {
    const { user } = this.props.auth;
    if (user) {
      this.props.validateToken(
        JSON.parse(localStorage.getItem(USER_TOKEN)),
        user.id
      );
    }
  }

  isAuthenticated() { }

  render() {
    const { user, validToken } = this.props.auth;
    let prefix = this.props.router.location.pathname.split('/');

    if (!user && !validToken) {
      return <Login>{this.props.children}</Login>;
    } else if (_.isNull(user.establishment_user)) {
      return <NotAuthorized />;
    }

    if (prefix.length > 1) {
      prefix = prefix[1];
    } else {
      prefix = prefix[0];
    }
    if (prefix === '') {
      return this.props.children;
    } else if (prefix === 'admin') {
      return (
        <Admin router={this.props.router} navBarItems={navBarItems}>
          {this.props.children}
        </Admin>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    general: state.general,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ validateToken }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthOrApp);
