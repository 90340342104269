import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import _ from 'lodash';
import { destroy, initialize } from 'redux-form';
import type from './types';
import typeDrafts from './typesDrafts';

const URL = `${BASE_API}/action-templates`;
const URL_ADMIN = `${BASE_API}/action-templates-admin`;
const URL_SET_ACTION_ACTIVE = `${BASE_API}/publish-action-templates`;
const URL_CREATE_ACTION = `${BASE_API}/draft-action-templates`;
const URL_PARTNERS = `${BASE_API}/establishments`;
const URL_GIVEAWAYS = `${BASE_API}/giveaways`;
const UPLOAD_IMAGE = `${BASE_API}/upload-image-to-s3`;

export function getList() {
  return dispatch => {
    dispatch({
      type: type.LIST_LOAD,
      payload: true,
    });
    axios
      .get(`${URL_ADMIN}/?where[is_draft]=0`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        dispatch({
          type: type.LIST_FETCHED,
          payload: response,
        });
      });
  };
}

export const getListPage = page => {
  const request = axios.get(`${URL}?page=${page}`, {
    headers: {
      Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
    },
  });
  return dispatch => {
    dispatch([
      {
        type: type.LIST_LOAD,
        payload: true,
      },
      {
        type: type.LIST_FETCHED,
        payload: request,
      },
    ]);
  };
};

export const getListLimit = number => {
  const request = axios.get(`${URL}?limit=${number}`, {
    headers: {
      Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
    },
  });
  return dispatch => {
    dispatch([
      {
        type: type.LIST_LOAD,
        payload: true,
      },
      {
        type: type.LIST_FETCHED,
        payload: request,
      },
    ]);
  };
};

export const remove = (id, resolve, reject, other = undefined) => {
  //console.log(id, "entrou");
  return dispatch => {
    //console.log(id, "dispatch", reject, resolve);
    dispatch({ type: type.LIST_LOAD, payload: false });
    axios
      .delete(`${URL}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        dispatch([
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
          {
            type: type.LIST_LOAD,
            payload: false,
          },
          resolve(),
          getList(),
          getListDrafts(),
        ]);
      })
      .catch(e => {
        try {
          for (const i in e.response.data) {
            for (const j in e.response.data[i]) {
              dispatch({
                type: 'SNACKBAR',
                payload: {
                  message: `${i}: ${e.response.data.errors[i][j]}`,
                  variant: 'error',
                  open: true,
                },
              });
            }
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const getDetail = id => {
  return dispatch => {
    dispatch({
      type: type.LIST_LOAD,
      payload: true,
    });
    axios
      .get(`${URL_ADMIN}/?where[id]=${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        const body = JSON.parse(response.data[0].body);
        const initialValues = {
          ...body,
          ...response.data[0],
          body_name: body ? (body.name ? body.name : null) : null,
          body_description: body
            ? body.description
              ? body.description
              : null
            : null,
          is_action_paid: body
            ? parseFloat(body.action_price) > 0
              ? true
              : false
            : false,
        };
        if (body) {
          if (body.target) {
            body.target.from = body.target.age.from;
            body.target.to = body.target.age.to;
          }
        }

        delete initialValues['body'];

        //Sets money fields
        if (initialValues.type_action_id === 4) {
          if (
            parseFloat(initialValues.price) > 0 ||
            parseFloat(initialValues.discount_price) > 0
          ) {
            initialValues.prices = true;
          } else {
            initialValues.prices = false;
          }

          //cupoms
        } else if (initialValues.type_action_id === 3) {
          if (
            parseFloat(initialValues.price) > 0 ||
            parseFloat(initialValues.newPrice) > 0
          ) {
            initialValues.prices = true;
          } else {
            initialValues.prices = false;
          }
        }
        //mgm
        else if (initialValues.type_action_id === 1) {
          if (parseFloat(initialValues.points_inviter) === 0) {
            initialValues.isInviterPaid = false;
          } else {
            initialValues.isInviterPaid = true;
          }
          if (parseFloat(initialValues.points_invited) === 0) {
            initialValues.isInvitedPaid = false;
          } else {
            initialValues.isInvitedPaid = true;
          }
          //signupandearn
        } else if (initialValues.type_action_id === 2) {
        }

        //Checks if activity is paid (does not include MGM)
        if (initialValues.type_action_id !== 1) {
          if (parseFloat(initialValues.points) === 0) {
            initialValues.isPaid = false;
          } else {
            initialValues.isPaid = true;
          }
        }
        dispatch([
          {
            type: type.ITEM_DETAIL,
            payload: initialValues,
          },
          initializeForm(initialValues),
        ]);
      });
  };
};

export const setDetail = values => {
  return dispatch => {
    dispatch({
      type: type.LIST_LOAD,
      payload: true,
    });
    const initialValues = {
      ...values,
    };
    dispatch([
      {
        type: type.ITEM_DETAIL,
        payload: { data: values },
      },
      initializeForm(initialValues),
      reset(),
      getList(),
    ]);
  };
};

export const initializeForm = values => {
  return initialize('templatesForm', values);
};

export const reset = () => {
  return initialize('templatesForm', {});
};

export const create = (values, router, firstTime) => {
  return submit(values, 'post', router, firstTime);
};

export const update = (values, router) => {
  return submit(values, 'put', router);
};

function submit(values, method, router = undefined, firstTime) {
  const id = values.id ? `${values.id}/` : '';
  return dispatch => {
    dispatch([
      { type: type.BUTTON_LOAD, payload: true },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
    ]);
    axios[method](`${URL}/${id}`, values, {
      headers: {
        Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
      },
      onUploadProgress: progressEvent =>
        dispatch([
          {
            type: 'SUBMIT_PROGRESS',
            payload: `${Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            )} %`,
          },
        ]),
    })
      .then(response => {
        dispatch([
          { type: type.BUTTON_LOAD, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
          reset(),
        ]);

        axios
          .put(
            `${URL_SET_ACTION_ACTIVE}/${id}`,
            {},
            {
              headers: {
                Authorization: `${JSON.parse(
                  localStorage.getItem(USER_TOKEN)
                )}`,
              },
            }
          )
          .then(responseUpdate => {
            if (!_.isUndefined(router)) {
              if (firstTime) {
                router.push('/admin');
              } else {
                router.push('/admin/modelos');
              }
              dispatch(reset());
            }
          })
          .catch(e => {
            dispatch([
              { type: type.BUTTON_LOAD, payload: false },
              {
                type: 'SUBMIT_PROGRESS',
                payload: 0,
              },
            ]);

            try {
              for (const i in e.response.data) {
                for (const j in e.response.data[i]) {
                  dispatch({
                    type: 'SNACKBAR',
                    payload: {
                      message: `${i}: ${e.response.data.errors[i][j]}`,
                      variant: 'error',
                      open: true,
                    },
                  });
                }
              }
            } catch (error) {
              dispatch({
                type: 'SNACKBAR',
                payload: { message: 'Erro', variant: 'error', open: true },
              });
            }
          });
      })
      .catch(e => {
        dispatch([
          { type: type.BUTTON_LOAD, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
        ]);

        try {
          for (const i in e.response.data) {
            for (const j in e.response.data[i]) {
              dispatch({
                type: 'SNACKBAR',
                payload: {
                  message: `${i}: ${e.response.data.errors[i][j]}`,
                  variant: 'error',
                  open: true,
                },
              });
            }
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
}

export const getPartnersList = () => {
  return dispatch => {
    dispatch({
      type: 'PARTNERS_LOAD',
      payload: true,
    });
    axios
      .get(`${URL_PARTNERS}/?where[type_id]=4`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        dispatch({
          type: 'PARTNERS_FETCHED',
          payload: response,
        });
      });
  };
};

export const getAllGiveaways = () => {
  return dispatch => {
    dispatch({
      type: 'GIVEAWAYS_LOAD',
      payload: true,
    });
    axios
      .get(`${URL_GIVEAWAYS}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        dispatch({
          type: 'GIVEAWAYS_FETCHED',
          payload: response,
        });
      });
  };
};

export const dispatchLoading = bool => {
  return dispatch => {
    dispatch({
      type: type.LIST_LOAD,
      payload: bool,
    });
  };
};

export const dispatchFormReset = () => {
  return dispatch => {
    dispatch(destroy('templatesForm'));
  };
};

// Actions of templatesDrafts

export function getListDrafts() {
  return dispatch => {
    dispatch({
      type: typeDrafts.LIST_LOAD,
      payload: true,
    });
    axios
      .get(`${URL_ADMIN}/?where[is_draft]=1`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        for (let index in response.data) {
          response.data[index]['display_name'] = response.data[index].name
            ? response.data[index].name
            : `Rascunho ${response.data[index].id}`;
          response.data[index]['display_description'] = response.data[index]
            .description
            ? response.data[index].description
            : `Descrição do rascunho ${response.data[index].id}`;
          response.data[index]['display_type'] = response.data[index]
            .type_action_id
            ? response.data[index].type.name
            : `Tipo ainda indefinido`;
        }
        dispatch({
          type: typeDrafts.LIST_FETCHED,
          payload: response,
        });
      });
  };
}

export function uploadImageToS3(base64) {
  return axios
    .post(
      `${UPLOAD_IMAGE}`,
      { base64 },
      {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      }
    )
    .then(response => {
      return response.data.url;
    });
}

export function createNewAction(router) {
  return axios
    .post(
      `${URL_CREATE_ACTION}`,
      {},
      {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      }
    )
    .then(response => {
      router.push(`/admin/rascunhos/${response.data.id}/editar`);
    });
}

export const savePartialy = (values, router, setValue, value) => {
  const id = router.params.id;
  return dispatch => {
    dispatch([
      { type: typeDrafts.BUTTON_LOAD, payload: true },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
    ]);

    axios
      .put(`${URL}/${id}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
        onUploadProgress: progressEvent =>
          dispatch([
            {
              type: 'SUBMIT_PROGRESS',
              payload: `${Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              )} %`,
            },
          ]),
      })
      .then(response => {
        dispatch([
          { type: typeDrafts.BUTTON_LOAD, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
          setValue(value + 1),
          getDetail(id),
        ]);
      })
      .catch(e => {
        dispatch([
          { type: typeDrafts.BUTTON_LOAD, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
        ]);

        try {
          for (const i in e.response.data) {
            for (const j in e.response.data[i]) {
              dispatch({
                type: 'SNACKBAR',
                payload: {
                  message: `${i}: ${e.response.data.errors[i][j]}`,
                  variant: 'error',
                  open: true,
                },
              });
            }
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const showToastr = ({ message, variant, open }) => {
  return dispatch => {
    dispatch({
      type: 'SNACKBAR',
      payload: { message, variant, open },
    });
  };
};
