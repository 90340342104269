import { Box, Typography } from '@material-ui/core';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import {
  InputLabelOutlined,
  SelectLabelOutlined,
} from 'common/components/fields/fields';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import Table6 from 'common/components/table/table6';
import { tableSearch } from 'helpers/utils';
import { flatten } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getManagersList } from '../managers/managersActions';
import { getParticipantsList, activateUser } from './participantsActions';

export function Participants(props) {
  const { router } = props;
  const { list, loading } = props.participants;

  const [data, setData] = useState([]);
  const [value, setValue] = useState('');
  const [selectedStation, setSelectedStation] = useState(null);

  const selectedManager = useMemo(() => {
    return props.managers.list.content.find((item) => {
      for (let i = 0; i < item.establishments.length; i++) {
        if (item.establishments[i].stations[0].id === selectedStation) {
          return item.establishments[i];
        }
      }
      return false;
    });
  }, [selectedStation, props.managers.list.content.length]);

  useEffect(() => {
    props.getManagersList();
  }, []);

  useEffect(() => {
    if (selectedStation) {
      props.getParticipantsList(selectedStation);
    }
  }, [selectedStation]);

  useEffect(() => {
    setData(props.participants.list.content);
  }, [props.participants.list.content]);

  function handleChange(e) {
    setData(tableSearch(e, props.participants.list.content));
    setValue(e.target.value);
  }
  const options = flatten(
    props.managers.list.content
      .filter((item) => item.establishments[0].stations[0])
      .map((item) =>
        item.establishments.map((establishment) => ({
          value: establishment.stations[0].id,
          label: establishment.stations[0].name,
        }))
      )
  );

  console.log(options);

  function renderEmptyScreen() {
    return (
      <section
        id="statement-buy-points"
        style={{ marginTop: '4.3em', height: '100vh' }}
      >
        <div>
          <SelectLabelOutlined
            input={{
              onChange: (e) => {
                setSelectedStation(e.target.value);
              },
              value: selectedStation,
            }}
            style={{ width: '100%' }}
            label={`Lista de Estações`}
            name={`stationsList`}
            margin="normal"
            options={options}
            value={selectedStation}
            meta={{ touched: null, error: null }}
          />
        </div>
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <h2 className="signup-text">Nenhum registro foi encontrado</h2>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img
              src={placeholder_illustration}
              className="img-fluid"
              alt="Ilustração: não há dados"
            />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }

  if (props.managers.loading || (selectedStation && loading)) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }
  if (list.content.length < 1) {
    return renderEmptyScreen();
  }
  return (
    <section id="participants" style={{ marginTop: '4.3em' }}>
      <div>
        <SelectLabelOutlined
          input={{
            onChange: (e) => setSelectedStation(e.target.value),
            value: selectedStation,
          }}
          style={{ width: '100%' }}
          label={`Lista de Estações`}
          name={`stationsList`}
          margin="normal"
          options={options}
          value={selectedStation}
          meta={{ touched: null, error: null }}
        />
      </div>
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12}>
          <GeneralGrid
            container
            spacing={3}
            justify="space-between"
            alignContent="space-between"
          >
            <GeneralGrid item xs={3}>
              <span
                style={{
                  fontFamily: 'Roboto',
                  fontWeight: 500,
                  fontSize: 24,
                }}
              >
                Lista de Participantes
              </span>
              <Typography variant="body1" style={{ marginTop: 20 }}>
                {props.participants.list.content.length} Participantes
              </Typography>
            </GeneralGrid>
            <GeneralGrid item xs={3}>
              <InputLabelOutlined
                input={{ onChange: handleChange, value: value }}
                meta={{}}
                onChange={handleChange}
                value={value}
                label="Procurar"
                name="search"
                margin="normal"
              />
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid container spacing={3}>
            <GeneralGrid item xs={12}>
              <Table6
                title="Participantes"
                columns={[
                  { field: 'name', title: 'Nome' },
                  { field: 'email', title: 'E-mail' },
                  {
                    title: 'Status',
                    render: (rowData) =>
                      rowData.deletedAt ? (
                        'Suspenso'
                      ) : rowData.active ? (
                        'E-mail Ativo'
                      ) : (
                        <div>
                          Email inativo{' '}
                          <span
                            onClick={() => {
                              props.activateUser({
                                email: rowData.email,
                                hash: rowData.hash,
                                station: rowData.station_id,
                                establishment:
                                  selectedManager.establishments.find(
                                    (item) =>
                                      item.stations[0].id === selectedStation
                                  ),
                              });
                            }}
                            style={{ cursor: 'pointer', color: '#2D95EF' }}
                          >
                            (Ativar e-mail)
                          </span>
                        </div>
                      ),
                  },
                  {
                    field: 'createdAt',
                    title: 'Data de Cadastro',
                    render: (rowData) =>
                      moment(rowData['createdAt']).format('DD/MM/YYYY HH:mm'),
                  },
                ]}
                data={data}
                router={router}
              />
            </GeneralGrid>
          </GeneralGrid>
        </GeneralGrid>
      </GeneralGrid>
    </section>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    participants: state.participants,
    managers: state.managers,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getParticipantsList,
      getManagersList,
      activateUser,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Participants);
