import MaterialGrid from '@material-ui/core/Grid';
import {
  InputLabelOutlined,
  SelectLabelOutlined,
} from 'common/components/fields/fields';
import React, { Component } from 'react';
import BuyActionTransactions from './buyActionTransactions/buyActionTransactions';
import EarnPointsTransactions from './earnPointsTransactions/earnPointsTransactions';
import UserBuyPointsTransactions from './userBuyPointsTransactions/userBuyPointsTransactions';

class Transactions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      procurar: '',
      select: '',
    };
  }

  componentDidMount() {
    this.setState({ select: 0 });
  }

  handleSelect = e => {
    this.setState({ select: e.target.value });
  };

  handleChange = e => {
    this.setState({ procurar: e.target.value });
  };

  render() {
    const { procurar, select } = this.state;
    const options = [
      { value: 0, label: 'Compra de ações pelos gestores' },
      { value: 1, label: 'Pontos ganhos pelos participantes' },
      { value: 2, label: 'Recargas dos participantes' },
    ];

    return (
      <>
        <MaterialGrid container style={{ marginBottom: 16 }}>
          <MaterialGrid item xs={12}>
            <MaterialGrid container justify="space-between">
              <MaterialGrid item xs={4}>
                <SelectLabelOutlined
                  input={{ onChange: this.handleSelect, value: select }}
                  style={{ width: '100%' }}
                  label={`Lista de transações`}
                  name={`transactionList`}
                  margin="normal"
                  options={options}
                  value={select}
                  meta={{ touched: null, error: null }}
                />
                {/* <Typography style={{margin: "24px 0"}}>Informação inportante para mostrar</Typography> */}
              </MaterialGrid>
              <MaterialGrid item xs={3}>
                <InputLabelOutlined
                  input={{ onChange: this.handleChange, value: procurar }}
                  meta={{}}
                  label="Procurar"
                  name="search"
                  margin="normal"
                />
              </MaterialGrid>
            </MaterialGrid>
          </MaterialGrid>
        </MaterialGrid>
        {select === 0 ? (
          <BuyActionTransactions procurar={procurar} />
        ) : select === 1 ? (
          <EarnPointsTransactions procurar={procurar} />
        ) : select === 2 ? (
          <UserBuyPointsTransactions procurar={procurar} />
        ) : null}
      </>
    );
  }
}

export default Transactions;
